import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign='center' color='inherit'>
      <Box variant='strong' textAlign='center' color='inherit'>
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = (props: { onClearFilter: any | undefined; }) => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b>No matches</b>
        <Box variant='p' color='inherit'>
          We can't find a match.
        </Box>
      </div>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 15
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
