import * as APIt from 'src/API';
import {
  BsCameraVideo,
  BsQuestionLg,
} from 'react-icons/bs';
import {
  FaNetworkWired,
  FaObjectGroup,
} from 'react-icons/fa';
import { VscCircuitBoard, VscTools } from 'react-icons/vsc';
import { GiTRexSkull } from 'react-icons/gi';
import { IconContext } from 'react-icons';
import { LuRadar } from 'react-icons/lu';
import { MdOutlineSensors } from 'react-icons/md';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { TiCalculator } from 'react-icons/ti';
import { debug } from 'src/utils';
import { useSessionContext } from './SessionContext';
import { useUserPreferences } from './hooks/useUserPreferences';

const iconSize = '1.7em'; // TODO: move to constant
const textSize = '1.5em'; // TODO: move to constant

export interface IDeviceIcon {
  deviceId: string;
  display: string;
  documentId: string;
  siteCode: string;
  type: string;
}

export function DeviceIcon(props: IDeviceIcon) {
  debug(`DeviceIcon() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  const userPreferencesQuery = useUserPreferences(sessionContext.userId);

  const getDarkMode = (): boolean => { 
    if (userPreferencesQuery?.data) {
      const userPreferences: APIt.UserPreferences = userPreferencesQuery?.data as APIt.UserPreferences;
      const preferences = JSON.parse(userPreferences?.preferences || '{}');
      return preferences?.darkMode || false;
    }
    return false;
  };

  let deviceImage: JSX.Element;

  const normalColor = getDarkMode() ? 'white' : 'black';
  const iconStyle = {
    color: normalColor,
    className: 'inline',
    size: iconSize,
  };

  switch (props.type) {
    case APIt.HardwareType.Controller:
      deviceImage = <IconContext.Provider value={iconStyle} key={`parent`}> <FaNetworkWired /> </IconContext.Provider>;
      break;
    case 'Camera':
      deviceImage = <IconContext.Provider value={iconStyle} key={`cam`}> <BsCameraVideo /> </IconContext.Provider>;
       break;
    case APIt.HardwareType.Reader:
      deviceImage = <IconContext.Provider value={iconStyle} key={`cr`}> <TiCalculator /> </IconContext.Provider>;
      break;
    case APIt.HardwareType.REX:
      deviceImage = <IconContext.Provider value={iconStyle} key={`crr`}> <GiTRexSkull /> </IconContext.Provider>;
      break;
    case APIt.HardwareType.Aux_Input:
    case APIt.HardwareType.Alarm_Input:
    case APIt.HardwareType.Input_Panel:
    case APIt.HardwareType.Port:
      deviceImage = <IconContext.Provider value={iconStyle} key={`ipi`}> <MdOutlineSensors /> </IconContext.Provider>;
      break;
    case APIt.HardwareType.Door_Contact:
      deviceImage = <IconContext.Provider value={iconStyle} key={`crdc`}> <VscTools /> </IconContext.Provider>;
      break;
    case APIt.HardwareType.Aux_Output:
    case APIt.HardwareType.Alarm_Output:
    case APIt.HardwareType.Output_Panel:
      deviceImage = <IconContext.Provider value={iconStyle} key={`ipo`}> <RiAlarmWarningLine /> </IconContext.Provider>;
      break;
    case APIt.HardwareType.Command_Keypad:
      deviceImage = <IconContext.Provider value={iconStyle} key={`ap`}> <VscCircuitBoard /> </IconContext.Provider>;
      break;
    case 'BoschPoint':
      deviceImage = <IconContext.Provider value={iconStyle} key={`ap`}> <FaObjectGroup /> </IconContext.Provider>;
      break;
    case 'Lidar':
      deviceImage = <IconContext.Provider value={iconStyle} key={`ldr`}> <LuRadar /> </IconContext.Provider>;
      break;
    case 'Undefined':
      deviceImage = <IconContext.Provider value={iconStyle} key={`q`}> <BsQuestionLg /> </IconContext.Provider>;
      break;
    default:
      console.error(`No icon found for device type: ${props.type}: ${props.type}`, true);
      deviceImage = <IconContext.Provider value={iconStyle} key={`q`}> <BsQuestionLg /> </IconContext.Provider>;
  }

  return(
    <>
      {deviceImage}
      <span
        style={{ fontSize: textSize, paddingLeft: 8 }}
      >
        {props.display}
      </span>
    </>);
}
