/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const rules = /* GraphQL */ `query Rules($method: String!, $parameters: AWSJSON!) {
  rules(method: $method, parameters: $parameters) {
    status
    message
    data {
      device {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
    }
  }
}
` as GeneratedQuery<APITypes.RulesQueryVariables, APITypes.RulesQuery>;
export const sesame = /* GraphQL */ `query Sesame($method: String!, $parameters: AWSJSON!) {
  sesame(method: $method, parameters: $parameters) {
    status
    message
    data {
      application {
        applicationID
        applicationName
      }
      applicationPermissions {
        applicationPermissionID
        permissionName
        applicationID
        metadata {
          key
          value
        }
        displayName
        description
      }
      resourcePermissions {
        resource {
          resourceType
          resourceID
          resourceName
        }
        permissionIDs
      }
      expirationTimestamp
      userRegion
      userTimezone
    }
  }
}
` as GeneratedQuery<APITypes.SesameQueryVariables, APITypes.SesameQuery>;
export const UnicornPACSRequestStatus = /* GraphQL */ `query UnicornPACSRequestStatus($method: String!, $parameters: AWSJSON!) {
  UnicornPACSRequestStatus(method: $method, parameters: $parameters)
}
` as GeneratedQuery<
  APITypes.UnicornPACSRequestStatusQueryVariables,
  APITypes.UnicornPACSRequestStatusQuery
>;
export const SNSPublishApproveDocument = /* GraphQL */ `query SNSPublishApproveDocument($documentId: ID!) {
  SNSPublishApproveDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishApproveDocumentQueryVariables,
  APITypes.SNSPublishApproveDocumentQuery
>;
export const SNSPublishCreateDocument = /* GraphQL */ `query SNSPublishCreateDocument($documentId: ID!) {
  SNSPublishCreateDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishCreateDocumentQueryVariables,
  APITypes.SNSPublishCreateDocumentQuery
>;
export const SNSPublishCreateTemplate = /* GraphQL */ `query SNSPublishCreateTemplate($templateId: ID!) {
  SNSPublishCreateTemplate(templateId: $templateId)
}
` as GeneratedQuery<
  APITypes.SNSPublishCreateTemplateQueryVariables,
  APITypes.SNSPublishCreateTemplateQuery
>;
export const SNSPublishDeleteDocument = /* GraphQL */ `query SNSPublishDeleteDocument($documentId: ID!) {
  SNSPublishDeleteDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishDeleteDocumentQueryVariables,
  APITypes.SNSPublishDeleteDocumentQuery
>;
export const SNSPublishDeleteTemplate = /* GraphQL */ `query SNSPublishDeleteTemplate($templateId: ID!) {
  SNSPublishDeleteTemplate(templateId: $templateId)
}
` as GeneratedQuery<
  APITypes.SNSPublishDeleteTemplateQueryVariables,
  APITypes.SNSPublishDeleteTemplateQuery
>;
export const SNSPublishFailDocument = /* GraphQL */ `query SNSPublishFailDocument($documentId: ID!) {
  SNSPublishFailDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishFailDocumentQueryVariables,
  APITypes.SNSPublishFailDocumentQuery
>;
export const SNSPublishSendDocument = /* GraphQL */ `query SNSPublishSendDocument($documentId: ID!) {
  SNSPublishSendDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishSendDocumentQueryVariables,
  APITypes.SNSPublishSendDocumentQuery
>;
export const SNSPublishReviewDocument = /* GraphQL */ `query SNSPublishReviewDocument($documentId: ID!) {
  SNSPublishReviewDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishReviewDocumentQueryVariables,
  APITypes.SNSPublishReviewDocumentQuery
>;
export const SNSPublishUpdateDocument = /* GraphQL */ `query SNSPublishUpdateDocument($documentId: ID!) {
  SNSPublishUpdateDocument(documentId: $documentId)
}
` as GeneratedQuery<
  APITypes.SNSPublishUpdateDocumentQueryVariables,
  APITypes.SNSPublishUpdateDocumentQuery
>;
export const SNSPublishUpdateTemplate = /* GraphQL */ `query SNSPublishUpdateTemplate($templateId: ID!) {
  SNSPublishUpdateTemplate(templateId: $templateId)
}
` as GeneratedQuery<
  APITypes.SNSPublishUpdateTemplateQueryVariables,
  APITypes.SNSPublishUpdateTemplateQuery
>;
export const getUserPreferences = /* GraphQL */ `query GetUserPreferences($userId: String!) {
  getUserPreferences(userId: $userId) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetUserPreferencesQueryVariables,
  APITypes.GetUserPreferencesQuery
>;
export const listUserPreferences = /* GraphQL */ `query ListUserPreferences(
  $userId: String
  $filter: ModelUserPreferencesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserPreferences(
    userId: $userId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      preferences
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserPreferencesQueryVariables,
  APITypes.ListUserPreferencesQuery
>;
export const getUserAction = /* GraphQL */ `query GetUserAction(
  $userId: String!
  $actionType: String!
  $actionTime: AWSTimestamp!
) {
  getUserAction(
    userId: $userId
    actionType: $actionType
    actionTime: $actionTime
  ) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetUserActionQueryVariables,
  APITypes.GetUserActionQuery
>;
export const listUserActions = /* GraphQL */ `query ListUserActions(
  $userId: String
  $actionTypeActionTime: ModelUserActionPrimaryCompositeKeyConditionInput
  $filter: ModelUserActionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserActions(
    userId: $userId
    actionTypeActionTime: $actionTypeActionTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userId
      actionType
      actionTime
      parameters
      ttl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserActionsQueryVariables,
  APITypes.ListUserActionsQuery
>;
export const listUserActionByActionTypeAndActionTimeAndUserId = /* GraphQL */ `query ListUserActionByActionTypeAndActionTimeAndUserId(
  $actionType: String!
  $actionTimeUserId: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserActionByActionTypeAndActionTimeAndUserId(
    actionType: $actionType
    actionTimeUserId: $actionTimeUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      actionType
      actionTime
      parameters
      ttl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserActionByActionTypeAndActionTimeAndUserIdQueryVariables,
  APITypes.ListUserActionByActionTypeAndActionTimeAndUserIdQuery
>;
export const listUserActionByActionTimeAndActionTypeAndUserId = /* GraphQL */ `query ListUserActionByActionTimeAndActionTypeAndUserId(
  $actionTime: AWSTimestamp!
  $actionTypeUserId: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserActionFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserActionByActionTimeAndActionTypeAndUserId(
    actionTime: $actionTime
    actionTypeUserId: $actionTypeUserId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userId
      actionType
      actionTime
      parameters
      ttl
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListUserActionByActionTimeAndActionTypeAndUserIdQueryVariables,
  APITypes.ListUserActionByActionTimeAndActionTypeAndUserIdQuery
>;
export const getApplicationSettings = /* GraphQL */ `query GetApplicationSettings($versionId: String!) {
  getApplicationSettings(versionId: $versionId) {
    versionId
    hardwareTypes {
      type
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          uiAttributes {
            name
            value
          }
        }
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetApplicationSettingsQueryVariables,
  APITypes.GetApplicationSettingsQuery
>;
export const listApplicationSettings = /* GraphQL */ `query ListApplicationSettings(
  $versionId: String
  $filter: ModelApplicationSettingsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listApplicationSettings(
    versionId: $versionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      versionId
      hardwareTypes {
        type
        name
        description
        hardwareDevices {
          name
          description
          fields {
            name
            segments {
              name
              sequence
              value
            }
            segmentDelimiter
            uiAttributes {
              name
              value
            }
          }
          rules {
            description
            id
            operator
            not
            conditions {
              id
              group
              groupParentId
              groupOperator
              key
              value
              not
              operator
            }
          }
          slots {
            description
            name
            hardwareType
            id
            settings {
              key
              value
            }
          }
        }
        settings
      }
      pacs {
        name
        description
        settings
      }
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListApplicationSettingsQueryVariables,
  APITypes.ListApplicationSettingsQuery
>;
export const getHardwareTemplate = /* GraphQL */ `query GetHardwareTemplate($id: ID!) {
  getHardwareTemplate(id: $id) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    hardwareType
    id
    name
    status
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetHardwareTemplateQueryVariables,
  APITypes.GetHardwareTemplateQuery
>;
export const listHardwareTemplates = /* GraphQL */ `query ListHardwareTemplates(
  $id: ID
  $filter: ModelHardwareTemplateFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listHardwareTemplates(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      description
      devices {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      hardwareType
      id
      name
      status
      versionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareTemplatesQueryVariables,
  APITypes.ListHardwareTemplatesQuery
>;
export const listHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndName = /* GraphQL */ `query ListHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndName(
  $hardwareType: HardwareType!
  $statusVersionIdName: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHardwareTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndName(
    hardwareType: $hardwareType
    statusVersionIdName: $statusVersionIdName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      description
      devices {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      hardwareType
      id
      name
      status
      versionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndNameQueryVariables,
  APITypes.ListHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndNameQuery
>;
export const listHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatus = /* GraphQL */ `query ListHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatus(
  $hardwareType: HardwareType!
  $nameVersionIdStatus: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHardwareTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatus(
    hardwareType: $hardwareType
    nameVersionIdStatus: $nameVersionIdStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      description
      devices {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      hardwareType
      id
      name
      status
      versionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatusQueryVariables,
  APITypes.ListHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatusQuery
>;
export const getSiteHardwareConfiguration = /* GraphQL */ `query GetSiteHardwareConfiguration($id: ID!) {
  getSiteHardwareConfiguration(id: $id) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    id
    name
    status
    siteCode
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedQuery<
  APITypes.GetSiteHardwareConfigurationQueryVariables,
  APITypes.GetSiteHardwareConfigurationQuery
>;
export const listSiteHardwareConfigurations = /* GraphQL */ `query ListSiteHardwareConfigurations(
  $id: ID
  $filter: ModelSiteHardwareConfigurationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listSiteHardwareConfigurations(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      description
      devices {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      id
      name
      status
      siteCode
      versionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSiteHardwareConfigurationsQueryVariables,
  APITypes.ListSiteHardwareConfigurationsQuery
>;
export const listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName = /* GraphQL */ `query ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName(
  $siteCode: String!
  $statusVersionIdName: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSiteHardwareConfigurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName(
    siteCode: $siteCode
    statusVersionIdName: $statusVersionIdName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      description
      devices {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      id
      name
      status
      siteCode
      versionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQueryVariables,
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery
>;
export const listSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus = /* GraphQL */ `query ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus(
  $siteCode: String!
  $nameVersionIdStatus: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSiteHardwareConfigurationFilterInput
  $limit: Int
  $nextToken: String
) {
  listSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus(
    siteCode: $siteCode
    nameVersionIdStatus: $nameVersionIdStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      description
      devices {
        id
        name
        hardwareTemplateId
        hardwareTemplateDeviceId
        hardwareType
        hardwareDeviceName
        address
        settings {
          key
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          value
          uiAttributes {
            name
            value
          }
        }
        parentDeviceId
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        passedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        failedRules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      id
      name
      status
      siteCode
      versionId
      createdAt
      updatedAt
    }
    nextToken
  }
}
` as GeneratedQuery<
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQueryVariables,
  APITypes.ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQuery
>;
