import { API, graphqlOperation } from 'aws-amplify';
import {
  ApplicationSettings,
  CreateSiteHardwareConfigurationInput,
  CreateSiteHardwareConfigurationMutation,
  DeleteSiteHardwareConfigurationInput,
  DeleteSiteHardwareConfigurationMutation,
  Device,
  HardwareDevice,
  RulesQuery,
  RulesResponse,
  SiteHardwareConfiguration,
  SNSPublishSendDocumentQuery,
  UpdateSiteHardwareConfigurationInput,
  UpdateSiteHardwareConfigurationMutation,
} from 'src/API';
import {
  IOpenDocumentsContext,
  OpenDocumentActionType,
} from './OpenDocumentsContext';
import {
  createSiteHardwareConfiguration as createSiteHardwareConfigurationMutation,
  deleteSiteHardwareConfiguration as deleteSiteHardwareConfigurationMutation,
  updateSiteHardwareConfiguration as updateSiteHardwareConfigurationMutation,
} from 'src/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { QueryClient } from '@tanstack/react-query';
import { QueryKey } from '../common/constants';
import { auditDecorator } from '../utils';
import { debug } from 'src/utils';
import { rules, SNSPublishSendDocument } from 'src/graphql/queries';

export let createSiteHardwareConfiguration = async (input: CreateSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`createSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let createdSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(createSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<CreateSiteHardwareConfigurationMutation>;
    if (response.data && response.data.createSiteHardwareConfiguration) {
      createdSiteHardwareConfiguration = response.data.createSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`createSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`createSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(createdSiteHardwareConfiguration);
};    
createSiteHardwareConfiguration = auditDecorator('createSiteHardwareConfiguration', createSiteHardwareConfiguration);

export let updateSiteHardwareConfiguration = async (input: UpdateSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`updateSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let updatedSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(updateSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<UpdateSiteHardwareConfigurationMutation>;
    if (response.data && response.data.updateSiteHardwareConfiguration) {
      updatedSiteHardwareConfiguration = response.data.updateSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`updateSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`updateSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(updatedSiteHardwareConfiguration);
};    
updateSiteHardwareConfiguration = auditDecorator('updateSiteHardwareConfiguration', updateSiteHardwareConfiguration);

export let deleteSiteHardwareConfiguration = async (input: DeleteSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`deleteSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let deletedSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(deleteSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<DeleteSiteHardwareConfigurationMutation>;
    if (response.data && response.data.deleteSiteHardwareConfiguration) {
      deletedSiteHardwareConfiguration = response.data.deleteSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`deleteSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`deleteSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(deletedSiteHardwareConfiguration);
};    
deleteSiteHardwareConfiguration = auditDecorator('deleteSiteHardwareConfiguration', deleteSiteHardwareConfiguration);

export function getApplicationSettingsForHardwareTypeAndDeviceName(
  applicationSettings: ApplicationSettings,
  hardwareTypeName: string,
  hardwareDeviceName: string): HardwareDevice | null
{
  debug(`getApplicationSettingsForHardwareTypeAndDeviceName() applicationSettings is ${JSON.stringify(applicationSettings)} \
    hardwareTypeName is ${hardwareTypeName} hardwareDeviceName is ${hardwareDeviceName}`);

  let hardwareDevice: HardwareDevice | null = null;

  try {
    hardwareDevice = applicationSettings?.hardwareTypes
      ?.find((ht) => ht?.name === hardwareTypeName)?.hardwareDevices
      ?.find((hd) => hd?.name === hardwareDeviceName) || null;
  } catch (error) {
    console.error(error); 
    throw error;
  }

  return hardwareDevice;
}

export const saveDevice = async (openDocumentsContext: IOpenDocumentsContext, queryClient: QueryClient) => {
  debug(`saveDevice() openDocumentsContext is ${JSON.stringify(openDocumentsContext)}`);

  if (!openDocumentsContext.currentDocument) throw new Error('missing current document');

  try {
    const updateSiteHardwareConfigurationInput: UpdateSiteHardwareConfigurationInput = {
      id: openDocumentsContext.currentDocument.temporaryDocument.id,
      description: openDocumentsContext.currentDocument.temporaryDocument.description,
      devices: openDocumentsContext.currentDocument.temporaryDocument.devices,
      name: openDocumentsContext.currentDocument.temporaryDocument.name,
      status: openDocumentsContext.currentDocument.temporaryDocument.status,
      versionId: openDocumentsContext.currentDocument.temporaryDocument.versionId,
    };
    await updateSiteHardwareConfiguration(updateSiteHardwareConfigurationInput);
    openDocumentsContext.dispatch({ type: OpenDocumentActionType.saveDevice, payload: { } });
    queryClient!.invalidateQueries({ queryKey: [QueryKey.documents] });
  } catch (error) {
    console.error(error); 
  }
};

export let evaluateRules = async (device: Device, callback: Function): Promise<RulesResponse | null> => {
  debug(`evaluateRules() device is ${JSON.stringify(device)}`);

  if (!device || !device.hardwareTemplateId || device.hardwareTemplateId === null) {
    callback(null);
    return;
  }

  let rulesEvaluationResponse: RulesResponse | null = null;
  
  try {
    const parameters = JSON.stringify({ device: device });
    debug(`evaluateRules() parameters is ${JSON.stringify(parameters)}`);
    const response = await API.graphql(graphqlOperation(rules,
      {
        method: 'evaluateRules',
        parameters,
      })) as GraphQLResult<RulesQuery>;
    if (response.data && response.data.rules) {
      rulesEvaluationResponse = response.data.rules as RulesResponse;
    }
    debug(`evaluateRules() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`evaluateRules() error is ${JSON.stringify(error)}`);
    throw error;
  }

  callback(rulesEvaluationResponse);

  return(rulesEvaluationResponse);
};    
evaluateRules = auditDecorator('evaluateRules', evaluateRules);

export let sendSNSTopic = async (documentId: string) => {
  debug(`sendSNSTopic() documentId is ${JSON.stringify(documentId)}`);

  if (!documentId) {
    return;
  }

  try {
    const response = await API.graphql(graphqlOperation(SNSPublishSendDocument,
      {
        documentId,
      })) as GraphQLResult<SNSPublishSendDocumentQuery>;
    debug(`sendSNSTopic() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`sendSNSTopic() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return;
};    
sendSNSTopic = auditDecorator('sendSNSTopic', sendSNSTopic);