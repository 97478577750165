import { HardwareType } from 'src/API';
import { QueryKey } from '../constants';
import { debug } from 'src/utils';
import { queryHardwareTemplates } from 'src/components/Templates/utils';
import { useQuery } from '@tanstack/react-query';

export function useTemplates(hardwareType?: HardwareType) {
  debug(`useTemplates() hardwareType is ${hardwareType}`);
  return useQuery({
    queryKey: [QueryKey.templates],
    queryFn: () => queryHardwareTemplates(hardwareType),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
    staleTime: Infinity,
    throwOnError: true,
  });
}
