import {
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useState } from 'react';

interface ICreateTemplateRevisionProps {
  cancel: Function;
  createTemplateRevision: Function;
  creatingTemplateRevision: boolean;
}

export function CreateTemplateRevision(props: ICreateTemplateRevisionProps) {
  debug(`CreateTemplateRevision() props is ${JSON.stringify(props)}`);

  const [versionId, setVersionId] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Templates.CreateTemplateRevision');

  const createTemplateRevision = () => {
    setVersionId('');
    props.createTemplateRevision(versionId);
  };

  const cancelCreateTemplateRevision = () => {
    setVersionId('');
    props.cancel();
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <form onSubmit={event => event.preventDefault()}>
      <Form
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button
                formAction='none'
                onClick={() => cancelCreateTemplateRevision()}
                variant='normal'
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                disabled={!versionId}
                loading={props.creatingTemplateRevision}
                onClick={() => createTemplateRevision()}
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </SpaceBetween>
          }
        >
        <FormField label={bundle.getMessage('version')} >
          <Input
            onChange={( { detail }) => setVersionId(detail.value)}
            value={versionId}
          />
        </FormField>
      </Form>
    </form>);
}
