import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
} from 'react';
import {
  SesameUserPermissions,
  UserPreferences,
} from 'src/API';
import { SPOTSesameUserPermissions } from './constants';
import { debug } from 'src/utils';
import { useImmerReducer } from 'use-immer';

export enum SessionActionType {
  setSessionEmployeeId = 'setSessionEmployeeId',
  setSessionEnableDebugLogging = 'setSessionEnableDebugLogging',
  setSessionPersonId = 'setSessionPersonId',
  setSessionSelectedDeviceId = 'setSessionSelectedDeviceId',
  setSessionSiteCode = 'setSessionSiteCode',
  setSessionUserId = 'setSessionUserId',
  setSessionUserPermissions = 'setSessionUserPermissions',
  setSessionUserPreferences = 'setSessionUserPreferences',
  setSessionUserSites = 'setSessionUserSites',
  setSessionUsername = 'setSessionUsername',
  setEnableVisualRefresh = 'setEnableVisualRefresh',
}

export interface ISessionContext {
  administrate: boolean;
  configuration: boolean | null;
  dispatch: Dispatch<SessionAction>;
  employeeId: string;
  enableDebugLogging: boolean;
  enableVisualRefresh: boolean;
  personId: string;
  review: boolean | null;
  selectedDeviceId: string | null;
  siteCode: string;
  support: boolean | null;
  template: boolean | null;
  userId: string;
  userPermissions: SesameUserPermissions | null;
  userPreferences: UserPreferences;
  userSites: string[];
  username: string;
}

const initialSessionContext: ISessionContext = {
  administrate: false,
  configuration: null,
  dispatch: () => {},
  employeeId: '',
  enableDebugLogging: (window.location.hostname === 'localhost.a2z.com'),
  enableVisualRefresh: false,
  personId: '',
  review: null,
  selectedDeviceId: null,
  siteCode: '',
  support: null,
  template: null,
  userId: '',
  userPermissions: null,
  userPreferences: {} as UserPreferences,
  userSites: [],
  username: '',
};

export const SessionContext = createContext<ISessionContext>(initialSessionContext);

export function useSessionContext() {
  return useContext(SessionContext);
}

type SessionAction =
  | { type: SessionActionType.setSessionEnableDebugLogging; payload: { enableDebugLogging: boolean } }
  | { type: SessionActionType.setSessionEmployeeId; payload: { employeeId: string } }
  | { type: SessionActionType.setSessionPersonId; payload: { personId: string } }
  | { type: SessionActionType.setSessionSelectedDeviceId; payload: { selectedDeviceId: string } }
  | { type: SessionActionType.setSessionSiteCode; payload: { siteCode: string } }
  | { type: SessionActionType.setSessionUserId; payload: { userId: string } }
  | { type: SessionActionType.setSessionUserPermissions; payload: { userPermissions: SesameUserPermissions | null } }
  | { type: SessionActionType.setSessionUserPreferences; payload: { userPreferences: UserPreferences } }
  | { type: SessionActionType.setSessionUserSites; payload: { userSites: string[] } }
  | { type: SessionActionType.setSessionUsername; payload: { username: string } }
  | { type: SessionActionType.setEnableVisualRefresh; payload: { enableVisualRefresh: boolean } };

function sessionReducer(draft: ISessionContext, action: SessionAction) {
  debug(`sessionReducer() draft is ${JSON.stringify(draft)} action is ${JSON.stringify(action)}`);
  debug(`sessionReducer() action is ${JSON.stringify(action)}`);
  debug(`sessionReducer() action.type is ${action.type}`);


  const hasPermission = (permissionName: string, userPermissions: SesameUserPermissions):boolean => {
    return (userPermissions.applicationPermissions?.find(ap => 
      ap.permissionName === permissionName) !== undefined)
      || false;
  };

  switch (action?.type) {
    case SessionActionType.setSessionEnableDebugLogging:
      draft.enableDebugLogging = action.payload.enableDebugLogging;
      break;

    case SessionActionType.setSessionEmployeeId:
      draft.employeeId = action.payload.employeeId;
      break;

    case SessionActionType.setSessionPersonId:
      draft.personId = action.payload.personId;
      break;

    case SessionActionType.setSessionSelectedDeviceId:
      draft.selectedDeviceId = action.payload.selectedDeviceId;
      break;

    case SessionActionType.setSessionSiteCode:
      draft.siteCode = action.payload.siteCode;
      break;

    case SessionActionType.setSessionUserId:
      draft.userId = action.payload.userId;
      break;

    case SessionActionType.setSessionUserPermissions:
      draft.userPermissions = action.payload.userPermissions;
      if (draft.userPermissions === null) return;
      draft.administrate = hasPermission(SPOTSesameUserPermissions.administrate, draft.userPermissions);
      draft.configuration = hasPermission(SPOTSesameUserPermissions.configuration, draft.userPermissions);
      draft.review = hasPermission(SPOTSesameUserPermissions.review, draft.userPermissions);
      draft.support = hasPermission(SPOTSesameUserPermissions.support, draft.userPermissions);
      draft.template = hasPermission(SPOTSesameUserPermissions.template, draft.userPermissions);
      break;

    case SessionActionType.setSessionUserPreferences:
      draft.userPreferences = action.payload.userPreferences;
      break;

    case SessionActionType.setSessionUserSites:
      draft.userSites = action.payload.userSites;
      break;

    case SessionActionType.setSessionUsername:
      draft.username = action.payload.username;
      break;

    case SessionActionType.setEnableVisualRefresh:
      draft.enableVisualRefresh = action.payload.enableVisualRefresh;
      break;

    default:
      return;
  }
}

export function SessionProvider({ children }: { children: ReactNode }) {

  const [sessionContext, sessionContextDispatch] = useImmerReducer(sessionReducer, initialSessionContext);

  return(
    <SessionContext.Provider
      value={
        {
          administrate: sessionContext.administrate,
          configuration: sessionContext.configuration,
          dispatch: sessionContextDispatch,
          selectedDeviceId: sessionContext.selectedDeviceId,
          employeeId: sessionContext.employeeId,
          enableDebugLogging: sessionContext.enableDebugLogging,
          personId: sessionContext.personId,
          review: sessionContext.review,
          siteCode: sessionContext.siteCode,
          support: sessionContext.support,
          template: sessionContext.template,
          userId: sessionContext.userId,
          userPreferences: sessionContext.userPreferences,
          userPermissions: sessionContext.userPermissions,
          userSites: sessionContext.userSites,
          username: sessionContext.username,
          enableVisualRefresh: sessionContext.enableVisualRefresh,
        }
      }
    >
      {children}
    </SessionContext.Provider>
  );
}
