import {
  Box,
  Button,
  Container,
  Header,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
  TextFilter,
  Textarea,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './TemplateDeviceRulesTableConfig';
import {
  Device,
  Rule,
} from 'src/API';
import {
  constructRuleConditionsStatement,
  formatOperator,
} from './utils';
import {
  useEffect,
  useState,
} from 'react';
import { TemplateDeviceRule } from './TemplateDeviceRule';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface ITemplateDeviceRulesProps {
  setRule: Function;
  deviceForDeviceRules: Device | undefined;
  removeRule: Function;
}

export function TemplateDeviceRulesTablePanel(props: ITemplateDeviceRulesProps) {
  debug(`TemplateDeviceRulesTablePanel() props is ${JSON.stringify(props)}`);

  const [editRule, setEditRule] = useState<Rule | undefined>();
  const [formatConditions, setFormatConditions] = useState<boolean>(false);
  const [formattedConditions, setFormattedConditions] = useState<string>('');
  const [formattedConditionsRows, setFormattedConditionsRows] = useState<number>();
  const [pageSize] = useState<number>(DefaultPageSize.pageSize);
  const [selectedRule, setSelectedRule] = useState<Rule | undefined>();
  const [showTemplateDeviceRule, setShowTemplateDeviceRule] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Templates.TemplateDeviceRulesTablePanel');

  const { actions, collectionProps, filterProps, filteredItemsCount, items, paginationProps } = useCollection(
    props.deviceForDeviceRules?.rules as Rule[] || [],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-rules-found') : 'No rules found'} />,
        filteringFunction: (item, filteringText) => {
          const conditionsWithDisplayedOperator = item.conditions?.map(c => ({...c, operator: formatOperator(c.operator)}));
          return(
            item.description.toLowerCase().includes(filteringText.toLowerCase())
            || formatOperator(item.operator.toLowerCase()).includes(filteringText.toLowerCase())
            || JSON.stringify(conditionsWithDisplayedOperator).toLowerCase().includes(filteringText.toLowerCase()));
        },
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
  });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const setRule = (rule: Rule) => {
    setShowTemplateDeviceRule(false);
    props.setRule(rule);
  };

  const remove = () => {
    setSelectedRule(undefined);
    props.removeRule(selectedRule);
  };

  const newLinesCount = (formattedConditions: string): number => {
    const newLineIndices: number[] = [];
    let prevIndex = 0;

    formattedConditions.split('\n').forEach((_, i) => {
      if (i !== 0) {
        newLineIndices.push(prevIndex + i);
      }
      prevIndex += i + 1;
    });

    return(newLineIndices.length+1);
  };

  useEffect(() => {
    if (!selectedRule) return;
    if (!props.deviceForDeviceRules?.rules) setSelectedRule(undefined);
    setSelectedRule(props.deviceForDeviceRules?.rules?.find(r => r?.id === selectedRule.id) || undefined);
  }, [[props.deviceForDeviceRules?.rules]]);

  useEffect(() => {
    if (!props.deviceForDeviceRules || !selectedRule) return;
    const newFormattedConditions = constructRuleConditionsStatement(props.deviceForDeviceRules, selectedRule, true);
    setFormattedConditions(newFormattedConditions);
    setFormattedConditionsRows(newLinesCount(newFormattedConditions));
  }, [formatConditions, selectedRule]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<Rule>[] = [
    {
      id: 'description',
      header: bundle.getMessage('description'),
      cell: item => item.description,
      sortingField: 'description',
    },
    {
      id: 'conditions',
      header: bundle.getMessage('conditions'),
      cell: item => {
        if (formatConditions && item.id === selectedRule?.id) {
          return(
            <Textarea
              readOnly
              rows={formattedConditionsRows}
              value={formattedConditions}
            />);
        }
        return(<>{constructRuleConditionsStatement(props.deviceForDeviceRules!, item)}</>);
      },
      sortingField: 'conditions',
    },
  ];

  return(
    <Container
      footer={
        <Box
          float='right'
        >
          <SpaceBetween direction='horizontal' size='s'>
            <Button
              disabled={!selectedRule}
              onClick={() => {
                setFormatConditions(!formatConditions);
              }}
              variant='normal'
            >
              {formatConditions ? bundle.getMessage('unformat-conditions') : bundle.getMessage('format-conditions')}
            </Button>
            <Button
              disabled={!selectedRule}
              onClick={() => {
                setEditRule(selectedRule);
                setShowTemplateDeviceRule(true);
              }}
              variant='normal'
            >
              {bundle.getMessage('edit')}
            </Button>
            <Button
              disabled={!selectedRule}
              onClick={() => remove()}
            >
              {bundle.getMessage('remove')}
            </Button>
            <Button
              onClick={() => {
                setEditRule(undefined);
                setShowTemplateDeviceRule(true);
              }}
              variant='primary'
            >
              {bundle.getMessage('add')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header>
          {bundle.getMessage('rules')}
        </Header>}
      variant='stacked'
    >
      {
        showTemplateDeviceRule // needed to unmount modal
        &&
        props.deviceForDeviceRules
        &&
        <Modal
          onDismiss={() => setShowTemplateDeviceRule(false)}
          header={editRule ? bundle.getMessage('edit-rule') : bundle.getMessage('add-rule')}
          size='max'
          visible={showTemplateDeviceRule}
        >
          <TemplateDeviceRule
            cancel={() => setShowTemplateDeviceRule(false)}
            device={props.deviceForDeviceRules}
            rule={editRule}
            setRule={setRule}
          />
        </Modal>
      }
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-rules')}
            filteringPlaceholder={bundle.getMessage('find-rules')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        items={items}
        onSelectionChange={({ detail }) => setSelectedRule(detail.selectedItems[0]) }
        pagination={
          ((props.deviceForDeviceRules?.rules?.length || 0) > pageSize)
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        selectedItems={selectedRule ? [selectedRule] : []}
        selectionType='single'
        trackBy='id'
        wrapLines
      />
    </Container>);
}
