import {
  ComponentType,
  UIAttribute,
} from '../common/constants';
import {
  Container,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import {
  Device,
  DeviceSettings,
} from 'src/API';
import {
  OpenReviewActionType,
  useOpenReviewsContext,
} from '../Reviews/OpenReviewsContext';
import {
  useEffect,
  useState,
} from 'react';
import { debug } from 'src/utils';

export interface IReviewDeviceSettingsProps {}

export default function ReviewDeviceSettings(props: IReviewDeviceSettingsProps) {
  debug(`ReviewDeviceSettings() props is ${JSON.stringify(props)}`);

  const openReviewsContext = useOpenReviewsContext();

  const [reviewDevice, setReviewDevice] = useState<Device>();

  useEffect(() => {
    debug(`ReviewDeviceSettings() useEffect()[openReviewsContext.currentDeviceId, openReviewsContext.currentReview.temporaryReview.devices]`);
    const currentDeviceId = openReviewsContext.currentDeviceId;
    if (!currentDeviceId) {
      setReviewDevice(undefined);
      return;
    }
    const device = openReviewsContext.currentReview?.temporaryReview.devices?.find(d => d.id === currentDeviceId);
    if (!device) return;
    setReviewDevice({...device});
  }, [openReviewsContext.currentDeviceId, openReviewsContext.currentReview?.temporaryReview?.devices]);

  if (openReviewsContext.currentDeviceId === null) return(<></>);

  const createHardwareDeviceForm = () => {
    debug(`ReviewDevice() createHardwareDeviceForm() reviewDevice is ${JSON.stringify(reviewDevice)}`);
    return(
      <Container
        header={
          <Header
          >
            {reviewDevice?.name}
          </Header>}
      >
      <form onSubmit={event => event.preventDefault()}>
        <Form>
          <Container>
            <SpaceBetween direction='vertical' size='xxxs'>
              {Array.from(reviewDevice?.settings || [])
                ?.sort((dsA: any, dsB: any): number => {
                  if (!dsA || !dsB) return 0;
                  const sequenceA = parseInt(dsA?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  const sequenceB = parseInt(dsB?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  return sequenceA > sequenceB ? 1 : -1;
              })
              .map((ds: (DeviceSettings | null)) => {
                if (ds === null) return(<></>);
                const componentType = ds.uiAttributes?.find((a: any) => a?.name === UIAttribute.componentType)?.value || ComponentType.input;
                return(
                  <FormField
                    key={ds?.key}
                    label={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.label)?.value || ds?.key}
                  >
                    {componentType === ComponentType.input && reviewDevice?.settings?.length && reviewDevice.settings.length > 0
                    ? 
                      <Input
                        disabled
                        readOnly
                        value={ds.value || ''}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.select && reviewDevice?.settings?.length && reviewDevice.settings.length > 0
                    ?
                      <Select
                        disabled
                        expandToViewport
                        onChange={({ detail }) => {
                          const deviceSettings = reviewDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds.key,
                            uiAttributes: ds.uiAttributes,
                            value: detail.selectedOption.value,
                          };
                          const newReviewDevice: Device = {
                            ...reviewDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setReviewDevice(newReviewDevice);
                          openReviewsContext.dispatch({ type: OpenReviewActionType.updateDevice, payload: { device: newReviewDevice } });
                        }}
                        options={JSON.parse(ds.uiAttributes.find(a => a.name === 'options').value).sort((a, b) => a.label < b.label ? -1 : 1)}
                        selectedOption={({ label: ds.value, value: ds.value})}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.toggle && reviewDevice?.settings?.length && reviewDevice.settings.length > 0
                    ? 
                      <Toggle
                        disabled
                        checked={ds.value === 'true' || false}
                      />
                    :
                      <></>}
                  </FormField>);
                })}
            </SpaceBetween>
          </Container>
        </Form>
      </form>
      </Container>
    );
  };

  return(
    <>
      {createHardwareDeviceForm()}
    </>);
}