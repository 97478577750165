import { API, graphqlOperation } from 'aws-amplify';
import {
  ApplicationSettings,
  HardwareDevice,
  SiteHardwareConfiguration,
  UpdateSiteHardwareConfigurationInput,
  UpdateSiteHardwareConfigurationMutation,
} from 'src/API';
import {
  IOpenReviewsContext,
  OpenReviewActionType,
} from './OpenReviewsContext';
import { GraphQLResult } from '@aws-amplify/api';
import { QueryClient } from '@tanstack/react-query';
import { QueryKey } from '../common/constants';
import { auditDecorator } from '../utils';
import { debug } from 'src/utils';
import { updateSiteHardwareConfiguration as updateSiteHardwareConfigurationMutation } from 'src/graphql/mutations';

export function getApplicationSettingsForHardwareTypeAndDeviceName(
  applicationSettings: ApplicationSettings,
  hardwareTypeName: string,
  hardwareDeviceName: string): HardwareDevice | null
{
  debug(`getApplicationSettingsForHardwareTypeAndDeviceName() applicationSettings is ${JSON.stringify(applicationSettings)} \
  hardwareTypeName is ${hardwareTypeName} hardwareDeviceName is ${hardwareDeviceName}`);

  let hardwareDevice: HardwareDevice | null = null;

  try {
    hardwareDevice = applicationSettings?.hardwareTypes
      ?.find((ht) => ht?.name === hardwareTypeName)?.hardwareDevices
      ?.find((hd) => hd?.name === hardwareDeviceName) || null;
  } catch (error) {
    console.error(error); 
    throw error;
  }

  return hardwareDevice;
}

export let updateSiteHardwareConfiguration = async (input: UpdateSiteHardwareConfigurationInput): Promise<SiteHardwareConfiguration | null> => {
  debug(`updateSiteHardwareConfiguration() input is ${JSON.stringify(input)}`);
 
  let updatedSiteHardwareConfiguration: SiteHardwareConfiguration | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(updateSiteHardwareConfigurationMutation,
      {
        input
      })) as GraphQLResult<UpdateSiteHardwareConfigurationMutation>;
    if (response.data && response.data.updateSiteHardwareConfiguration) {
      updatedSiteHardwareConfiguration = response.data.updateSiteHardwareConfiguration as SiteHardwareConfiguration;
    }
    debug(`updateSiteHardwareConfiguration() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`updateSiteHardwareConfiguration() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(updatedSiteHardwareConfiguration);
};    
updateSiteHardwareConfiguration = auditDecorator('updateSiteHardwareConfiguration', updateSiteHardwareConfiguration);

export const saveDevice = async (openReviewsContext: IOpenReviewsContext, queryClient: QueryClient) => {
  if (!openReviewsContext.currentReview) throw new Error('missing current document');
  try {
    const updateSiteHardwareConfigurationInput: UpdateSiteHardwareConfigurationInput = {
      id: openReviewsContext.currentReview.temporaryReview.id,
      description: openReviewsContext.currentReview.temporaryReview.description,
      devices: openReviewsContext.currentReview.temporaryReview.devices,
      name: openReviewsContext.currentReview.temporaryReview.name,
      status: openReviewsContext.currentReview.temporaryReview.status,
      versionId: openReviewsContext.currentReview.temporaryReview.versionId,
    };
    await updateSiteHardwareConfiguration(updateSiteHardwareConfigurationInput);
    openReviewsContext.dispatch({ type: OpenReviewActionType.saveDevice, payload: { } });
    queryClient!.invalidateQueries({ queryKey: [QueryKey.documents] });
  } catch (error) {
    console.error(error); 
  }
};
