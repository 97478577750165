import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Container,
  Header,
  Modal,
  SelectProps,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './DocumentDevicesTableConfig';
import {
  Device,
  HardwareType,
  RulesEvaluation,
  RulesResponse,
} from 'src/API';
import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from './OpenDocumentsContext';
import {
  SessionActionType,
  useSessionContext,
} from '../common/SessionContext';
import {
  evaluateRules,
  saveDevice,
} from './utils';
import {
  findParentDevices,
  sortDevices,
} from '../utils';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ApplyTemplateTablePanel } from './ApplyTemplateTablePanel';
import { DeviceIcon } from '../common/DeviceIcon';
import { DocumentRulesEvaluationTablePanel } from './DocumentRulesEvaluationTablePanel';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

export interface IDocumentDevicesTableProps {
  applyTemplate: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
}

export const DocumentDevicesTablePanel = (props: IDocumentDevicesTableProps) => {
  debug(`DocumentDevicesTree() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  const openDocumentsContext = useOpenDocumentsContext();

  const queryClient = useQueryClient();

  const [documentDevices, setDocumentDevices] = useState<Device[]>(
    sortDevices(openDocumentsContext.currentDocument?.temporaryDocument
      .devices?.filter(d => d !== null)) || []);
  const [documentDeviceForRulesEvaluation, setDocumentDeviceForRulesEvaluation] = useState<Device | null>(null);
  const [documentDeviceRulesEvaluation, setDocumentDeviceRulesEvaluation] = useState<RulesEvaluation | null>(null);
  const [documentDevicesRulesEvaluating, setDocumentDevicesRulesEvaluating] = useState<Device[]>([]);
  const [documentDevicesRulesResponses, setDocumentDevicesRulesResponses] = useState<RulesResponse[]>([]);
  const [selectedItems, setSelectedItems] = useState<Device[]>([]);
  const [showApplyTemplate, setShowApplyTemplate] = useState<boolean>(false);

  const deviceForApplyTemplate = useRef<Device | null>(null);

  const { data: evaluateRulesData, refetch: evaluateRulesQueryRefetch, } = useQuery({
    queryFn: async () => {
      if (!documentDeviceForRulesEvaluation) return null;
      const device = openDocumentsContext.currentDocument?.temporaryDocument
        ?.devices?.find((d: Device) => d.id === documentDeviceForRulesEvaluation.id);
      if (!device) return null;
      setDocumentDevicesRulesEvaluating([...documentDevicesRulesEvaluating, device]);
      let ruleEvaluationResult: RulesResponse;
      setDocumentDevicesRulesResponses([]);
      if (device.hardwareTemplateId && device.hardwareTemplateDeviceId) {
        ruleEvaluationResult = await evaluateRules(device, () => null);
        setDocumentDevicesRulesResponses([ruleEvaluationResult]);
      }
      const newDocumentDevice: Device = {
        ...device,
        failedRules: ruleEvaluationResult?.data?.failedRules ?? [],
        passedRules: ruleEvaluationResult?.data?.passedRules ?? [],
      };
      openDocumentsContext.dispatch({ type: OpenDocumentActionType.updateDeviceForRulesEvaluation, payload: { device: newDocumentDevice } });
      setDocumentDeviceRulesEvaluation({
        device: newDocumentDevice,
        failedRules: ruleEvaluationResult?.data?.failedRules ?? [],
        passedRules: ruleEvaluationResult?.data?.passedRules ?? [],
      });
      setDocumentDevicesRulesEvaluating([]);
      return(ruleEvaluationResult);
    },
    queryKey: ['evaluateRules'],
    retry: 3,
    throwOnError: true,
  });

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentDevicesTablePanel');

  const { items, actions, collectionProps } = useCollection(
    documentDevices,
    {
      expandableRows: {
        getId: item => item.id,
        getParentId: item => item.parentDeviceId || null,
      },
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-devices-found') : 'No devices found'} />,
        noMatch: <TableNoMatchState />
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
  });

  const selectDevice = (selectedDevices: Device[]) => {
    debug(`DocumentDevicesTree() selectDevice() selectedDevices is ${JSON.stringify(selectedDevices)}`);
    try {
      const payload: { deviceId: string | null } = { deviceId: null};
      switch (selectedDevices.length) {
        case 0:
          setSelectedItems([]);
          break;
        case 1:
          setSelectedItems([selectedDevices[0]]);
          payload.deviceId = selectedDevices[0].id;
          break;
        case 2:
          setSelectedItems([selectedDevices[1]]);
          payload.deviceId = selectedDevices[1].id;
          break;
        default:
          setSelectedItems([]);
          break;
      }
      openDocumentsContext.dispatch({
        type: OpenDocumentActionType.setCurrentDeviceId,
        payload,
      });
    } catch(error) {
      console.error(error);
    }
    const parentDevices = findParentDevices(selectedDevices[0], documentDevices);
    const expandedItems = [...collectionProps?.expandableRows?.expandedItems || []];
    parentDevices.forEach(device => {
      if (!expandedItems.includes(device)) {
        expandedItems.push(device);
      }
    });
    actions.setExpandedItems(expandedItems);
  };

  const expand = () => {
    const expandedItems = collectionProps.expandableRows?.expandedItems;
    if (expandedItems && expandedItems?.length > 0) {
      actions.setExpandedItems([]);
      return;
    }
    const parentDevices = documentDevices
      .filter(device => documentDevices.find(innerDevice => innerDevice.parentDeviceId === device.id) !== undefined);
    actions.setExpandedItems(parentDevices);
  };

  enum actionType {
    copySettingsToOtherDevices = 'copySettingsToOtherDevices',
    findTemplate = 'findTemplate',
    remove = 'remove',
    save = 'save',
    send = 'send',
    undo = 'undo',
    verify = 'verify',
  }

  const act = (action: { type: actionType, payload: { device?: Device } }) => {
    if (!action.payload.device) action.payload.device = selectedItems[0];
    switch (action.type) {
      case actionType.copySettingsToOtherDevices:
        alert('TODO: copy settings to other devices');
        break;

      case actionType.findTemplate:
        deviceForApplyTemplate.current = action.payload.device;
        setShowApplyTemplate(true);
        break;
    
      case actionType.remove:
        openDocumentsContext.dispatch({ type: OpenDocumentActionType.removeDevice, payload: { deviceId: action.payload.device.id } });
        break;

      case actionType.save:
        saveDevice(openDocumentsContext, queryClient);
        break;

      case actionType.send:
        alert('TODO: send to PACS');
        break;

      case actionType.undo:
        openDocumentsContext.dispatch({ type: OpenDocumentActionType.undoDevice, payload: { deviceId: action.payload.device.id } });
        break;

      case actionType.verify:
        if (action.payload.device.hardwareTemplateId && action.payload.device.hardwareTemplateDeviceId) {
          setDocumentDeviceForRulesEvaluation(action.payload.device);
        } else {
          alert(bundle.getMessage('template-not-applied'));
        }
        break;

      default:
        break;
    }
  };

  const itemsCount = (): number => {
    if (documentDevices) return documentDevices.length;
    return 0;
  };

  useEffect(() => {
    const device = (openDocumentsContext.currentDocument?.temporaryDocument.devices
      ?.find(d => d.id === openDocumentsContext.currentDeviceId));
    if (device) {
      selectDevice([device]);
      sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: device.id } });
    }
    if (!device) {
      setSelectedItems([]);
      sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: null } });
    }
  }, [openDocumentsContext.currentDeviceId]);

  useEffect(() => {
    const updatedDevices = openDocumentsContext.currentDocument?.temporaryDocument.devices
      ?.filter(d => d !== null) || [];
    if (updatedDevices.length > documentDevices.length) {
      actions.setExpandedItems(updatedDevices.filter(d => d.hardwareType !== HardwareType.Port));
      const addedDevice = updatedDevices.find(d => !documentDevices.includes(d));
      if (addedDevice) {
        setSelectedItems([addedDevice]);
        selectDevice([addedDevice]);
      }
    }
    setDocumentDevices(sortDevices(updatedDevices));
  }, [openDocumentsContext.currentDocument?.temporaryDocument.devices]);

  useEffect(() => {
    if (documentDeviceForRulesEvaluation) evaluateRulesQueryRefetch();
  }, [documentDeviceForRulesEvaluation]);

  useEffect(() => {
    if (!evaluateRulesData?.data?.device) return;
    setDocumentDeviceRulesEvaluation({
      device: evaluateRulesData?.data?.device,
      failedRules: evaluateRulesData?.data?.failedRules,
      passedRules: evaluateRulesData?.data?.passedRules,
  });
  }, [evaluateRulesData]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<Device>[] = [
    {
      id: 'name',
      header:
        <>
          <Button
            iconAlign='right'
            iconName={collectionProps.expandableRows?.expandedItems && collectionProps.expandableRows.expandedItems.length === 0
              ? 'treeview-expand'
              : 'treeview-collapse'
            }
            onClick={() => expand()}
            variant='icon'
          />
          {bundle.getMessage('name')}
        </>,
      cell: item =>
        <DeviceIcon
          deviceId={item.id}
          display={`${item.name} (${item.hardwareType.replace(/_/g, ' ')})`}
          documentId={''}
          siteCode={''}
          type={item.hardwareType}
        />,
    },
    {
      id: 'action',
      header: bundle.getMessage('actions'),
      cell: item =>
        <SpaceBetween direction='horizontal' size='xxxs'>
          {deviceActions(item.id).map(action => {
            return(
              <Button
                ariaLabel={action.text}
                disabled={action.disabled}
                disabledReason={action.disabledReason}
                iconName={action.iconName}
                key={action.id}
                onClick={() => act({ type: action.id as actionType, payload: { device: item } })}
                variant='icon'
              />);
            })}
        </SpaceBetween>,
    },
  ];

  const deviceActions = (deviceId?: string): ButtonDropdownProps.ItemOrGroup[] => {
    const evaluatingDeviceRules = (documentDevicesRulesEvaluating.find(d => d.id === deviceId) !== undefined);
    const device = openDocumentsContext.currentDocument.temporaryDocument.devices.find(d => d.id === deviceId);
    debug(`DocumentDevicesTablePanel() deviceActions() deviceId is ${deviceId} device is ${JSON.stringify(device)}`);

    const buttonDropDownProps: ButtonDropdownProps.ItemOrGroup[] = [
      {
       iconName: 'copy',
        id: actionType.copySettingsToOtherDevices,
        text: bundle.getMessage('copy'),
      },
      {
        iconName: 'settings',
        id: actionType.findTemplate,
        text: `${bundle.getMessage('apply-template')}...`,
      },
      {
        iconName: 'remove',
        id: actionType.remove,
        text: bundle.getMessage('remove'),
      },
      {
        iconName: 'file',
        id: actionType.save,
        text: bundle.getMessage('save'),
      },
      {
        iconName: 'send',
        id: actionType.send,
        text: bundle.getMessage('send'),
      },
      {
        iconName: 'undo',
        id: actionType.undo,
        text: bundle.getMessage('undo'),
      },
    ];

    if (!deviceId) {
      buttonDropDownProps.push({
        iconName: evaluatingDeviceRules ? 'status-pending' : 'check',
        id: actionType.verify,
        text: bundle.getMessage('verify'),
      });
    }

    if (device?.hardwareTemplateDeviceId && device?.hardwareTemplateDeviceId) {
      buttonDropDownProps.push({
        disabled: evaluatingDeviceRules || (deviceId === undefined && documentDevicesRulesEvaluating.length > 0),
        iconName: evaluatingDeviceRules ? 'status-pending' : 'check',
        id: actionType.verify,
        text: bundle.getMessage('verify'),
      });
    }

    return buttonDropDownProps;
  }
 
  return(
    <Container
      header={
        <Header
          actions={
            <ButtonDropdown
              disabled={selectedItems.length !== 1}
              expandToViewport
              items={deviceActions(selectDevice[0]?.id)}
              onItemClick={({ detail }) => act({ type: detail.id as actionType, payload: {} })}
              variant='primary'
            >
              {bundle.getMessage('actions')}
            </ButtonDropdown>
          }
        >
          {bundle.getMessage('devices')} {`(${itemsCount().toString()})`}
        </Header>}
    >
      <Modal
        onDismiss={() => setShowApplyTemplate(false)}
        header={
          <h2>
            {bundle.getMessage('apply-template')}
          </h2>
        }
        size='large'
        visible={showApplyTemplate && deviceForApplyTemplate !== null}
      >
        <ApplyTemplateTablePanel
          applyTemplate={props.applyTemplate}
          closeApplyTemplate={() => setShowApplyTemplate(false)}
          deviceForApplyTemplate={deviceForApplyTemplate.current!}
        />
      </Modal>
      {documentDeviceRulesEvaluation
      &&
      documentDeviceForRulesEvaluation
      &&
      <Modal
        onDismiss={() => {
          setDocumentDeviceRulesEvaluation(null);
          setDocumentDeviceForRulesEvaluation(null);
        }}
        header={
          <h2>
            {bundle.getMessage('rules-evaluation')}
          </h2>
        }
        size='large'
        visible={documentDeviceRulesEvaluation !== null}
      >
        <DocumentRulesEvaluationTablePanel
          rulesEvaluating={documentDevicesRulesEvaluating.length > 0}
          rulesEvaluatingPercentComplete={100}
          rulesEvaluations={[documentDeviceRulesEvaluation]}
          rulesResponses={documentDevicesRulesResponses}
        />
      </Modal>}
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        items={items}
        onSelectionChange={({ detail }) => selectDevice(detail.selectedItems) }
        resizableColumns={true}
        selectedItems={selectedItems}
        selectionType='multi'
      />
    </Container>
  );
}