import {
  SessionActionType,
  useSessionContext,
} from '../SessionContext';
import { QueryKey } from '../constants';
import { queryUserPreferences } from '../../utils';
import { useQuery } from '@tanstack/react-query';

export function useUserPreferences(userId: string) {
  const sessionContext = useSessionContext();

  return useQuery({
    enabled: !!userId,
    queryKey: [QueryKey.userPreferences],
    queryFn: async () => {
      const userPreferences = await queryUserPreferences(userId);
      if (userPreferences) sessionContext.dispatch(
        {
          type: SessionActionType.setSessionUserPreferences,
          payload: {
            userPreferences,
          },
        });
      return(await queryUserPreferences(userId));
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
    staleTime: Infinity,
    throwOnError: true,
  });
}
