import 'src/styles/temp.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import {
  SessionActionType,
  useSessionContext,
} from './common/SessionContext';
import App from './App';
import { OpenDocumentsProvider } from './Documents/OpenDocumentsContext';
import { OpenReviewsProvider } from './Reviews/OpenReviewsContext';
import { OpenTemplatesProvider } from './Templates/OpenTemplatesContext';
import { SesameUserPermissions } from 'src/API';
import { debug } from 'src/utils';
import { useEffect } from 'react';

const queryClient = new QueryClient();

interface IAppRouter {
  personId: string;
  userId: string;
  userPermissions: SesameUserPermissions | null;
  userSites: string[];
  username: string;
}

export function AppRouter(props: IAppRouter) {
  debug(`AppRouter() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  useEffect(() => {
    sessionContext.dispatch({ type: SessionActionType.setSessionPersonId, payload: { personId: props.personId }});
    sessionContext.dispatch({ type: SessionActionType.setSessionUserId, payload: { userId: props.userId }});
    if (props.userPermissions) sessionContext.dispatch(
      {
        type: SessionActionType.setSessionUserPermissions,
        payload: {
          userPermissions: props.userPermissions,
        }
      });
    sessionContext.dispatch({ type: SessionActionType.setSessionUserSites, payload: { userSites: props.userSites }});
    sessionContext.dispatch({ type: SessionActionType.setSessionUsername, payload: { username: props.username }});
  }, []);

  useEffect(() => {
    sessionContext.dispatch({ type: SessionActionType.setSessionUserPermissions, payload: { userPermissions: props.userPermissions }});
  }, [props.userPermissions]);

  useEffect(() => {
    debug(`AppRouter() useEffect()[props.userSites] props.userSites is ${JSON.stringify(props.userSites)}`);
    sessionContext.dispatch({ type: SessionActionType.setSessionUserSites, payload: { userSites: props.userSites }});
  }, [props.userSites]);

  return(
    <QueryClientProvider client={queryClient}>
      <OpenTemplatesProvider>
        <OpenDocumentsProvider>
          <OpenReviewsProvider>
            <Router>
              <Routes>
                <Route
                  path='*'
                  element={ <App /> }
                />
              </Routes>
            </Router>
          </OpenReviewsProvider>
        </OpenDocumentsProvider>
      </OpenTemplatesProvider>
    </QueryClientProvider>
  );
}
