import {
  ComponentType,
  UIAttribute,
} from '../common/constants';
import {
  Container,
  Form,
  FormField,
  Header,
  Icon,
  Input,
  Select,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import {
  Device,
  DeviceSettings,
} from 'src/API';
import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from './OpenDocumentsContext';
import {
  useEffect,
  useState,
} from 'react';
import { debug } from 'src/utils';

export interface IDocumentDeviceSettingsProps {}

export default function DocumentDeviceSettings(props: IDocumentDeviceSettingsProps) {
  debug(`DocumentDeviceSettings() props is ${JSON.stringify(props)}`);

  const openDocumentsContext = useOpenDocumentsContext();

  const [documentDevice, setDocumentDevice] = useState<Device>();

  const invalidValue = (fieldName: string): boolean => {
    debug(`DocumentDeviceSettings() invalidValue() fieldName is ${fieldName}`);
    const currentDeviceId = openDocumentsContext.currentDeviceId;
    if (!currentDeviceId) return true;
    const device = openDocumentsContext.currentDocument?.temporaryDocument.devices?.find(d => d.id === currentDeviceId);
    debug(`DocumentDeviceSettings() invalidValue() device is ${JSON.stringify(device)}`);
    if (!device) return true;
    const failedRules = device.failedRules;
    debug(`DocumentDeviceSettings() invalidValue() failedRules is ${JSON.stringify(failedRules)}`);
    if (!failedRules) return false;
    const failedConditionOnField = failedRules.find(r => r?.conditions?.find(c => c.key === fieldName) !== undefined);
    debug(`DocumentDeviceSettings() invalidValue() failedConditionOnField is ${failedConditionOnField}`);
    if (failedConditionOnField) return true;
    return false;
  }

  useEffect(() => {
    debug(`DocumentDeviceSettings() useEffect()[openDocumentsContext.currentDeviceId, openDocumentsContext.currentDocument.temporaryDocument.devices]`);
    const currentDeviceId = openDocumentsContext.currentDeviceId;
    if (!currentDeviceId) {
      setDocumentDevice(undefined);
      return;
    }
    const device = openDocumentsContext.currentDocument?.temporaryDocument.devices?.find(d => d.id === currentDeviceId);
    if (!device) return;
    setDocumentDevice({...device});
  }, [openDocumentsContext.currentDeviceId, openDocumentsContext.currentDocument?.temporaryDocument?.devices]);

  if (openDocumentsContext.currentDeviceId === null) return(<></>);

  const createHardwareDeviceForm = () => {
    debug(`DocumentDevice() createHardwareDeviceForm() documentDevice is ${JSON.stringify(documentDevice)}`);
    return(
      <Container
        header={
          <Header
          >
            {documentDevice?.name}
          </Header>}
      >
      <form onSubmit={event => event.preventDefault()}>
        <Form>
          <Container>
            <SpaceBetween direction='vertical' size='xxxs'>
              {Array.from(documentDevice?.settings || [])
                ?.sort((dsA: any, dsB: any): number => {
                  if (!dsA || !dsB) return 0;
                  const sequenceA = parseInt(dsA?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  const sequenceB = parseInt(dsB?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  return sequenceA > sequenceB ? 1 : -1;
              })
              .map((ds: (DeviceSettings | null)) => {
                if (ds === null) return(<></>);
                const componentType = ds.uiAttributes?.find((a: any) => a?.name === UIAttribute.componentType)?.value || ComponentType.input;
                return(
                  <FormField
                    key={ds?.key}
                    label={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.label)?.value || ds?.key}
                  >
                    {componentType === ComponentType.input && documentDevice?.settings?.length && documentDevice.settings.length > 0
                    ? 
                      <Input
                        disabled={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly) ?.value === 'true' || false}
                        invalid={invalidValue(ds.key)}
                        onChange={({ detail }) => {
                          const deviceSettings = documentDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds?.key,
                            uiAttributes: ds?.uiAttributes,
                            value: detail.value,
                          };
                          const newDocumentDevice: Device = {
                            ...documentDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setDocumentDevice(newDocumentDevice);
                          openDocumentsContext.dispatch({ type: OpenDocumentActionType.updateDevice, payload: { device: newDocumentDevice } });
                        }}
                        value={ds.value || ''}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.select && documentDevice?.settings?.length && documentDevice.settings.length > 0
                    ?
                      <Select
                        disabled={ds.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly)?.value === 'true' || false}
                        expandToViewport
                        onChange={({ detail }) => {
                          const deviceSettings = documentDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds.key,
                            uiAttributes: ds.uiAttributes,
                            value: detail.selectedOption.value,
                          };
                          const newDocumentDevice: Device = {
                            ...documentDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setDocumentDevice(newDocumentDevice);
                          openDocumentsContext.dispatch({ type: OpenDocumentActionType.updateDevice, payload: { device: newDocumentDevice } });
                        }}
                        options={JSON.parse(ds.uiAttributes.find(a => a.name === 'options').value).sort((a, b) => a.label < b.label ? -1 : 1)}
                        selectedOption={({ label: ds.value, value: ds.value})}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.toggle && documentDevice?.settings?.length && documentDevice.settings.length > 0
                    ? 
                      <Toggle
                        disabled={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly)
                          ?.value === 'true' || false}
                        onChange={({ detail }) => {
                          const deviceSettings = documentDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds?.key,
                            uiAttributes: ds?.uiAttributes,
                            value: detail.checked.toString(),
                          };
                          const newDocumentDevice: Device = {
                            ...documentDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setDocumentDevice(newDocumentDevice);
                          openDocumentsContext.dispatch({ type: OpenDocumentActionType.updateDevice, payload: { device: newDocumentDevice } });
                        }}
                        checked={ds.value === 'true' || false}
                      >
                        {invalidValue(ds.key) ? <Icon name='flag' variant='error'></Icon> : undefined}
                      </Toggle>
                    :
                      <></>}
                  </FormField>);
                })}
            </SpaceBetween>
          </Container>
        </Form>
      </form>
      </Container>
    );
  };

  return(
    <>
      {createHardwareDeviceForm()}
    </>);
}