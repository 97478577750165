/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUserPreferences = /* GraphQL */ `mutation CreateUserPreferences(
  $input: CreateUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  createUserPreferences(input: $input, condition: $condition) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPreferencesMutationVariables,
  APITypes.CreateUserPreferencesMutation
>;
export const updateUserPreferences = /* GraphQL */ `mutation UpdateUserPreferences(
  $input: UpdateUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  updateUserPreferences(input: $input, condition: $condition) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPreferencesMutationVariables,
  APITypes.UpdateUserPreferencesMutation
>;
export const deleteUserPreferences = /* GraphQL */ `mutation DeleteUserPreferences(
  $input: DeleteUserPreferencesInput!
  $condition: ModelUserPreferencesConditionInput
) {
  deleteUserPreferences(input: $input, condition: $condition) {
    userId
    preferences
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPreferencesMutationVariables,
  APITypes.DeleteUserPreferencesMutation
>;
export const createUserAction = /* GraphQL */ `mutation CreateUserAction(
  $input: CreateUserActionInput!
  $condition: ModelUserActionConditionInput
) {
  createUserAction(input: $input, condition: $condition) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActionMutationVariables,
  APITypes.CreateUserActionMutation
>;
export const updateUserAction = /* GraphQL */ `mutation UpdateUserAction(
  $input: UpdateUserActionInput!
  $condition: ModelUserActionConditionInput
) {
  updateUserAction(input: $input, condition: $condition) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserActionMutationVariables,
  APITypes.UpdateUserActionMutation
>;
export const deleteUserAction = /* GraphQL */ `mutation DeleteUserAction(
  $input: DeleteUserActionInput!
  $condition: ModelUserActionConditionInput
) {
  deleteUserAction(input: $input, condition: $condition) {
    userId
    actionType
    actionTime
    parameters
    ttl
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserActionMutationVariables,
  APITypes.DeleteUserActionMutation
>;
export const createApplicationSettings = /* GraphQL */ `mutation CreateApplicationSettings(
  $input: CreateApplicationSettingsInput!
  $condition: ModelApplicationSettingsConditionInput
) {
  createApplicationSettings(input: $input, condition: $condition) {
    versionId
    hardwareTypes {
      type
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          uiAttributes {
            name
            value
          }
        }
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateApplicationSettingsMutationVariables,
  APITypes.CreateApplicationSettingsMutation
>;
export const updateApplicationSettings = /* GraphQL */ `mutation UpdateApplicationSettings(
  $input: UpdateApplicationSettingsInput!
  $condition: ModelApplicationSettingsConditionInput
) {
  updateApplicationSettings(input: $input, condition: $condition) {
    versionId
    hardwareTypes {
      type
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          uiAttributes {
            name
            value
          }
        }
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateApplicationSettingsMutationVariables,
  APITypes.UpdateApplicationSettingsMutation
>;
export const deleteApplicationSettings = /* GraphQL */ `mutation DeleteApplicationSettings(
  $input: DeleteApplicationSettingsInput!
  $condition: ModelApplicationSettingsConditionInput
) {
  deleteApplicationSettings(input: $input, condition: $condition) {
    versionId
    hardwareTypes {
      type
      name
      description
      hardwareDevices {
        name
        description
        fields {
          name
          segments {
            name
            sequence
            value
          }
          segmentDelimiter
          uiAttributes {
            name
            value
          }
        }
        rules {
          description
          id
          operator
          not
          conditions {
            id
            group
            groupParentId
            groupOperator
            key
            value
            not
            operator
          }
        }
        slots {
          description
          name
          hardwareType
          id
          settings {
            key
            value
          }
        }
      }
      settings
    }
    pacs {
      name
      description
      settings
    }
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteApplicationSettingsMutationVariables,
  APITypes.DeleteApplicationSettingsMutation
>;
export const createHardwareTemplate = /* GraphQL */ `mutation CreateHardwareTemplate(
  $input: CreateHardwareTemplateInput!
  $condition: ModelHardwareTemplateConditionInput
) {
  createHardwareTemplate(input: $input, condition: $condition) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    hardwareType
    id
    name
    status
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateHardwareTemplateMutationVariables,
  APITypes.CreateHardwareTemplateMutation
>;
export const updateHardwareTemplate = /* GraphQL */ `mutation UpdateHardwareTemplate(
  $input: UpdateHardwareTemplateInput!
  $condition: ModelHardwareTemplateConditionInput
) {
  updateHardwareTemplate(input: $input, condition: $condition) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    hardwareType
    id
    name
    status
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateHardwareTemplateMutationVariables,
  APITypes.UpdateHardwareTemplateMutation
>;
export const deleteHardwareTemplate = /* GraphQL */ `mutation DeleteHardwareTemplate(
  $input: DeleteHardwareTemplateInput!
  $condition: ModelHardwareTemplateConditionInput
) {
  deleteHardwareTemplate(input: $input, condition: $condition) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    hardwareType
    id
    name
    status
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteHardwareTemplateMutationVariables,
  APITypes.DeleteHardwareTemplateMutation
>;
export const createSiteHardwareConfiguration = /* GraphQL */ `mutation CreateSiteHardwareConfiguration(
  $input: CreateSiteHardwareConfigurationInput!
  $condition: ModelSiteHardwareConfigurationConditionInput
) {
  createSiteHardwareConfiguration(input: $input, condition: $condition) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    id
    name
    status
    siteCode
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.CreateSiteHardwareConfigurationMutationVariables,
  APITypes.CreateSiteHardwareConfigurationMutation
>;
export const updateSiteHardwareConfiguration = /* GraphQL */ `mutation UpdateSiteHardwareConfiguration(
  $input: UpdateSiteHardwareConfigurationInput!
  $condition: ModelSiteHardwareConfigurationConditionInput
) {
  updateSiteHardwareConfiguration(input: $input, condition: $condition) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    id
    name
    status
    siteCode
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.UpdateSiteHardwareConfigurationMutationVariables,
  APITypes.UpdateSiteHardwareConfigurationMutation
>;
export const deleteSiteHardwareConfiguration = /* GraphQL */ `mutation DeleteSiteHardwareConfiguration(
  $input: DeleteSiteHardwareConfigurationInput!
  $condition: ModelSiteHardwareConfigurationConditionInput
) {
  deleteSiteHardwareConfiguration(input: $input, condition: $condition) {
    description
    devices {
      id
      name
      hardwareTemplateId
      hardwareTemplateDeviceId
      hardwareType
      hardwareDeviceName
      address
      settings {
        key
        segments {
          name
          sequence
          value
        }
        segmentDelimiter
        value
        uiAttributes {
          name
          value
        }
      }
      parentDeviceId
      rules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      passedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      failedRules {
        description
        id
        operator
        not
        conditions {
          id
          group
          groupParentId
          groupOperator
          key
          value
          not
          operator
        }
      }
      slots {
        description
        name
        hardwareType
        id
        settings {
          key
          value
        }
      }
    }
    id
    name
    status
    siteCode
    versionId
    createdAt
    updatedAt
  }
}
` as GeneratedMutation<
  APITypes.DeleteSiteHardwareConfigurationMutationVariables,
  APITypes.DeleteSiteHardwareConfigurationMutation
>;
