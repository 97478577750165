import {
  Alert,
  Box,
  Button,
  CollectionPreferences,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter, 
 } from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './DocumentsTableConfig';
import {
  UseQueryResult,
  useMutation,
} from '@tanstack/react-query';
import {
  useEffect,
  useState,
} from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import { SiteHardwareConfiguration } from 'src/API';
import { debug } from 'src/utils';
import { deleteSiteHardwareConfiguration } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useSessionContext } from '../common/SessionContext';

export interface IDocumentsTablePanelProps {
  closeDocument: Function;
  createNewDocument: Function;
  createNewDocumentRevision: Function;
  editDocument: Function;
  selectedDocument: SiteHardwareConfiguration | null;
  setSelectedDocument: Function;
  documentsQuery: UseQueryResult<SiteHardwareConfiguration[]>;
}

export function DocumentsTablePanel(props: IDocumentsTablePanelProps ) {
  debug(`DocumentsTablePanel() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  const [allItems, setAllItems] = useState<SiteHardwareConfiguration[]>(props.documentsQuery?.data || []);
  const [clickedDocument, setClickedDocument] = useState<SiteHardwareConfiguration | null>(null);
  const [error, setError] = useState<string>();
  const [hideTable] = useState<boolean>(false);
  const [pageSize] = useState<number>(5);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<SiteHardwareConfiguration | null>(props.selectedDocument);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentsTablePanel');

  const deleteSiteHardwareConfigurationMutation = useMutation(
    {
      mutationFn: deleteSiteHardwareConfiguration,
      onSuccess: (data) => {
        setAllItems([]);
        setError(undefined);
        setSelectedDocument(null);
        props.documentsQuery.refetch();
        props.closeDocument(data);
        setShowDeleteConfirmation(false);
      },
      onError: (error) => {
        console.error(error);
        setError(error.message);
      },
    }
  );

  const refresh = async () => {
    props.documentsQuery.refetch();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-documents-found') : 'No documents found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    setSelectedDocument(null);
    refresh();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const deleteDocument = async (id: string) => {
    await deleteSiteHardwareConfigurationMutation.mutateAsync({id});
  };

  useEffect(() => {
    if (!props.documentsQuery?.data) {
      setAllItems([]);
      return;
    }
    setAllItems(props.documentsQuery.data);
    if (props.documentsQuery.data.length === 1) {
      setSelectedDocument(props.documentsQuery.data[0]);
    }
  }, [props.documentsQuery.data]);

  useEffect(() => {
    props.setSelectedDocument(selectedDocument);
  }, [selectedDocument]);

  useEffect(() => {
    if (props.selectedDocument !== selectedDocument) setSelectedDocument(props.selectedDocument);
  }, [props.selectedDocument]);

  useEffect(() => {
    if (clickedDocument) props.editDocument();
  }, [clickedDocument]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowDeleteConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                loading={deleteSiteHardwareConfigurationMutation.isPending}
                iconName='check'
                onClick={() => deleteDocument(selectedDocument!.id)}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowDeleteConfirmation(false)}
        visible={showDeleteConfirmation}
      >
        {`${bundle.getMessage('delete')} ${selectedDocument?.name} ${selectedDocument?.versionId}`}
      </Modal>
      {
        error
        &&
        <Alert
          header={bundle.getMessage('error')}
          type='error'
          dismissible
          onDismiss={() => setError('')}
        >
          {error}
        </Alert>
      }
      <div id='DocumentsTablePanel' hidden={hideTable}>
        <Table
          {...collectionProps}
          columnDefinitions={[
            {
              id: 'name',
              header: bundle.getMessage('name'),
              cell: item =>
                <Link
                  onClick={async () => {
                    setClickedDocument(item);
                    setSelectedDocument(item);
                    props.setSelectedDocument(item);
                  }}
                >
                  {item.name}
                </Link>,
              sortingField: 'name',
            },
            ...ColumnDefinitions
          ]}
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-documents')}
              filteringPlaceholder={bundle.getMessage('find-documents')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
          }
          footer={
            <Header
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <Button
                    disabled={!sessionContext.siteCode}
                    onClick={() => props.createNewDocument()}
                    iconName='add-plus'
                    variant='primary'
                  >
                    {bundle.getMessage('create')}
                  </Button>
                  <Button
                    disabled={selectedDocument === null}
                    iconName='edit'
                    onClick={() => props.editDocument()}
                    variant='normal'
                  >
                    {bundle.getMessage('edit')}
                  </Button>
                  <Button
                    disabled={selectedDocument === null}
                    onClick={() => props.createNewDocumentRevision()}
                    iconName='copy'
                    variant='normal'
                  >
                    {bundle.getMessage('new-revision')}
                  </Button>
                  <Button
                    disabled={selectedDocument === null}
                    onClick={() => setShowDeleteConfirmation(true)}
                    iconName='remove'
                    variant='normal'
                  >
                    {bundle.getMessage('delete')}
                  </Button>
                </SpaceBetween>
              }
            >
            </Header>
          }
          header={
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <Button
                  loading={refreshing}
                  key='refreshBtn'
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                />
              }
            >
              {`${sessionContext.siteCode} ${bundle.getMessage('documents')}`}
            </Header>
          }
          items={items}
          loading={props.documentsQuery?.fetchStatus == 'fetching'}
          empty={props.documentsQuery?.status == 'error'
            ?
              <Alert type='error'>
                `${bundle.getMessage('query-failure')} (${typeof props.documentsQuery?.error === 'object'
                   ? JSON.stringify(props.documentsQuery?.error)
                   : 'TODO'
                   })`
              </Alert>
            : bundle.getMessage('no-documents-found')
          }
          loadingText={bundle.getMessage('loading-documents')}
          onSelectionChange={({ detail }) => setSelectedDocument(detail.selectedItems[0]) }
          pagination={
            allItems.length > pageSize
            &&
            <Pagination
              {...paginationProps}
              ariaLabels={PaginationLabels}
            />
          }
          preferences={
            <CollectionPreferences
              onConfirm={() => alert('TODO: save preferences')}
              title={bundle.getMessage('preferences')}
              confirmLabel={bundle.getMessage('confirm')}
              cancelLabel={bundle.getMessage('cancel')}
              preferences={{
                pageSize: pageSize,
              }}
              pageSizePreference={{
                title: bundle.getMessage('select-page-size'),
                options: [
                  { value: 5, label: '5' },
                  { value: 10, label: '10' },
                  { value: 15, label: '15' },
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ],
              }}
            />
          }
          resizableColumns={true}
          selectedItems={selectedDocument ? [selectedDocument] : []}
          selectionType='single'
          trackBy='id'
        />
      </div>
    </>);
}
