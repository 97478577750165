import {
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useState } from 'react';

interface ICreateNewDocumentRevisionProps {
  cancel: Function;
  createDocumentRevision: Function;
  creatingDocumentRevision: boolean;
}

export function CreateNewDocumentRevision(props: ICreateNewDocumentRevisionProps) {
  debug(`CreateNewDocumentRevision() props is ${JSON.stringify(props)}`);

  const [versionId, setVersionId] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Documents.CreateNewDocumentRevision');

  const createNewDocumentRevision = () => {
    setVersionId('');
    props.createDocumentRevision(versionId);
  };

  const cancelCreateNewDocumentRevision = () => {
    setVersionId('');
    props.cancel();
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <form onSubmit={event => event.preventDefault()}>
      <Form
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button
                formAction='none'
                onClick={() => cancelCreateNewDocumentRevision()}
                variant='normal'
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                disabled={!versionId}
                loading={props.creatingDocumentRevision}
                onClick={() => createNewDocumentRevision()}
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </SpaceBetween>
          }
        >
        <FormField label={bundle.getMessage('version')} >
          <Input
            onChange={( { detail }) => setVersionId(detail.value)}
            value={versionId}
          />
        </FormField>
      </Form>
    </form>);
}
