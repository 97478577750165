import {
  createJsonSchemaFromGraphQLType,
  queryApplicationSettings,
} from 'src/components/utils';
import { Ajv } from 'ajv';
import { QueryKey } from '../constants';
import { useQuery } from '@tanstack/react-query';

export function useSelectedApplicationSettings(selectedApplicationSettingsVersion: string) {
  return useQuery({
    enabled: selectedApplicationSettingsVersion !== null,
    queryKey: [QueryKey.selectedApplicationSettings],
    queryFn: async () => {
      const appSettingsSchema = createJsonSchemaFromGraphQLType('ApplicationSettings');
      if (!selectedApplicationSettingsVersion || !selectedApplicationSettingsVersion) return null;
      const appSettings = await queryApplicationSettings(selectedApplicationSettingsVersion);
      const isValidAppSettings = new Ajv({ strict: true }).compile(appSettingsSchema);
      if (!isValidAppSettings(appSettings)) {
        console.error('Invalid application settings', isValidAppSettings.errors);
        throw new Error(`Invalid application settings: ${JSON.stringify(isValidAppSettings.errors)}`);
      }
      return appSettings;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    staleTime: Infinity,
    throwOnError: true,
  });
}
