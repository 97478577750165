/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserPreferencesInput = {
  userId: string,
  preferences: string,
};

export type ModelUserPreferencesConditionInput = {
  preferences?: ModelStringInput | null,
  and?: Array< ModelUserPreferencesConditionInput | null > | null,
  or?: Array< ModelUserPreferencesConditionInput | null > | null,
  not?: ModelUserPreferencesConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type UserPreferences = {
  userId: string,
  preferences: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserPreferencesInput = {
  userId: string,
  preferences?: string | null,
};

export type DeleteUserPreferencesInput = {
  userId: string,
};

export type CreateUserActionInput = {
  userId: string,
  actionType: string,
  actionTime: number,
  parameters?: string | null,
  ttl: number,
};

export type ModelUserActionConditionInput = {
  parameters?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelUserActionConditionInput | null > | null,
  or?: Array< ModelUserActionConditionInput | null > | null,
  not?: ModelUserActionConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UserAction = {
  userId: string,
  actionType: string,
  actionTime: number,
  parameters?: string | null,
  ttl: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserActionInput = {
  userId: string,
  actionType: string,
  actionTime: number,
  parameters?: string | null,
  ttl?: number | null,
};

export type DeleteUserActionInput = {
  userId: string,
  actionType: string,
  actionTime: number,
};

export type CreateApplicationSettingsInput = {
  versionId: string,
  hardwareTypes?: Array< HardwareTypeDetailsInput > | null,
  pacs?: Array< PACSInput > | null,
};

export type HardwareTypeDetailsInput = {
  type: HardwareType,
  name: string,
  description: string,
  hardwareDevices?: Array< HardwareDeviceInput > | null,
  settings?: string | null,
};

export enum HardwareType {
  Alarm_Input = "Alarm_Input",
  Alarm_Output = "Alarm_Output",
  Aux_Input = "Aux_Input",
  Aux_Output = "Aux_Output",
  Command_Keypad = "Command_Keypad",
  Controller = "Controller",
  Door_Contact = "Door_Contact",
  Input_Panel = "Input_Panel",
  Multiple = "Multiple",
  Output_Panel = "Output_Panel",
  Port = "Port",
  Reader = "Reader",
  REX = "REX",
  RIM = "RIM",
  Strike = "Strike",
  Undefined = "Undefined",
}


export type HardwareDeviceInput = {
  name: string,
  description: string,
  fields?: Array< HardwareFieldInput > | null,
  rules?: Array< RuleInput > | null,
  slots?: Array< SlotInput > | null,
};

export type HardwareFieldInput = {
  name: string,
  segments?: Array< SegmentInput | null > | null,
  segmentDelimiter?: string | null,
  uiAttributes?: Array< UIAttributeInput | null > | null,
};

export type SegmentInput = {
  name: string,
  sequence: number,
  value: string,
};

export type UIAttributeInput = {
  name: string,
  value: string,
};

export type RuleInput = {
  description: string,
  id: string,
  operator: RuleOperator,
  not: boolean,
  conditions?: Array< RuleConditionInput > | null,
};

export enum RuleOperator {
  AND = "AND",
  OR = "OR",
}


export type RuleConditionInput = {
  id: string,
  group: boolean,
  groupParentId?: string | null,
  groupOperator?: RuleConditionGroupOperator | null,
  key: string,
  value: string,
  not: boolean,
  operator?: RuleConditionOperator | null,
};

export enum RuleConditionGroupOperator {
  AND = "AND",
  OR = "OR",
}


export enum RuleConditionOperator {
  equals = "equals",
  format_is = "format_is",
  includes = "includes",
  greater_than = "greater_than",
  greater_than_or_equal_to = "greater_than_or_equal_to",
  less_than = "less_than",
  less_than_or_equal_to = "less_than_or_equal_to",
  not_equal = "not_equal",
  not_includes = "not_includes",
}


export type SlotInput = {
  description: string,
  name: string,
  hardwareType: HardwareType,
  id: string,
  settings?: Array< SlotSettingsInput | null > | null,
};

export type SlotSettingsInput = {
  key: string,
  value: string,
};

export type PACSInput = {
  name: string,
  description: string,
  settings?: string | null,
};

export type ModelApplicationSettingsConditionInput = {
  and?: Array< ModelApplicationSettingsConditionInput | null > | null,
  or?: Array< ModelApplicationSettingsConditionInput | null > | null,
  not?: ModelApplicationSettingsConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ApplicationSettings = {
  versionId: string,
  hardwareTypes?:  Array<HardwareTypeDetails > | null,
  pacs?:  Array<PACS > | null,
  createdAt: string,
  updatedAt: string,
};

export type HardwareTypeDetails = {
  type: HardwareType,
  name: string,
  description: string,
  hardwareDevices?:  Array<HardwareDevice > | null,
  settings?: string | null,
};

export type HardwareDevice = {
  name: string,
  description: string,
  fields?:  Array<HardwareField > | null,
  rules?:  Array<Rule > | null,
  slots?:  Array<Slot > | null,
};

export type HardwareField = {
  name: string,
  segments?:  Array<Segment | null > | null,
  segmentDelimiter?: string | null,
  uiAttributes?:  Array<UIAttribute | null > | null,
};

export type Segment = {
  name: string,
  sequence: number,
  value: string,
};

export type UIAttribute = {
  name: string,
  value: string,
};

export type Rule = {
  description: string,
  id: string,
  operator: RuleOperator,
  not: boolean,
  conditions?:  Array<RuleCondition > | null,
};

export type RuleCondition = {
  id: string,
  group: boolean,
  groupParentId?: string | null,
  groupOperator?: RuleConditionGroupOperator | null,
  key: string,
  value: string,
  not: boolean,
  operator?: RuleConditionOperator | null,
};

export type Slot = {
  description: string,
  name: string,
  hardwareType: HardwareType,
  id: string,
  settings?:  Array<SlotSettings | null > | null,
};

export type SlotSettings = {
  key: string,
  value: string,
};

export type PACS = {
  name: string,
  description: string,
  settings?: string | null,
};

export type UpdateApplicationSettingsInput = {
  versionId: string,
  hardwareTypes?: Array< HardwareTypeDetailsInput > | null,
  pacs?: Array< PACSInput > | null,
};

export type DeleteApplicationSettingsInput = {
  versionId: string,
};

export type CreateHardwareTemplateInput = {
  description: string,
  devices?: Array< DeviceInput > | null,
  hardwareType: HardwareType,
  id?: string | null,
  name: string,
  status: Status,
  versionId: string,
};

export type DeviceInput = {
  id: string,
  name: string,
  hardwareTemplateId?: string | null,
  hardwareTemplateDeviceId?: string | null,
  hardwareType: HardwareType,
  hardwareDeviceName: string,
  address?: number | null,
  settings?: Array< DeviceSettingsInput > | null,
  parentDeviceId?: string | null,
  rules?: Array< RuleInput | null > | null,
  passedRules?: Array< RuleInput | null > | null,
  failedRules?: Array< RuleInput | null > | null,
  slots?: Array< SlotInput | null > | null,
};

export type DeviceSettingsInput = {
  key: string,
  segments?: Array< SegmentInput | null > | null,
  segmentDelimiter?: string | null,
  value: string,
  uiAttributes?: Array< UIAttributeInput | null > | null,
};

export enum Status {
  Active = "Active",
  Development = "Development",
  Inactive = "Inactive",
  Review = "Review",
  Temporary = "Temporary",
}


export type ModelHardwareTemplateConditionInput = {
  description?: ModelStringInput | null,
  hardwareType?: ModelHardwareTypeInput | null,
  name?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  versionId?: ModelStringInput | null,
  and?: Array< ModelHardwareTemplateConditionInput | null > | null,
  or?: Array< ModelHardwareTemplateConditionInput | null > | null,
  not?: ModelHardwareTemplateConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelHardwareTypeInput = {
  eq?: HardwareType | null,
  ne?: HardwareType | null,
};

export type ModelStatusInput = {
  eq?: Status | null,
  ne?: Status | null,
};

export type HardwareTemplate = {
  description: string,
  devices?:  Array<Device > | null,
  hardwareType: HardwareType,
  id: string,
  name: string,
  status: Status,
  versionId: string,
  createdAt: string,
  updatedAt: string,
};

export type Device = {
  id: string,
  name: string,
  hardwareTemplateId?: string | null,
  hardwareTemplateDeviceId?: string | null,
  hardwareType: HardwareType,
  hardwareDeviceName: string,
  address?: number | null,
  settings?:  Array<DeviceSettings > | null,
  parentDeviceId?: string | null,
  rules?:  Array<Rule | null > | null,
  passedRules?:  Array<Rule | null > | null,
  failedRules?:  Array<Rule | null > | null,
  slots?:  Array<Slot | null > | null,
};

export type DeviceSettings = {
  key: string,
  segments?:  Array<Segment | null > | null,
  segmentDelimiter?: string | null,
  value: string,
  uiAttributes?:  Array<UIAttribute | null > | null,
};

export type UpdateHardwareTemplateInput = {
  description?: string | null,
  devices?: Array< DeviceInput > | null,
  hardwareType?: HardwareType | null,
  id: string,
  name?: string | null,
  status?: Status | null,
  versionId?: string | null,
};

export type DeleteHardwareTemplateInput = {
  id: string,
};

export type CreateSiteHardwareConfigurationInput = {
  description: string,
  devices?: Array< DeviceInput > | null,
  id?: string | null,
  name: string,
  status: Status,
  siteCode: string,
  versionId: string,
};

export type ModelSiteHardwareConfigurationConditionInput = {
  description?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  siteCode?: ModelStringInput | null,
  versionId?: ModelStringInput | null,
  and?: Array< ModelSiteHardwareConfigurationConditionInput | null > | null,
  or?: Array< ModelSiteHardwareConfigurationConditionInput | null > | null,
  not?: ModelSiteHardwareConfigurationConditionInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type SiteHardwareConfiguration = {
  description: string,
  devices?:  Array<Device > | null,
  id: string,
  name: string,
  status: Status,
  siteCode: string,
  versionId: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSiteHardwareConfigurationInput = {
  description?: string | null,
  devices?: Array< DeviceInput > | null,
  id: string,
  name?: string | null,
  status?: Status | null,
  siteCode?: string | null,
  versionId?: string | null,
};

export type DeleteSiteHardwareConfigurationInput = {
  id: string,
};

export type RulesResponse = {
  status: number,
  message: string,
  data?: RulesEvaluation | null,
};

export type RulesEvaluation = {
  device: Device,
  passedRules?:  Array<Rule > | null,
  failedRules?:  Array<Rule > | null,
};

export type SesameResponse = {
  status: number,
  message: string,
  data?: SesameUserPermissions | null,
};

export type SesameUserPermissions = {
  application?: SesameApplication | null,
  applicationPermissions?:  Array<SesameApplicationPermission > | null,
  resourcePermissions?:  Array<SesameResourcePermission | null > | null,
  expirationTimestamp?: number | null,
  userRegion?: string | null,
  userTimezone?: string | null,
};

export type SesameApplication = {
  applicationID: string,
  applicationName?: string | null,
};

export type SesameApplicationPermission = {
  applicationPermissionID: string,
  permissionName: string,
  applicationID?: string | null,
  metadata?: SesameMetaDataObject | null,
  displayName?: string | null,
  description?: string | null,
};

export type SesameMetaDataObject = {
  key?: string | null,
  value?: string | null,
};

export type SesameResourcePermission = {
  resource: SesameResource,
  permissionIDs?: Array< string > | null,
};

export type SesameResource = {
  resourceType: string,
  resourceID?: string | null,
  resourceName?: string | null,
};

export type ModelUserPreferencesFilterInput = {
  userId?: ModelStringInput | null,
  preferences?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserPreferencesFilterInput | null > | null,
  or?: Array< ModelUserPreferencesFilterInput | null > | null,
  not?: ModelUserPreferencesFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelUserPreferencesConnection = {
  items:  Array<UserPreferences | null >,
  nextToken?: string | null,
};

export type ModelUserActionPrimaryCompositeKeyConditionInput = {
  eq?: ModelUserActionPrimaryCompositeKeyInput | null,
  le?: ModelUserActionPrimaryCompositeKeyInput | null,
  lt?: ModelUserActionPrimaryCompositeKeyInput | null,
  ge?: ModelUserActionPrimaryCompositeKeyInput | null,
  gt?: ModelUserActionPrimaryCompositeKeyInput | null,
  between?: Array< ModelUserActionPrimaryCompositeKeyInput | null > | null,
  beginsWith?: ModelUserActionPrimaryCompositeKeyInput | null,
};

export type ModelUserActionPrimaryCompositeKeyInput = {
  actionType?: string | null,
  actionTime?: number | null,
};

export type ModelUserActionFilterInput = {
  userId?: ModelStringInput | null,
  actionType?: ModelStringInput | null,
  actionTime?: ModelIntInput | null,
  parameters?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserActionFilterInput | null > | null,
  or?: Array< ModelUserActionFilterInput | null > | null,
  not?: ModelUserActionFilterInput | null,
};

export type ModelUserActionConnection = {
  items:  Array<UserAction | null >,
  nextToken?: string | null,
};

export type ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyConditionInput = {
  eq?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  le?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  lt?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  ge?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  gt?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
  between?: Array< ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null > | null,
  beginsWith?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput | null,
};

export type ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyInput = {
  actionTime?: number | null,
  userId?: string | null,
};

export type ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyConditionInput = {
  eq?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  le?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  lt?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  ge?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  gt?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
  between?: Array< ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null > | null,
  beginsWith?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput | null,
};

export type ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyInput = {
  actionType?: string | null,
  userId?: string | null,
};

export type ModelApplicationSettingsFilterInput = {
  versionId?: ModelStringInput | null,
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelApplicationSettingsFilterInput | null > | null,
  or?: Array< ModelApplicationSettingsFilterInput | null > | null,
  not?: ModelApplicationSettingsFilterInput | null,
};

export type ModelApplicationSettingsConnection = {
  items:  Array<ApplicationSettings | null >,
  nextToken?: string | null,
};

export type ModelHardwareTemplateFilterInput = {
  description?: ModelStringInput | null,
  hardwareType?: ModelHardwareTypeInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  versionId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelHardwareTemplateFilterInput | null > | null,
  or?: Array< ModelHardwareTemplateFilterInput | null > | null,
  not?: ModelHardwareTemplateFilterInput | null,
};

export type ModelHardwareTemplateConnection = {
  items:  Array<HardwareTemplate | null >,
  nextToken?: string | null,
};

export type ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyConditionInput = {
  eq?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  le?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  lt?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  ge?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  gt?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  between?: Array< ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null > | null,
  beginsWith?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
};

export type ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyInput = {
  status?: Status | null,
  versionId?: string | null,
  name?: string | null,
};

export type ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyConditionInput = {
  eq?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  le?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  lt?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  ge?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  gt?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  between?: Array< ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
};

export type ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyInput = {
  name?: string | null,
  versionId?: string | null,
  status?: Status | null,
};

export type ModelSiteHardwareConfigurationFilterInput = {
  description?: ModelStringInput | null,
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  status?: ModelStatusInput | null,
  siteCode?: ModelStringInput | null,
  versionId?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSiteHardwareConfigurationFilterInput | null > | null,
  or?: Array< ModelSiteHardwareConfigurationFilterInput | null > | null,
  not?: ModelSiteHardwareConfigurationFilterInput | null,
};

export type ModelSiteHardwareConfigurationConnection = {
  items:  Array<SiteHardwareConfiguration | null >,
  nextToken?: string | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyConditionInput = {
  eq?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  le?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  lt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  ge?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  gt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
  between?: Array< ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null > | null,
  beginsWith?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyInput = {
  status?: Status | null,
  versionId?: string | null,
  name?: string | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyConditionInput = {
  eq?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  le?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  lt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  ge?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  gt?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
  between?: Array< ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput | null,
};

export type ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyInput = {
  name?: string | null,
  versionId?: string | null,
  status?: Status | null,
};

export type ModelSubscriptionUserPreferencesFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  preferences?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserPreferencesFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserPreferencesFilterInput | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionUserActionFilterInput = {
  userId?: ModelSubscriptionStringInput | null,
  actionType?: ModelSubscriptionStringInput | null,
  actionTime?: ModelSubscriptionIntInput | null,
  parameters?: ModelSubscriptionStringInput | null,
  ttl?: ModelSubscriptionIntInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserActionFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserActionFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionApplicationSettingsFilterInput = {
  versionId?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionApplicationSettingsFilterInput | null > | null,
  or?: Array< ModelSubscriptionApplicationSettingsFilterInput | null > | null,
};

export type ModelSubscriptionHardwareTemplateFilterInput = {
  description?: ModelSubscriptionStringInput | null,
  hardwareType?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  versionId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionHardwareTemplateFilterInput | null > | null,
  or?: Array< ModelSubscriptionHardwareTemplateFilterInput | null > | null,
};

export type ModelSubscriptionSiteHardwareConfigurationFilterInput = {
  description?: ModelSubscriptionStringInput | null,
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  siteCode?: ModelSubscriptionStringInput | null,
  versionId?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSiteHardwareConfigurationFilterInput | null > | null,
  or?: Array< ModelSubscriptionSiteHardwareConfigurationFilterInput | null > | null,
};

export type CreateUserPreferencesMutationVariables = {
  input: CreateUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type CreateUserPreferencesMutation = {
  createUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserPreferencesMutationVariables = {
  input: UpdateUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type UpdateUserPreferencesMutation = {
  updateUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserPreferencesMutationVariables = {
  input: DeleteUserPreferencesInput,
  condition?: ModelUserPreferencesConditionInput | null,
};

export type DeleteUserPreferencesMutation = {
  deleteUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserActionMutationVariables = {
  input: CreateUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type CreateUserActionMutation = {
  createUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserActionMutationVariables = {
  input: UpdateUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type UpdateUserActionMutation = {
  updateUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserActionMutationVariables = {
  input: DeleteUserActionInput,
  condition?: ModelUserActionConditionInput | null,
};

export type DeleteUserActionMutation = {
  deleteUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateApplicationSettingsMutationVariables = {
  input: CreateApplicationSettingsInput,
  condition?: ModelApplicationSettingsConditionInput | null,
};

export type CreateApplicationSettingsMutation = {
  createApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateApplicationSettingsMutationVariables = {
  input: UpdateApplicationSettingsInput,
  condition?: ModelApplicationSettingsConditionInput | null,
};

export type UpdateApplicationSettingsMutation = {
  updateApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteApplicationSettingsMutationVariables = {
  input: DeleteApplicationSettingsInput,
  condition?: ModelApplicationSettingsConditionInput | null,
};

export type DeleteApplicationSettingsMutation = {
  deleteApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateHardwareTemplateMutationVariables = {
  input: CreateHardwareTemplateInput,
  condition?: ModelHardwareTemplateConditionInput | null,
};

export type CreateHardwareTemplateMutation = {
  createHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateHardwareTemplateMutationVariables = {
  input: UpdateHardwareTemplateInput,
  condition?: ModelHardwareTemplateConditionInput | null,
};

export type UpdateHardwareTemplateMutation = {
  updateHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteHardwareTemplateMutationVariables = {
  input: DeleteHardwareTemplateInput,
  condition?: ModelHardwareTemplateConditionInput | null,
};

export type DeleteHardwareTemplateMutation = {
  deleteHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSiteHardwareConfigurationMutationVariables = {
  input: CreateSiteHardwareConfigurationInput,
  condition?: ModelSiteHardwareConfigurationConditionInput | null,
};

export type CreateSiteHardwareConfigurationMutation = {
  createSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSiteHardwareConfigurationMutationVariables = {
  input: UpdateSiteHardwareConfigurationInput,
  condition?: ModelSiteHardwareConfigurationConditionInput | null,
};

export type UpdateSiteHardwareConfigurationMutation = {
  updateSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSiteHardwareConfigurationMutationVariables = {
  input: DeleteSiteHardwareConfigurationInput,
  condition?: ModelSiteHardwareConfigurationConditionInput | null,
};

export type DeleteSiteHardwareConfigurationMutation = {
  deleteSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type RulesQueryVariables = {
  method: string,
  parameters: string,
};

export type RulesQuery = {
  rules?:  {
    status: number,
    message: string,
    data?:  {
      device:  {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      },
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } > | null,
    } | null,
  } | null,
};

export type SesameQueryVariables = {
  method: string,
  parameters: string,
};

export type SesameQuery = {
  sesame?:  {
    status: number,
    message: string,
    data?:  {
      application?:  {
        applicationID: string,
        applicationName?: string | null,
      } | null,
      applicationPermissions?:  Array< {
        applicationPermissionID: string,
        permissionName: string,
        applicationID?: string | null,
        metadata?:  {
          key?: string | null,
          value?: string | null,
        } | null,
        displayName?: string | null,
        description?: string | null,
      } > | null,
      resourcePermissions?:  Array< {
        resource:  {
          resourceType: string,
          resourceID?: string | null,
          resourceName?: string | null,
        },
        permissionIDs?: Array< string > | null,
      } | null > | null,
      expirationTimestamp?: number | null,
      userRegion?: string | null,
      userTimezone?: string | null,
    } | null,
  } | null,
};

export type UnicornPACSRequestStatusQueryVariables = {
  method: string,
  parameters: string,
};

export type UnicornPACSRequestStatusQuery = {
  UnicornPACSRequestStatus?: string | null,
};

export type SNSPublishApproveDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishApproveDocumentQuery = {
  SNSPublishApproveDocument?: string | null,
};

export type SNSPublishCreateDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishCreateDocumentQuery = {
  SNSPublishCreateDocument?: string | null,
};

export type SNSPublishCreateTemplateQueryVariables = {
  templateId: string,
};

export type SNSPublishCreateTemplateQuery = {
  SNSPublishCreateTemplate?: string | null,
};

export type SNSPublishDeleteDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishDeleteDocumentQuery = {
  SNSPublishDeleteDocument?: string | null,
};

export type SNSPublishDeleteTemplateQueryVariables = {
  templateId: string,
};

export type SNSPublishDeleteTemplateQuery = {
  SNSPublishDeleteTemplate?: string | null,
};

export type SNSPublishFailDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishFailDocumentQuery = {
  SNSPublishFailDocument?: string | null,
};

export type SNSPublishSendDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishSendDocumentQuery = {
  SNSPublishSendDocument?: string | null,
};

export type SNSPublishReviewDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishReviewDocumentQuery = {
  SNSPublishReviewDocument?: string | null,
};

export type SNSPublishUpdateDocumentQueryVariables = {
  documentId: string,
};

export type SNSPublishUpdateDocumentQuery = {
  SNSPublishUpdateDocument?: string | null,
};

export type SNSPublishUpdateTemplateQueryVariables = {
  templateId: string,
};

export type SNSPublishUpdateTemplateQuery = {
  SNSPublishUpdateTemplate?: string | null,
};

export type GetUserPreferencesQueryVariables = {
  userId: string,
};

export type GetUserPreferencesQuery = {
  getUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserPreferencesQueryVariables = {
  userId?: string | null,
  filter?: ModelUserPreferencesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserPreferencesQuery = {
  listUserPreferences?:  {
    items:  Array< {
      userId: string,
      preferences: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserActionQueryVariables = {
  userId: string,
  actionType: string,
  actionTime: number,
};

export type GetUserActionQuery = {
  getUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserActionsQueryVariables = {
  userId?: string | null,
  actionTypeActionTime?: ModelUserActionPrimaryCompositeKeyConditionInput | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserActionsQuery = {
  listUserActions?:  {
    items:  Array< {
      userId: string,
      actionType: string,
      actionTime: number,
      parameters?: string | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActionByActionTypeAndActionTimeAndUserIdQueryVariables = {
  actionType: string,
  actionTimeUserId?: ModelUserActionUserActionsByActionTypeAndActionTimeAndUserIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActionByActionTypeAndActionTimeAndUserIdQuery = {
  listUserActionByActionTypeAndActionTimeAndUserId?:  {
    items:  Array< {
      userId: string,
      actionType: string,
      actionTime: number,
      parameters?: string | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUserActionByActionTimeAndActionTypeAndUserIdQueryVariables = {
  actionTime: number,
  actionTypeUserId?: ModelUserActionUserActionsByActionTimeAndActionTypeAndUserIdCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserActionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserActionByActionTimeAndActionTypeAndUserIdQuery = {
  listUserActionByActionTimeAndActionTypeAndUserId?:  {
    items:  Array< {
      userId: string,
      actionType: string,
      actionTime: number,
      parameters?: string | null,
      ttl: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApplicationSettingsQueryVariables = {
  versionId: string,
};

export type GetApplicationSettingsQuery = {
  getApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListApplicationSettingsQueryVariables = {
  versionId?: string | null,
  filter?: ModelApplicationSettingsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListApplicationSettingsQuery = {
  listApplicationSettings?:  {
    items:  Array< {
      versionId: string,
      hardwareTypes?:  Array< {
        type: HardwareType,
        name: string,
        description: string,
        hardwareDevices?:  Array< {
          name: string,
          description: string,
          fields?:  Array< {
            name: string,
            segments?:  Array< {
              name: string,
              sequence: number,
              value: string,
            } | null > | null,
            segmentDelimiter?: string | null,
            uiAttributes?:  Array< {
              name: string,
              value: string,
            } | null > | null,
          } > | null,
          rules?:  Array< {
            description: string,
            id: string,
            operator: RuleOperator,
            not: boolean,
            conditions?:  Array< {
              id: string,
              group: boolean,
              groupParentId?: string | null,
              groupOperator?: RuleConditionGroupOperator | null,
              key: string,
              value: string,
              not: boolean,
              operator?: RuleConditionOperator | null,
            } > | null,
          } > | null,
          slots?:  Array< {
            description: string,
            name: string,
            hardwareType: HardwareType,
            id: string,
            settings?:  Array< {
              key: string,
              value: string,
            } | null > | null,
          } > | null,
        } > | null,
        settings?: string | null,
      } > | null,
      pacs?:  Array< {
        name: string,
        description: string,
        settings?: string | null,
      } > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetHardwareTemplateQueryVariables = {
  id: string,
};

export type GetHardwareTemplateQuery = {
  getHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListHardwareTemplatesQueryVariables = {
  id?: string | null,
  filter?: ModelHardwareTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListHardwareTemplatesQuery = {
  listHardwareTemplates?:  {
    items:  Array< {
      description: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } > | null,
      hardwareType: HardwareType,
      id: string,
      name: string,
      status: Status,
      versionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndNameQueryVariables = {
  hardwareType: HardwareType,
  statusVersionIdName?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndStatusAndVersionIdAndNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHardwareTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndNameQuery = {
  listHardwareTemplateByHardwareTypeAndStatusAndVersionIdAndName?:  {
    items:  Array< {
      description: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } > | null,
      hardwareType: HardwareType,
      id: string,
      name: string,
      status: Status,
      versionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatusQueryVariables = {
  hardwareType: HardwareType,
  nameVersionIdStatus?: ModelHardwareTemplateHardwareTemplatesByHardwareTypeAndNameAndVersionIdAndStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelHardwareTemplateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatusQuery = {
  listHardwareTemplateByHardwareTypeAndNameAndVersionIdAndStatus?:  {
    items:  Array< {
      description: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } > | null,
      hardwareType: HardwareType,
      id: string,
      name: string,
      status: Status,
      versionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSiteHardwareConfigurationQueryVariables = {
  id: string,
};

export type GetSiteHardwareConfigurationQuery = {
  getSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSiteHardwareConfigurationsQueryVariables = {
  id?: string | null,
  filter?: ModelSiteHardwareConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListSiteHardwareConfigurationsQuery = {
  listSiteHardwareConfigurations?:  {
    items:  Array< {
      description: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } > | null,
      id: string,
      name: string,
      status: Status,
      siteCode: string,
      versionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQueryVariables = {
  siteCode: string,
  statusVersionIdName?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndStatusAndVersionIdAndNameCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSiteHardwareConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery = {
  listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName?:  {
    items:  Array< {
      description: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } > | null,
      id: string,
      name: string,
      status: Status,
      siteCode: string,
      versionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQueryVariables = {
  siteCode: string,
  nameVersionIdStatus?: ModelSiteHardwareConfigurationSiteHardwareConfigurationsBySiteCodeAndNameAndVersionIdAndStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSiteHardwareConfigurationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatusQuery = {
  listSiteHardwareConfigurationBySiteCodeAndNameAndVersionIdAndStatus?:  {
    items:  Array< {
      description: string,
      devices?:  Array< {
        id: string,
        name: string,
        hardwareTemplateId?: string | null,
        hardwareTemplateDeviceId?: string | null,
        hardwareType: HardwareType,
        hardwareDeviceName: string,
        address?: number | null,
        settings?:  Array< {
          key: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          value: string,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        parentDeviceId?: string | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        passedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        failedRules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } | null > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } | null > | null,
      } > | null,
      id: string,
      name: string,
      status: Status,
      siteCode: string,
      versionId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserPreferencesSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferencesFilterInput | null,
};

export type OnCreateUserPreferencesSubscription = {
  onCreateUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserPreferencesSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferencesFilterInput | null,
};

export type OnUpdateUserPreferencesSubscription = {
  onUpdateUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserPreferencesSubscriptionVariables = {
  filter?: ModelSubscriptionUserPreferencesFilterInput | null,
};

export type OnDeleteUserPreferencesSubscription = {
  onDeleteUserPreferences?:  {
    userId: string,
    preferences: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserActionSubscriptionVariables = {
  filter?: ModelSubscriptionUserActionFilterInput | null,
};

export type OnCreateUserActionSubscription = {
  onCreateUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserActionSubscriptionVariables = {
  filter?: ModelSubscriptionUserActionFilterInput | null,
};

export type OnUpdateUserActionSubscription = {
  onUpdateUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserActionSubscriptionVariables = {
  filter?: ModelSubscriptionUserActionFilterInput | null,
};

export type OnDeleteUserActionSubscription = {
  onDeleteUserAction?:  {
    userId: string,
    actionType: string,
    actionTime: number,
    parameters?: string | null,
    ttl: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateApplicationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionApplicationSettingsFilterInput | null,
};

export type OnCreateApplicationSettingsSubscription = {
  onCreateApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateApplicationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionApplicationSettingsFilterInput | null,
};

export type OnUpdateApplicationSettingsSubscription = {
  onUpdateApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteApplicationSettingsSubscriptionVariables = {
  filter?: ModelSubscriptionApplicationSettingsFilterInput | null,
};

export type OnDeleteApplicationSettingsSubscription = {
  onDeleteApplicationSettings?:  {
    versionId: string,
    hardwareTypes?:  Array< {
      type: HardwareType,
      name: string,
      description: string,
      hardwareDevices?:  Array< {
        name: string,
        description: string,
        fields?:  Array< {
          name: string,
          segments?:  Array< {
            name: string,
            sequence: number,
            value: string,
          } | null > | null,
          segmentDelimiter?: string | null,
          uiAttributes?:  Array< {
            name: string,
            value: string,
          } | null > | null,
        } > | null,
        rules?:  Array< {
          description: string,
          id: string,
          operator: RuleOperator,
          not: boolean,
          conditions?:  Array< {
            id: string,
            group: boolean,
            groupParentId?: string | null,
            groupOperator?: RuleConditionGroupOperator | null,
            key: string,
            value: string,
            not: boolean,
            operator?: RuleConditionOperator | null,
          } > | null,
        } > | null,
        slots?:  Array< {
          description: string,
          name: string,
          hardwareType: HardwareType,
          id: string,
          settings?:  Array< {
            key: string,
            value: string,
          } | null > | null,
        } > | null,
      } > | null,
      settings?: string | null,
    } > | null,
    pacs?:  Array< {
      name: string,
      description: string,
      settings?: string | null,
    } > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateHardwareTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareTemplateFilterInput | null,
};

export type OnCreateHardwareTemplateSubscription = {
  onCreateHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateHardwareTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareTemplateFilterInput | null,
};

export type OnUpdateHardwareTemplateSubscription = {
  onUpdateHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteHardwareTemplateSubscriptionVariables = {
  filter?: ModelSubscriptionHardwareTemplateFilterInput | null,
};

export type OnDeleteHardwareTemplateSubscription = {
  onDeleteHardwareTemplate?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    hardwareType: HardwareType,
    id: string,
    name: string,
    status: Status,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSiteHardwareConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionSiteHardwareConfigurationFilterInput | null,
};

export type OnCreateSiteHardwareConfigurationSubscription = {
  onCreateSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSiteHardwareConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionSiteHardwareConfigurationFilterInput | null,
};

export type OnUpdateSiteHardwareConfigurationSubscription = {
  onUpdateSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSiteHardwareConfigurationSubscriptionVariables = {
  filter?: ModelSubscriptionSiteHardwareConfigurationFilterInput | null,
};

export type OnDeleteSiteHardwareConfigurationSubscription = {
  onDeleteSiteHardwareConfiguration?:  {
    description: string,
    devices?:  Array< {
      id: string,
      name: string,
      hardwareTemplateId?: string | null,
      hardwareTemplateDeviceId?: string | null,
      hardwareType: HardwareType,
      hardwareDeviceName: string,
      address?: number | null,
      settings?:  Array< {
        key: string,
        segments?:  Array< {
          name: string,
          sequence: number,
          value: string,
        } | null > | null,
        segmentDelimiter?: string | null,
        value: string,
        uiAttributes?:  Array< {
          name: string,
          value: string,
        } | null > | null,
      } > | null,
      parentDeviceId?: string | null,
      rules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      passedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      failedRules?:  Array< {
        description: string,
        id: string,
        operator: RuleOperator,
        not: boolean,
        conditions?:  Array< {
          id: string,
          group: boolean,
          groupParentId?: string | null,
          groupOperator?: RuleConditionGroupOperator | null,
          key: string,
          value: string,
          not: boolean,
          operator?: RuleConditionOperator | null,
        } > | null,
      } | null > | null,
      slots?:  Array< {
        description: string,
        name: string,
        hardwareType: HardwareType,
        id: string,
        settings?:  Array< {
          key: string,
          value: string,
        } | null > | null,
      } | null > | null,
    } > | null,
    id: string,
    name: string,
    status: Status,
    siteCode: string,
    versionId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
