import {
  CreateUserActionInput,
  SesameUserPermissions,
} from 'src/API';
import {
  Link,
  Modal,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  useEffect,
  useState,
} from 'react';
import { AppRouter } from 'src/components/AppRouter';
import { Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { SessionProvider } from 'src/components/common/SessionContext';
import { URLS } from 'src/constants';
import { createUserAction, queryPersonSites } from 'src/components/utils'; // leave here due to Uncaught TypeError: _utils__WEBPACK_IMPORTED_MODULE_16__.auditDecorator is not a function
import { debug } from 'src/utils';
import { jwtDecode } from 'jwt-decode';
import { queryPersonPermissions } from 'src/components/utils';

export let authenticatedUsername: string = 'unknown';

const AMPLIFY_SYMBOL = ((typeof Symbol !== 'undefined' && typeof Symbol.for === 'function') ?
  Symbol.for('amplify_default') : '@@amplify_default') as Symbol;

const dispatchAuthEvent = (event:string, data:any, message:string) => {
  Hub.dispatch('auth', { event, data, message }, 'Auth', AMPLIFY_SYMBOL);
};

export default function AuthenticateUser() {
  debug('AuthenticateUser()');

  document.title = 'SPOT';

  const [authFailed, setAuthFailed] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string>('');
  const [decodedTokenValue, setDecodedTokenValue] = useState<any>();
  const [userId, setUserId] = useState<string>('');
  const [personId, setPersonId] = useState<string>('');
  const [queryingUserSites, setQueryingUserSites] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [userPermissions, setUserPermissions] = useState<SesameUserPermissions | null>(null);
  const [userSites, setUserSites] = useState<string[]>([]);

  Hub.listen('auth', ({ payload: { event, data }  }) => {
    debug(`AuthenticateUser() event is ${event} data is ${JSON.stringify(data)}`);

    switch(event) {
      case 'customOAuthState':
        window.location.replace(data);
        break;
    }
    console.log(event);
    switch (event) {
      case 'signIn':
        console.log(data);
        return;

      case 'signIn_failure':
        console.log('the user failed to sign in');
        console.log('the error is', data);
        return;

      default:
        break;
    }
  });

  useEffect(() => {
    debug('AuthenticateUser() useEffect([])');
    setAuthFailed(false);
    Auth.currentAuthenticatedUser()
      .then(async (cognitoUserData: { username: string; signInUserSession: { idToken: { jwtToken: any; }; }; }) => {
        try {
          const userActionInput: CreateUserActionInput = {
            actionTime: Date.now(),
            actionType: 'login',
            ttl: Math.round((Date.now()+10000)/1000),
            userId: authenticatedUsername,
          };
          await createUserAction(userActionInput);
        } catch(error) {
          console.error('AuthenticateUser Auth.currentAuthenticatedUser', error);
        }
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() cognitoUserData is ${JSON.stringify(cognitoUserData)}`);
        const username = cognitoUserData.username.split('_')[1];
        setUsername(username);
        debug(`AuthenticateUser() useEffect([]) currentAuthenticatedUser() username is ${username}`);
        try {
          const jwtToken = cognitoUserData.signInUserSession.idToken.jwtToken;
          const decodedTokenValue: any = jwtDecode(jwtToken);
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
          setDecodedTokenValue(decodedTokenValue);
          let userId  = decodedTokenValue['custom:employeeId'];
          if (username == 'svcgsospottest1' || username == 'svcgsospottest2') userId = -99;
          setUserId(userId);
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() userId is ${userId}`);
          const personId = decodedTokenValue['custom:personId'];
          setPersonId(personId);
          try {
            setUserPermissions(await queryPersonPermissions(personId));
            setQueryingUserSites(true);
          } catch(error) {
            console.error(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
            throw error;
          }
          const userSites = await queryPersonSites(personId);
          setUserSites(userSites);
          setQueryingUserSites(false);
        } catch(error) {
          debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${error} JSON.stringify: ${JSON.stringify(error)}`);
        }
        dispatchAuthEvent('configured', null, `The Auth category has been configured successfully`);
      })
      .catch((error) => {
        debug(`AuthenticateUser() useEffect([]) currentAuthenticateUser() error is ${JSON.stringify(error)}`);
        setAuthError(error);
        Auth.federatedSignIn({ customProvider: 'AmazonFederate', customState: window.location.href })
          .catch(error => {
            debug(`AuthenticateUser() useEffect([]) federatedSignIn() error is ${JSON.stringify(error)}`);
            setAuthFailed(true);
            setAuthError(error);
          });
        const userActionInput: CreateUserActionInput = {
          actionTime: Date.now(),
          actionType: 'login#error',
          ttl: Math.round((Date.now()+10000)/1000),
          userId: authenticatedUsername,
        };
        createUserAction(userActionInput);
      });
  }, []);

  let componentToRender: JSX.Element;

  if (username && userId && decodedTokenValue) {
    debug(`AuthenticateUser() authentication complete, username is ${username} userId is ${userId} decodedTokenValue is ${JSON.stringify(decodedTokenValue)}`);
    authenticatedUsername = username;
    componentToRender = (
      <SessionProvider>
        <AppRouter
          personId={personId}
          userId={userId}
          username={username}
          userPermissions={userPermissions}
          userSites={userSites}
        />
      </SessionProvider>
    );
  } else if (authFailed) {
    debug(`AuthenticateUser() authFailed`);
    const userActionInput: CreateUserActionInput = {
      actionTime: Date.now(),
      actionType: 'login#failed',
      ttl: Math.round((Date.now()+10000)/1000),
      userId: authenticatedUsername,
    };
    createUserAction(userActionInput);
    componentToRender = (
      <Modal
        header={<>Authentication Failure</>}
        visible
      >
        {authError}
        Failed to authenticate user, please try again or contact <Link external href={URLS.Contact}>SIDE Support</Link>.
      </Modal>
    );
  } else {
    debug(`AuthenticateUser() authenticating`);
    componentToRender = (
      <Modal
        header={<>Initializing <Spinner/></>}
        visible
      >
        <h3>{!personId && 'Authenticating User...'}</h3>
        <h3>{queryingUserSites && 'Authenticating User...'}</h3>
      </Modal>
    );
  }

  return componentToRender;
}
