import {
  Alert,
  Box,
  Button,
  Container,
  Flashbar,
  Header,
  Modal,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  DARK_THEME,
  LIGHT_THEME,
  Plumage,
  PlumageEvents,
  PlumageModel,
} from '@amzn/plumage';
import {
  Device,
  HardwareTemplate,
  HardwareType,
  Rule,
} from 'src/API'
import {
  OpenTemplateActionType,
  useOpenTemplatesContext,
} from './OpenTemplatesContext';
import {
  PlumageStyle,
  QueryKey,
} from '../common/constants';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { TemplateActions } from './TemplateActions';
import { TemplateDeviceRulesTablePanel } from './TemplateDeviceRulesTablePanel';
import { TemplateDevicesTablePanel } from './TemplateDevicesTablePanel';
import { debug } from 'src/utils';
import { transformDocumentDevicesToPlumageModel } from '../utils';
import { updateHardwareTemplate } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useSessionContext } from '../common/SessionContext';

export interface ITemplateProps {
  close: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
}

export function Template(props: ITemplateProps) {
  debug(`Template() props is ${JSON.stringify(props)}`);

  const openTemplatesContext = useOpenTemplatesContext();

  const sessionContext = useSessionContext();

  const queryClient = useQueryClient();

  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [showPlumageModel, setShowPlumageModel] = useState<boolean>(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false);
  const [showTemplateSaved, setShowTemplateSaved] = useState<boolean>(false);
  const [template, setTemplate] = useState<HardwareTemplate | null>(openTemplatesContext.currentTemplate?.temporaryTemplate || null);
  const [templatesaveError, setTemplateSaveError] = useState<string | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Templates.Template');

  const saveTemplateMutation = useMutation({
    mutationFn: updateHardwareTemplate,
    onSettled: (data) => {
      openTemplatesContext.dispatch({ type: OpenTemplateActionType.save, payload: { template: data }});
      queryClient.invalidateQueries({ queryKey: [QueryKey.templates] });
      setShowTemplateSaved(true);
    },
    onError: (error) => {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    },
  });

  const addDevice = async (device: Device) => {
    debug(`Template() addDevice() device is ${JSON.stringify(device)}`);
    if (!device) return;
    try {
      await openTemplatesContext.dispatch({ type: OpenTemplateActionType.addDevice, payload: { device } });
    } catch(error: any) {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const setRule = (rule: Rule) => {
    debug(`Template() setRule() rule is ${JSON.stringify(rule)}`);
    if (!rule) return;
    const newDevice = {...selectedDevice};
    const existingRule = newDevice?.rules?.find(r => r?.id === rule.id);
    debug(`Template() setRule() existingRule is ${JSON.stringify(existingRule)}`);
    if (existingRule?.id) {
      newDevice.rules = [
        ...newDevice.rules!.filter(r => r?.id !== existingRule.id),
        rule,
      ];
    }
    if (!existingRule?.id) {
      if (!newDevice?.rules) newDevice.rules = [];
      debug(`Template() setRule() newDevice is ${JSON.stringify(newDevice)}`);
      newDevice.rules = [
        ...newDevice.rules,
        rule,
      ];
    }
    try {
      openTemplatesContext.dispatch({ type: OpenTemplateActionType.updateDevice, payload: { device: newDevice } });
    } catch(error: any) {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const removeRule = (rule: Rule) => {
    debug(`Template() removeRule() rule is ${JSON.stringify(rule)}`);
    if (!rule) return;
    const newDevice = {...selectedDevice};
    debug(`Template() removeRule() newDevice is ${JSON.stringify(newDevice)}`);
    const existingRule = newDevice?.rules?.find(r => r?.id === rule.id);
    debug(`Template() removeRule() existingRule is ${JSON.stringify(existingRule)}`);
    if (!existingRule?.id) return;
    if (existingRule?.id) {
      newDevice.rules = [
        ...newDevice.rules!.filter(r => r?.id !== existingRule.id),
      ];
    }
    debug(`Template() removeRule() newDevice is ${JSON.stringify(newDevice)}`);
    try {
      openTemplatesContext.dispatch({ type: OpenTemplateActionType.updateDevice, payload: { device: newDevice } });
    } catch(error: any) {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const saveTemplate = async (template: HardwareTemplate) => {
    debug(`Template() saveTemplate() template is ${JSON.stringify(template)}`);
    if (!template) return;
    openTemplatesContext.dispatch({ type: OpenTemplateActionType.saving, payload: { } });
    const parentDevices = template.devices?.filter(d => d.parentDeviceId === null) || [];
    try {
      const saveInput = {
        id: template.id,
        description: template.description,
        devices: template.devices,
        hardwareType: parentDevices.length === 1
          ? parentDevices[0].hardwareType
          : parentDevices.length > 1
            ? HardwareType.Multiple
            : HardwareType.Undefined,
        name: template.name,
        status: template.status,
        versionId: template.versionId,
      };
      await saveTemplateMutation.mutateAsync(saveInput);
    } catch(error: any) {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const updateTemplate = async (updatedTemplate: HardwareTemplate) => {
    debug(`Template() updateTemplate() updatedTemplate is ${JSON.stringify(updatedTemplate)}`);
    if (!updatedTemplate) return;
    try {
      openTemplatesContext.dispatch({ type: OpenTemplateActionType.update, payload: { template: updatedTemplate } });
      debug(`Template() updateTemplate() called dispatch`);
    } catch(error: any) {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const undoTemplate = async () => {
    debug(`Template() undoTemplate() template is ${JSON.stringify(template)}`);
    if (!template) return;
    try {
      openTemplatesContext.dispatch({ type: OpenTemplateActionType.undo, payload: { template } });
    } catch(error: any) {
      setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const closeTemplate = () => {
    const templateChanged = openTemplatesContext.currentTemplate?.changed;
    if (templateChanged) {
      setShowCloseConfirmation(true);
    }
    if (!templateChanged) props.close(template);
  };

  const plumageModel: PlumageModel = useMemo(
    () => transformDocumentDevicesToPlumageModel(openTemplatesContext.currentTemplate.temporaryTemplate.devices),
    [openTemplatesContext.currentTemplate.temporaryTemplate.devices]
  );

  const events: PlumageEvents = {
    onSelectionChanged: (event) => {
      debug(`Template() events.onSelectionChanged() event is ${JSON.stringify(event)}`)
    },
  };

  useEffect(() => {
    debug(`Template() useEffect()[openTemplatesContext.currentTemplate.temporaryTemplate]`);
    debug(`Template() useEffect()[openTemplatesContext.currentTemplate.temporaryTemplate] openTemplatesContext.currentTemplate?.temporaryTemplate is ${JSON.stringify(openTemplatesContext.currentTemplate?.temporaryTemplate)}`);
    setTemplate(openTemplatesContext.currentTemplate?.temporaryTemplate || null);
    if (openTemplatesContext.currentTemplate?.temporaryTemplate) {
      setSelectedDevice(openTemplatesContext.currentTemplate.temporaryTemplate.devices?.find(d => d.id === selectedDevice?.id));
    }
  }, [openTemplatesContext.currentTemplate?.temporaryTemplate]);

  useEffect(() => {
    if (openTemplatesContext.currentDeviceId) {
      const device = openTemplatesContext.currentTemplate?.temporaryTemplate?.devices?.find(d => d.id === openTemplatesContext.currentDeviceId);
      setSelectedDevice(device);
      return;
    }
    setSelectedDevice(undefined);
  }, [openTemplatesContext.currentDeviceId]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      {showTemplateSaved
      &&
      <Flashbar
        items={
          [
            {
              content: bundle.getMessage('template-saved'),
              dismissible: true,
              onDismiss: () => setShowTemplateSaved(false),
              type: 'success',
            },
          ]}
      />}
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowCloseConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                iconName='check'
                onClick={() => props.close()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowCloseConfirmation(false)}
        visible={showCloseConfirmation}
      >
        {`${bundle.getMessage('template-close-confirm')}`}
      </Modal>
      <Container
        header={
          templatesaveError
          &&
          <Alert
            dismissible
            onDismiss={() => setTemplateSaveError(null)}
            type='error'
          >
            {templatesaveError}
          </Alert>
        }
      >
        {template
        &&
        <SpaceBetween direction='vertical' size='s'>
          <TemplateActions
            addDevice={addDevice}
            close={closeTemplate}
            saveTemplate={saveTemplate}
            selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
            setSelectedApplicationSettingsVersion={props.setSelectedApplicationSettingsVersion}
            showPlumageModel={() => setShowPlumageModel(true)}
            updateTemplate={updateTemplate}
            undoTemplate={undoTemplate}
          />
          <TemplateDevicesTablePanel
            addDownstream={addDevice}
          />
          {showPlumageModel
          &&
          <Modal
            onDismiss={() => setShowPlumageModel(false)}
            size='max'
            visible={showPlumageModel}
          >
            <Plumage
              events={events}
              options={{
                focusOnSelect: true,
                isUsingCFNIcons: true,
                viewportControls: {
                  showFullscreen: false,
                  showZoom: false,
                  showZoomToFit: false,
                },
              }}
              plumageModel={plumageModel}
              style={PlumageStyle}
              theme={
                JSON.parse(sessionContext.userPreferences.preferences)?.darkMode
                  ? DARK_THEME
                  : LIGHT_THEME
              }
            />
          </Modal>}
          {selectedDevice
          &&
          <TemplateDeviceRulesTablePanel
            setRule={setRule}
            deviceForDeviceRules={openTemplatesContext.currentTemplate?.temporaryTemplate?.devices?.find(d => d.id === selectedDevice.id)}
            removeRule={removeRule}
          />}
        </SpaceBetween>}
      </Container>
    </>);
}
