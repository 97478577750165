import * as uuid from 'uuid';
import {
  Button,
  Form,
  FormField,
  Grid,
  SpaceBetween,
  Spinner,
  Textarea,
} from '@amzn/awsui-components-react';
import {
  Device,
  Rule,
  RuleCondition,
  RuleOperator,
} from 'src/API';
import {
  useEffect,
  useState,
} from 'react';
import { TemplateDeviceRuleConditionsTablePanel } from './TemplateDeviceRuleConditionsTablePanel';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';

interface ITemplateDeviceRuleProps {
  cancel: Function;
  device: Device;
  rule: Rule | undefined;
  setRule: Function;
}

export function TemplateDeviceRule(props: ITemplateDeviceRuleProps) {
  debug(`TemplateDeviceRule() props is ${JSON.stringify(props)}`);

  const DEFAULT_RULE = {
    conditions: [],
    description: '',
    id: uuid.v4(),
    not: false,
    operator: RuleOperator.AND,
  }

  const [rule, setRule] = useState<Rule>(props.rule || DEFAULT_RULE);

  const [bundle, isBundleLoading] = useBundle('components.Templates.TemplateDeviceRule');

  const saveRule = () => {
    setRule(DEFAULT_RULE);
    props.setRule(rule);
  };

  const addRuleCondition = (condition: RuleCondition) => {
    const conditions = rule.conditions?.filter(c => c.id !== condition.id) || [];
    conditions.push(condition);
    setRule({
      ...rule,
      conditions,
    });
  };

  const removedChildRuleConditions = (
    ruleConditions: RuleCondition[],
    parentRuleConditionId: string,
    removedConditions: RuleCondition[],
  ) => {
    debug(`TemplateDeviceRule() removeChildRuleConditions() ruleConditions is ${JSON.stringify(ruleConditions)} parentRuleConditionId is ${parentRuleConditionId}`);
    ruleConditions.filter(rc => {
      if (!rc.group && rc.groupParentId === parentRuleConditionId) {
        // add group non group child to removal set
        removedConditions.push(rc);
      } else if (rc.group) {
        if (rc.groupParentId !== parentRuleConditionId && rc.id !== parentRuleConditionId) return true;
        const groupConditions = rule.conditions?.filter(c => c.groupParentId === parentRuleConditionId);
        // add child group and its children to removal set
        removedChildRuleConditions(groupConditions || [], rc.id, removedConditions);
        removedConditions.push(rc);
      } else {
        // leave non related
        return false;
      }
    });
    debug(`TemplateDeviceRule() removeChildRuleConditions() removedConditions is ${JSON.stringify(removedConditions)}`);
  }

  const removeRuleCondition = (removeRuleCondition: RuleCondition) => {
    debug(`TemplateDeviceRule() removeRuleCondition() removeRuleCondition is ${JSON.stringify(removeRuleCondition)}`);
    if (!removeRuleCondition) return;
    if (!rule.conditions || rule.conditions.length === 0) return;
    const ruleConditions = rule.conditions.filter(c => c.id === removeRuleCondition.id);
    const removedConditions: RuleCondition[] = [];
    if (!removeRuleCondition.group) {
      removedConditions.push(removeRuleCondition);
    }
    if (removeRuleCondition.group) {
      removedChildRuleConditions(ruleConditions, removeRuleCondition.id, removedConditions);
    }
    const newConditions = rule.conditions.filter(c => removedConditions.find(rc => c.id === rc.id) === undefined);
    setRule({
      ...rule,
      conditions: newConditions,
    });
  };

  const setConditions = (conditions: RuleCondition[]) => {
    setRule({
      ...rule,
      conditions,
    });
  };

  const cancel = () => {
    setRule(DEFAULT_RULE);
    props.cancel();
  };

  useEffect(() => {
    if (props.rule) setRule(props.rule);
  }, [props.rule]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Form
      actions={
        <SpaceBetween direction='horizontal' size='s'>
          <Button
            formAction='none'
            onClick={cancel}
            variant='normal'
          >
            {bundle.getMessage('cancel')}
          </Button>
          <Button
            disabled={!rule.description || !rule.operator || rule?.conditions?.length === 0}
            disabledReason={bundle.getMessage('description-and-at-least-one-condition-are-required')}
            onClick={saveRule}
            variant='primary'
          >
            {bundle.getMessage('save')}
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction='vertical' size='l'>
        <Grid gridDefinition={[{colspan: 6}, {colspan: 6}]}>
          <FormField
            label={bundle.getMessage('description')}
            stretch
          >
            <Textarea
              autoFocus
              onChange={( { detail }) => setRule({...rule, description: detail.value})}
              placeholder={bundle.getMessage('enter-rule-description')}
              rows={1}
              value={rule.description}
            />
          </FormField>
          <FormField label='&zwnj;'>
            <SpaceBetween direction='horizontal' size='xxxs'>
              <Button
                onClick={() => setRule(
                  {
                    ...rule,
                    not: !rule.not,
                  })}
                variant={rule.not ? 'primary' : 'normal' }
              >
                NOT
              </Button>
              <Button
                onClick={() => setRule({...rule, operator: RuleOperator.AND})}
                variant={rule.operator === RuleOperator.AND ? 'primary' : 'normal' }
              >
                {RuleOperator.AND}
              </Button>
              <Button
                onClick={() => setRule({...rule, operator: RuleOperator.OR})}
                variant={rule.operator === RuleOperator.OR ? 'primary' : 'normal' }
              >
                {RuleOperator.OR}
              </Button>
            </SpaceBetween>
          </FormField>
        </Grid>
        <TemplateDeviceRuleConditionsTablePanel
          addRuleCondition={addRuleCondition}
          device={props.device}
          removeRuleCondition={removeRuleCondition}
          rule={rule}
          setConditions={setConditions}
        />
      </SpaceBetween>
    </Form>);
}
