import {
  Alert,
  Modal,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  CreateSiteHardwareConfigurationInput,
  SiteHardwareConfiguration,
  Status,
} from 'src/API';
import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from './OpenDocumentsContext';
import {
  UseQueryResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  useEffect,
  useState,
} from 'react';
import { CreateNewDocument } from './CreateNewDocument';
import { CreateNewDocumentRevision } from './CreateNewDocumentRevision';
import { Document } from './Document';
import { DocumentsTablePanel } from './DocumentsTablePanel';
import { QueryKey } from '../common/constants';
import { createSiteHardwareConfiguration } from './utils';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useSessionContext } from '../common/SessionContext';

interface IDocumentsProps {
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
  documentsQuery: UseQueryResult<SiteHardwareConfiguration[]>;
}

export default function Documents(props: IDocumentsProps) {
  debug(`Documents() props is ${JSON.stringify(props)}`);

  const openDocumentsContext = useOpenDocumentsContext();
  const sessionContext = useSessionContext();

  const [creatingNewDocument, setCreatingNewDocument] = useState<boolean>(false);
  const [creatingNewDocumentRevision, setCreatingNewDocumentRevision] = useState<boolean>(false);
  const [documentSaveError, setDocumentSaveError] = useState<string | null>(null);
  const [showCreateNewDocument, setShowCreateNewDocument] = useState<boolean>(false);
  const [showCreateNewDocumentRevision, setShowCreateNewDocumentRevision] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<SiteHardwareConfiguration | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Documents.index');

  const queryClient = useQueryClient();

  const createSiteHardwareConfigurationMutation = useMutation(
    {
      mutationFn: createSiteHardwareConfiguration,
      onSuccess: (data) => {
        queryClient.fetchQuery({queryKey: [QueryKey.documents]});
        setSelectedDocument(data);
        setShowCreateNewDocument(false);
      },
      onError: (error: any) => {
        setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      },
    }
  );

  const createNewDocument = async (newDocumentProps: {name: string, description: string, versionId: string}) => {
    setCreatingNewDocument(true);
    setSelectedDocument(null);
    try {
      const createDocumentInput: CreateSiteHardwareConfigurationInput = {
        name: newDocumentProps.name,
        versionId: newDocumentProps.versionId,
        description: newDocumentProps.description,
        status: Status.Development,
        siteCode: sessionContext.siteCode,
        devices: [],
      };
      await createSiteHardwareConfigurationMutation.mutateAsync(createDocumentInput);
      setShowCreateNewDocument(false);
    } catch(error) {
      console.error(error);
      setShowCreateNewDocument(false);
      setDocumentSaveError(JSON.stringify(error));
    }
    setCreatingNewDocument(false);
  };

  const createNewDocumentRevision = async (versionId: string) => {
    setCreatingNewDocumentRevision(true);
    if (!selectedDocument) return;
    try {
      const newDocument: CreateSiteHardwareConfigurationInput = {
        name: selectedDocument.name,
        versionId: versionId,
        description: selectedDocument.description,
        status: Status.Development,
        siteCode: sessionContext.siteCode,
        devices: selectedDocument.devices,
      };
      await createSiteHardwareConfigurationMutation.mutateAsync(newDocument);
      setShowCreateNewDocumentRevision(false);
    } catch(error) {
      console.error(error);
      setShowCreateNewDocumentRevision(false);
      setDocumentSaveError(JSON.stringify(error));
    }
    setCreatingNewDocumentRevision(false);
  };

  const editDocument = () => {
    openDocumentsContext?.dispatch({ type: OpenDocumentActionType.open, payload: { document: selectedDocument } });
  };

  const closeDocument = (document: SiteHardwareConfiguration) => {
    openDocumentsContext.dispatch({ type: OpenDocumentActionType.close, payload: { document } });
  };

  useEffect(() => {
    if (openDocumentsContext.currentDocument?.id) {
      setSelectedDocument({...openDocumentsContext.currentDocument.savedDocument});
    }
  }, [openDocumentsContext.currentDocument]);

  useEffect(() => {
    if (openDocumentsContext.currentDocument
      && openDocumentsContext.currentDocument?.id === selectedDocument?.id)
    {
      setSelectedDocument({...openDocumentsContext.currentDocument.savedDocument});
    }
  }, [openDocumentsContext]);

  if (isBundleLoading) return <Spinner/>;

  if (sessionContext.siteCode === '') {
    return (
      <Alert
        type='info'
      >
        {bundle.getMessage('select-a-site')}
      </Alert>
    );
  }

  if (openDocumentsContext.currentDocument) {
    return(
      <Document
        close={closeDocument}
        selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
        setSelectedApplicationSettingsVersion={props.setSelectedApplicationSettingsVersion}
      />);
  }

  return(
    <>
      {documentSaveError
      &&
      <Alert
        onDismiss={() => setDocumentSaveError(null)}
        type='error'
      >
        {documentSaveError}
      </Alert>}
      <Modal
        header={<h3>{bundle.getMessage('create')}</h3>}
        onDismiss={() => setShowCreateNewDocument(false)}  
        size='small'
        visible={showCreateNewDocument}
      >
        <CreateNewDocument
          cancel={() => setShowCreateNewDocument(false)}
          createDocument={createNewDocument}
          creatingDocument={creatingNewDocument}
        />
      </Modal>
      <Modal
        header={<h3>{bundle.getMessage('create')}</h3>}
        onDismiss={() => setShowCreateNewDocumentRevision(false)}  
        size='small'
        visible={showCreateNewDocumentRevision}
      >
        <CreateNewDocumentRevision
          cancel={() => setShowCreateNewDocumentRevision(false)}
          createDocumentRevision={createNewDocumentRevision}
          creatingDocumentRevision={creatingNewDocumentRevision}
        />
      </Modal>
      <DocumentsTablePanel
        closeDocument={closeDocument}
        createNewDocument={() => setShowCreateNewDocument(true)}
        createNewDocumentRevision={() => setShowCreateNewDocumentRevision(true)}
        editDocument={editDocument}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        documentsQuery={props.documentsQuery}
      />
    </>);
}
