import {
  Alert,
  Box,
  Button,
  Container,
  Flashbar,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  DARK_THEME,
  LIGHT_THEME,
  Plumage,
  PlumageEvents,
  PlumageModel,
} from '@amzn/plumage';
import {
  OpenReviewActionType,
  useOpenReviewsContext,
} from './OpenReviewsContext';
import {
  PlumageStyle,
  QueryKey,
} from '../common/constants';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ReviewActions } from './ReviewActions';
import { ReviewDevicesTablePanel } from './ReviewDevicesTablePanel';
import { SiteHardwareConfiguration } from 'src/API';
import { debug } from 'src/utils';
import { transformDocumentDevicesToPlumageModel } from '../utils';
import { updateSiteHardwareConfiguration } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useSessionContext } from '../common/SessionContext';

export interface IReviewProps {
  close: Function;
}

export function Review(props: IReviewProps) {
  debug(`Review() props is ${JSON.stringify(props)}`);

  const openReviewsContext = useOpenReviewsContext();

  const sessionContext = useSessionContext();

  const queryClient = useQueryClient();

  const [document, setReview] = useState<SiteHardwareConfiguration | null>(openReviewsContext.currentReview?.temporaryReview || null);
  const [documentSaveError, setReviewSaveError] = useState<string | null>(null);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false);
  const [showPlumageModel, setShowPlumageModel] = useState<boolean>(false);
  const [showReviewSaved, setShowReviewSaved] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Reviews.Review');

  const saveReviewMutation = useMutation({
    mutationFn: updateSiteHardwareConfiguration,
    onSettled: (data) => {
      openReviewsContext.dispatch({ type: OpenReviewActionType.save, payload: { review: data }});
      queryClient.invalidateQueries({ queryKey: [QueryKey.reviews] });
      setShowReviewSaved(true);
    },
    onError: (error) => {
      setReviewSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    },
  });

  const saveReview = async (document: SiteHardwareConfiguration) => {
    if (!document) return;
    openReviewsContext.dispatch({ type: OpenReviewActionType.saving, payload: { } });
    try {
      const saveInput = {
        id: document.id,
        description: document.description,
        devices: document.devices,
        name: document.name,
        status: document.status,
        versionId: document.versionId,
      };
      await saveReviewMutation.mutateAsync(saveInput);
    } catch(error: any) {
      setReviewSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const updateReview = async (updatedReview: SiteHardwareConfiguration) => {
    if (!updatedReview) return;
    try {
      openReviewsContext.dispatch({ type: OpenReviewActionType.update, payload: { review: updatedReview } });
    } catch(error: any) {
      setReviewSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const undoReview = async () => {
    if (!document) return;
    try {
      openReviewsContext.dispatch({ type: OpenReviewActionType.undo, payload: { } });
    } catch(error: any) {
      setReviewSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const closeReview = () => {
    const documentChanged = openReviewsContext.currentReview?.changed;
    if (documentChanged) {
      setShowCloseConfirmation(true);
    }
    if (!documentChanged) props.close(document);
  };

  const plumageModel: PlumageModel = useMemo(
    () => transformDocumentDevicesToPlumageModel(openReviewsContext.currentReview.temporaryReview.devices),
    [openReviewsContext.currentReview.temporaryReview.devices]
  );

  const events: PlumageEvents = {
    onSelectionChanged: (event) => {
      debug(`Review() events.onSelectionChanged() event is ${JSON.stringify(event)}`)
    },
  };

  useEffect(() => {
    setReview(openReviewsContext.currentReview?.temporaryReview || null);
  }, [openReviewsContext.currentReview?.temporaryReview]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      {showReviewSaved
      &&
      <Flashbar
        items={
          [
            {
              content: bundle.getMessage('document-saved'),
              dismissible: true,
              onDismiss: () => setShowReviewSaved(false),
              type: 'success',
            },
          ]}
      />}
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowCloseConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                iconName='check'
                onClick={() => props.close()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowCloseConfirmation(false)}
        visible={showCloseConfirmation}
      >
        {`${bundle.getMessage('document-close-confirm')}`}
      </Modal>
      <Container
        header={
          documentSaveError
          &&
          <Alert
            dismissible
            onDismiss={() => setReviewSaveError(null)}
            type='error'
          >
            {documentSaveError}
          </Alert>
        }
      >
        {document
        &&
        <SpaceBetween direction='vertical' size='s'>
          <ReviewActions
            close={closeReview}
            saveReview={saveReview}
            showPlumageModel={() => setShowPlumageModel(true)}
            updateReview={updateReview}
            undoReview={undoReview}
          />
          <ReviewDevicesTablePanel />
          {showPlumageModel
          &&
          <Modal
            onDismiss={() => setShowPlumageModel(false)}
            size='max'
            visible={showPlumageModel}
          >
            <Plumage
              events={events}
              options={{
                focusOnSelect: true,
                isUsingCFNIcons: true,
                viewportControls: {
                  showFullscreen: false,
                  showZoom: false,
                  showZoomToFit: false,
                },
              }}
              plumageModel={plumageModel}
              style={PlumageStyle}
              theme={
                JSON.parse(sessionContext.userPreferences.preferences)?.darkMode
                  ? DARK_THEME
                  : LIGHT_THEME
              }
            />
          </Modal>}
        </SpaceBetween>}
      </Container>
    </>);
}
