import { HOW_MANY_TRACKER } from 'src/constants';

export default function Tracker(props: {username: string}) {
  const hostname = window.location.hostname;

  let trackerURL = '';

  switch (hostname) {
    case 'beta.spot.gso.amazon.dev':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_BETA;
      break;
    case 'gamma.spot.gso.amazon.dev':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_GAMMA;
      break;
    case 'spot.gso.amazon.dev':
      trackerURL = HOW_MANY_TRACKER.WebsiteAccess_URL_PROD;
      break;
  }

  const how_many_tracker_url = `${trackerURL}&login=${props.username}`;

  return (
    <img src={how_many_tracker_url} />
  )
}