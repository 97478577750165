import {
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useState } from 'react';

interface ICreateTemplateProps {
  cancel: Function;
  createTemplate: Function;
  creatingTemplate: boolean;
}

export function CreateTemplate(props: ICreateTemplateProps) {
  debug(`CreateTemplate() props is ${JSON.stringify(props)}`);

  const [name, setName] = useState<string>('');
  const [versionId, setVersionId] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [bundle, isBundleLoading] = useBundle('components.Templates.CreateTemplate');

  const createTemplate = () => {
    setName('');
    setDescription('');
    setVersionId('');
    props.createTemplate({ name, versionId, description });
  };

  const cancelCreateTemplate = () => {
    setName('');
    setDescription('');
    setVersionId('');
    props.cancel();
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <form onSubmit={event => event.preventDefault()}>
      <Form
          actions={
            <SpaceBetween direction='horizontal' size='s'>
              <Button
                formAction='none'
                onClick={() => cancelCreateTemplate()}
                variant='normal'
              >
                {bundle.getMessage('cancel')}
              </Button>
              <Button
                disabled={!name || !versionId || !description}
                loading={props.creatingTemplate}
                onClick={() => createTemplate()}
                variant='primary'
              >
                {bundle.getMessage('save')}
              </Button>
            </SpaceBetween>
          }
        >
        <SpaceBetween direction='horizontal' size='s'>
          <FormField label={bundle.getMessage('name')} >
            <Input
              autoFocus
              onChange={( { detail }) => setName(detail.value)}
              value={name}
            />
          </FormField>
          <FormField label={bundle.getMessage('description')} >
            <Input
              onChange={( { detail }) => setDescription(detail.value)}
              value={description}
            />
          </FormField>
          <FormField label={bundle.getMessage('version')} >
            <Input
              onChange={( { detail }) => setVersionId(detail.value)}
              value={versionId}
            />
          </FormField>
        </SpaceBetween>
      </Form>
    </form>);
}
