import {
  Alert,
  AppLayout,
  HelpPanel,
  Modal,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  CreateUserPreferencesInput,
  Status,
  UpdateUserPreferencesInput,
} from 'src/API';
import {
  DEFAULT_USER_PREFERENCES,
  Page,
} from './common/constants';
import {
  SessionActionType,
  useSessionContext,
} from './common/SessionContext';
import {
  createUserPreferences,
  updateUserPreferences,
} from './utils';
import {
  useBundle,
  useLocalizationContext,
} from '@amzn/react-arb-tools';
import {
  useEffect,
  useState,
} from 'react';
import { DEFAULT_LC_BUILDER } from 'src/constants';
import DocumentDeviceSettings from './Documents/DocumentDeviceSettings';
import Documents from './Documents';
import { ErrorBoundary } from 'react-error-boundary';
import FallbackError from './common/FallbackError';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ReviewDeviceSettings from './Reviews/ReviewDeviceSettings';
import Reviews from './Reviews';
import SelectSite from './common/SelectSite';
import SideNav from './common/SideNav';
import TemplateDeviceSettings from './Templates/TemplateDeviceSettings';
import Templates from './Templates';
import TopNav from './common/TopNav';
import Tracker from './common/Tracker';
import { debug } from 'src/utils';
import i18n from 'src/i18n';
import { useAllApplicationSettings } from './common/hooks/useAllApplicationSettings';
import { useDocuments } from './common/hooks/useDocuments';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useReviews } from './common/hooks/useReviews';
import { useSelectedApplicationSettings } from './common/hooks/useSelectedApplicationSettings';
import { useTemplates } from './common/hooks/useTemplates';
import { useUserPreferences } from './common/hooks/useUserPreferences';

export let debugLogging: boolean = false;

export interface IAppProps {}

export default function App(props: IAppProps) {
  debug(`App() props is ${JSON.stringify(props)}`);

  const { siteCodeUrlParam, documentIdUrlParam } = useParams();

  const sessionContext = useSessionContext();

  debugLogging = sessionContext.enableDebugLogging;

  debug(`App() siteCodeUrlParam is ${siteCodeUrlParam}`);
  debug(`App() documentIdUrlParam is ${documentIdUrlParam}`);

  const userPreferencesQuery = useUserPreferences(sessionContext.userId);

  const allApplicationSettingsQuery = useAllApplicationSettings();

  const latestApplicationSettingsVersion = (): SelectProps.Option | undefined => {
    debug(`App() latestApplicationSettings() allApplicationSettingsQuery.data is ${JSON.stringify(allApplicationSettingsQuery.data)}`);
    const allApplicationSettings = allApplicationSettingsQuery.data;
    if (allApplicationSettings === undefined || allApplicationSettings.length === 0) return;
    const latestApplicationSettingsVersion = allApplicationSettings?.reduce((prev: any, current: any) => {
      return (prev.versionId > current.versionId) ? prev : current;
    });
    const latestApplicationSettingsOption: SelectProps.Option = {
      label: latestApplicationSettingsVersion?.versionId,
      value: latestApplicationSettingsVersion?.versionId,
    };
    debug(`App() latestApplicationSettings() latestApplicationSettingsOption is ${JSON.stringify(latestApplicationSettingsOption)}`);
    if (!latestApplicationSettingsOption) throw new Error('latestApplicationSettingsOption is undefined');
    return(latestApplicationSettingsOption);
  };

  const [initialPage, setInitialPage] = useState<Page | null>(null);
  const [navigationOpen, setNavigationOpen] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<Page>(Page.Documents);
  const [selectedApplicationSettingsVersion, setSelectedApplicationSettingsVersion] =
    useState<SelectProps.Option | undefined>(latestApplicationSettingsVersion());
  const [selectedSiteCode, setSelectSiteCode] = useState<string>(siteCodeUrlParam || '');
  const [showSelectSite, setShowSelectSite] = useState<boolean>(false);
  const [showTools, setShowTools] = useState<boolean>(false);

  const templatesQuery = useTemplates();
  const documentsQuery = useDocuments(selectedSiteCode, '');
  const reviewsQuery = useReviews(selectedSiteCode, Status.Review);

  const selectedApplicationSettingsQuery = useSelectedApplicationSettings(selectedApplicationSettingsVersion?.value!);

  const { setLocalizationContext } = useLocalizationContext();

  const [bundle, isBundleLoading] = useBundle('components.App');

  const saveUserPrefsMutation = useMutation({
    mutationFn: async (input: CreateUserPreferencesInput) => {
      await createUserPreferences(input);
      await userPreferencesQuery.refetch();
    },
  });

  const setSelectedSite = async (newSelectedSiteCode: string) => {
    if (selectedSiteCode === newSelectedSiteCode) return;
    setSelectSiteCode(newSelectedSiteCode);
    if (!userPreferencesQuery?.data?.preferences) {
      const createUserPreferencesInput: CreateUserPreferencesInput = {
        preferences: JSON.stringify({
          ...DEFAULT_USER_PREFERENCES,
          lastSiteCode: newSelectedSiteCode,
        }),
        userId: sessionContext.userId,
      };
      await saveUserPrefsMutation.mutateAsync(createUserPreferencesInput);
    } else {
      const userPreferences = JSON.parse(userPreferencesQuery?.data?.preferences);
      userPreferences.lastSiteCode = newSelectedSiteCode;
      const updateUserPreferencesInput: UpdateUserPreferencesInput = {
        userId: sessionContext.userId,
        preferences: JSON.stringify(userPreferences),
      };
      await updateUserPreferences(updateUserPreferencesInput);
    }
    setShowSelectSite(false);
  };

  useEffect(() => {
    const changeLanguage = async (language: string) => {
      try {
        await i18n.changeLanguage(language);
        setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(language).build());
      } catch(error) {
        console.error(error);
        setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(DEFAULT_USER_PREFERENCES.language.value).build());
      }
    };
    try {
      let userPreferences: any = null;
      if (userPreferencesQuery?.data?.preferences) userPreferences = JSON.parse(userPreferencesQuery.data.preferences);
      if (userPreferences?.darkMode === true) {
        document.body.classList.add('awsui-polaris-dark-mode');
      } else {
        document.body.classList.remove('awsui-polaris-dark-mode');
      }
      if (userPreferences?.visualRefresh === true) {
        document.body.classList.add('awsui-visual-refresh');
      } else {
        document.body.classList.remove('awsui-visual-refresh');
      }
      setLocalizationContext(DEFAULT_LC_BUILDER.withLocale(DEFAULT_USER_PREFERENCES.language.value).build());
      if (userPreferences?.language?.value !== undefined) {
        changeLanguage(userPreferences.language.value);
      }
      if (typeof userPreferences?.lastSiteCode === 'string'
        && sessionContext.userPermissions?.resourcePermissions?.find(
          rp => rp?.resource.resourceType === 'SITE' && rp?.resource.resourceName === userPreferences?.lastSiteCode))
      {
        setSelectSiteCode(userPreferences.lastSiteCode);
      }
      if (typeof userPreferences?.initialPage === 'string') {
        if (initialPage === null) {
          setInitialPage(userPreferences.initialPage);
        }
      }
    } catch(error) {
      console.error(error);
    }
  }, [userPreferencesQuery.data]);

  useEffect(() => {
    try {
      if (!sessionContext?.userPreferences?.preferences || sessionContext?.userPreferences?.preferences === '') return;
      const userPreferences = JSON.parse(sessionContext?.userPreferences?.preferences);
      if (typeof userPreferences?.lastSiteCode === 'string' && sessionContext.userSites.find(s => s === userPreferences?.lastSiteCode)) {
        setSelectSiteCode(userPreferences.lastSiteCode);
      }
    } catch(error) {
      console.error(error);
    }
  }, [sessionContext.userSites]);

  useEffect(() => {
    selectedApplicationSettingsQuery.refetch();
  }, [selectedApplicationSettingsVersion]);

  useEffect(() => {
    if (allApplicationSettingsQuery.error) throw allApplicationSettingsQuery.error;
    if (!selectedApplicationSettingsVersion) setSelectedApplicationSettingsVersion(latestApplicationSettingsVersion());
  }, [allApplicationSettingsQuery.data]);

  useEffect(() => {
    if (siteCodeUrlParam && siteCodeUrlParam !== '' && siteCodeUrlParam !== selectedSiteCode) {
      setSelectSiteCode(siteCodeUrlParam);
    }
  }, [siteCodeUrlParam]);

  useEffect(() => {
    sessionContext.dispatch({ type: SessionActionType.setSessionSiteCode, payload: { siteCode: selectedSiteCode } })
    if (selectedSiteCode != '') documentsQuery.refetch();
    if (selectedSiteCode != '') reviewsQuery.refetch();
  }, [selectedSiteCode]);

  if (isBundleLoading || !selectedApplicationSettingsVersion) {
    return(
      <Modal
        header={<>Initializing <Spinner/></>}
        visible
      >  
        <ErrorBoundary FallbackComponent={FallbackError}>
          {isBundleLoading ? <h3>Loading Language...</h3> : <></>}
          {!selectedApplicationSettingsVersion ? <h3>Loading Application Settings...</h3> : <></>}
        </ErrorBoundary>
      </Modal>);
  }

  return (
    <>
      {showSelectSite
      &&
      <SelectSite
        selectedSiteCode={selectedSiteCode}
        setSelectedSite={setSelectedSite}
        setSiteSelectVisible={setShowSelectSite}
        personId={sessionContext.personId}
      />}
      <div
        className='awsui'
        id='topNavigation'
        style={{position: 'sticky', top: 0, zIndex: 1002}}
      >
        <TopNav
          setShowSelectSite={setShowSelectSite}
        />
      </div>
      <AppLayout
        ariaLabels={{ navigationClose: 'close' }}
        content={
            (
             currentPage === Page.Documents
              &&
              (
                (
                  sessionContext.configuration
                  &&
                  <ErrorBoundary FallbackComponent={FallbackError}>
                    <Documents
                      documentsQuery={documentsQuery}
                      selectedApplicationSettingsVersion={selectedApplicationSettingsVersion!}
                      setSelectedApplicationSettingsVersion={setSelectedApplicationSettingsVersion}
                    />
                  </ErrorBoundary>
                )
                || (sessionContext.configuration === null && <Spinner/>)
                || <Alert type='info'>{bundle.getMessage('not-permitted')}</Alert>
              )
            ) 
            || 
            (
              currentPage === Page.Reviews
              &&
              (
                (
                  sessionContext.review
                  &&
                  <ErrorBoundary FallbackComponent={FallbackError}>
                    <Reviews
                      reviewsQuery={reviewsQuery}
                    />
                  </ErrorBoundary>
                )
                || (sessionContext.review === null && <Spinner/>)
                || <Alert type='info'>{bundle.getMessage('not-permitted')}</Alert>
              )
            )
            || 
            (
              currentPage === Page.Templates
              &&
              (
                (
                  sessionContext.template
                  &&
                  <ErrorBoundary FallbackComponent={FallbackError}>
                    <Templates
                      selectedApplicationSettingsVersion={selectedApplicationSettingsVersion!}
                      setSelectedApplicationSettingsVersion={setSelectedApplicationSettingsVersion}
                      templatesQuery={templatesQuery}
                    />
                  </ErrorBoundary>
                )
                || (sessionContext.template === null && <Spinner/>)
                || <Alert type='info'>{bundle.getMessage('not-permitted')}</Alert>
              )
            )
        }
        contentType='table'
        disableContentPaddings
        headerSelector='#topNavigation'
        navigation={
          <SideNav
            setCurrentPage={setCurrentPage}
            setShowTools={setShowTools}
            userDefaultPage={initialPage}
          />
        }
        navigationHide={false}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        onToolsChange={() => {
          if (sessionContext.selectedDeviceId) setShowTools(!showTools);
          if (!sessionContext.selectedDeviceId) setShowTools(false);
        }}
        stickyNotifications
        tools={
          (sessionContext.selectedDeviceId
          &&
          <HelpPanel
            header={<h3>{bundle.getMessage('settings')}</h3>}
          >
            <TemplateDeviceSettings />
            <DocumentDeviceSettings />
            <ReviewDeviceSettings />
          </HelpPanel>)
        }
        toolsOpen={showTools}
        toolsWidth={420}
      />
      <Tracker username={sessionContext.username} />
      <ReactQueryDevtools/>
    </>
  );
}
