import {
  Alert,
  Box,
  Button,
  CollectionPreferences,
  Link,
  Modal,
  Pagination,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter, 
 } from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './TemplatesTableConfig';
import {
  UseQueryResult,
  useMutation,
} from '@tanstack/react-query';
import {
  useEffect,
  useState,
} from 'react';
import { HardwareTemplate } from 'src/API';
import Header from '@amzn/awsui-components-react/polaris/header';
import { debug } from 'src/utils';
import { deleteHardwareTemplate } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

export interface ITemplatesTablePanelProps {
  closeTemplate: Function;
  createTemplate: Function;
  createTemplateRevision: Function;
  editTemplate: Function;
  templatesQuery: UseQueryResult<HardwareTemplate[]>;
  selectedTemplate: HardwareTemplate | null;
  setSelectedTemplate: Function;
}

export function TemplatesTablePanel(props: ITemplatesTablePanelProps ) {
  debug(`TemplatesTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<HardwareTemplate[]>(props.templatesQuery?.data || []);
  const [clickedDocument, setClickedDocument] = useState<HardwareTemplate | null>(null);
  const [error, setError] = useState<string>();
  const [hideTable] = useState<boolean>(false);
  const [pageSize] = useState<number>(DefaultPageSize.pageSize);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<HardwareTemplate | null>(props.selectedTemplate);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Templates.TemplatesTablePanel');

  const deleteHardwareTemplateMutation = useMutation(
    {
      mutationFn: deleteHardwareTemplate,
      onSuccess: (data) => {
        setAllItems([]);
        setError(undefined);
        setSelectedTemplate(null);
        props.templatesQuery.refetch();
        props.closeTemplate(data);
        setShowDeleteConfirmation(false);
      },
      onError: (error) => {
        console.error(error);
        setError(error.message);
      },
    }
  );

  const refresh = async () => {
    props.templatesQuery.refetch();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-templates-found') : 'No templates found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    setSelectedTemplate(null);
    refresh();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const deleteTemplate = async (id: string) => {
    await deleteHardwareTemplateMutation.mutateAsync({id});
  };

  useEffect(() => {
    if (!props.templatesQuery?.data) {
      setAllItems([]);
      return;
    }
    setAllItems(props.templatesQuery.data);
    if (props.templatesQuery.data.length === 1) {
      setSelectedTemplate(props.templatesQuery.data[0]);
    }
  }, [props.templatesQuery.data]);

  useEffect(() => {
    props.setSelectedTemplate(selectedTemplate);
  }, [selectedTemplate]);

  useEffect(() => {
    if (props.selectedTemplate !== selectedTemplate) setSelectedTemplate(props.selectedTemplate);
  }, [props.selectedTemplate]);

  useEffect(() => {
    if (clickedDocument) props.editTemplate();
  }, [clickedDocument]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowDeleteConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                loading={deleteHardwareTemplateMutation.isPending}
                iconName='check'
                onClick={() => deleteTemplate(selectedTemplate!.id)}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowDeleteConfirmation(false)}
        visible={showDeleteConfirmation}
      >
        {`${bundle.getMessage('delete')} ${selectedTemplate?.name} ${selectedTemplate?.versionId}`}
      </Modal>
      {
        error
        &&
        <Alert
          header={bundle.getMessage('error')}
          type='error'
          dismissible
          onDismiss={() => setError('')}
        >
          {error}
        </Alert>
      }
      <div id='TemplatesTablePanel' hidden={hideTable}>
        <Table
          {...collectionProps}
          columnDefinitions={[
            {
              id: 'name',
              header: bundle.getMessage('name'),
              cell: item =>
                <Link
                  onClick={async () => {
                    setClickedDocument(item);
                    setSelectedTemplate(item);
                    props.setSelectedTemplate(item);
                  }}
                >
                  {item.name}
                </Link>,
              sortingField: 'name',
            },
            ...ColumnDefinitions
          ]}
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-templates')}
              filteringPlaceholder={bundle.getMessage('find-templates')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
          }
          footer={
            <Header
              actions={
                <SpaceBetween direction='horizontal' size='s'>
                  <Button
                    onClick={() => props.createTemplate()}
                    iconName='add-plus'
                    variant='primary'
                  >
                    {bundle.getMessage('create')}
                  </Button>
                  <Button
                    disabled={selectedTemplate === null}
                    iconName='edit'
                    onClick={() => props.editTemplate()}
                    variant='normal'
                  >
                    {bundle.getMessage('edit')}
                  </Button>
                  <Button
                    disabled={selectedTemplate === null}
                    onClick={() => props.createTemplateRevision()}
                    iconName='copy'
                    variant='normal'
                  >
                    {bundle.getMessage('new-revision')}
                  </Button>
                  <Button
                    disabled={selectedTemplate === null}
                    onClick={() => setShowDeleteConfirmation(true)}
                    iconName='remove'
                    variant='normal'
                  >
                    {bundle.getMessage('delete')}
                  </Button>
                </SpaceBetween>
              }
            >
            </Header>
          }
          header={
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <Button
                  loading={refreshing}
                  key='refreshBtn'
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                />
              }
            >
              {bundle.getMessage('templates')}
            </Header>
          }
          items={items}
          loading={props.templatesQuery?.fetchStatus == 'fetching'}
          empty={props.templatesQuery?.status == 'error'
            ?
              <Alert type='error'>
                `${bundle.getMessage('query-failure')} (${typeof props.templatesQuery?.error === 'object'
                   ? JSON.stringify(props.templatesQuery?.error)
                   : 'TODO'
                   })`
              </Alert>
            : bundle.getMessage('no-templates-found')
          }
          loadingText={bundle.getMessage('loading-templates')}
          onSelectionChange={({ detail }) => setSelectedTemplate(detail.selectedItems[0]) }
          pagination={
            allItems.length > pageSize
            &&
            <Pagination
              {...paginationProps}
              ariaLabels={PaginationLabels}
            />
          }
          preferences={
            <CollectionPreferences
              cancelLabel={bundle.getMessage('cancel')}
              confirmLabel={bundle.getMessage('confirm')}
              onConfirm={() => alert('TODO: save preferences')}
              pageSizePreference={{
                title: bundle.getMessage('select-page-size'),
                options: [
                  { value: 5, label: '5' },
                  { value: 10, label: '10' },
                  { value: 15, label: '15' },
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ],
              }}
              preferences={{
                pageSize: pageSize,
              }}
              title={bundle.getMessage('preferences')}
            />
          }
          resizableColumns={true}
          selectedItems={selectedTemplate ? [selectedTemplate] : []}
          selectionType='single'
          trackBy='id'
        />
      </div>
    </>);
}
