import * as uuid from 'uuid';
import {
  ApplicationSettings,
  Device,
  DeviceSettings,
  HardwareDevice,
  HardwareType,
  HardwareTypeDetails,
  RulesEvaluation,
  RulesResponse,
  Slot,
  Status,
} from 'src/API';
import {
  Button,
  Container,
  FormField,
  Grid,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from './OpenDocumentsContext';
import {
  evaluateRules,
  sendSNSTopic,
} from './utils';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { DocumentRulesEvaluationTablePanel } from './DocumentRulesEvaluationTablePanel';
import { Images } from 'src/constants';
import { debug } from 'src/utils';
import { useAllApplicationSettings } from '../common/hooks/useAllApplicationSettings';
import { useBundle } from '@amzn/react-arb-tools';
import { useSelectedApplicationSettings } from '../common/hooks/useSelectedApplicationSettings';
import { useSessionContext } from '../common/SessionContext';

const documentStatusOptions = [
  Status.Development,
  Status.Inactive,
  Status.Review,
];

export interface IDocumentActions {
  addDevice: Function;
  applyTemplate: Function;
  close: Function;
  saveDocument: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
  showPlumageModel: Function;
  updateDocument: Function;
  undoDocument: Function;
}

export function DocumentActions(props: IDocumentActions) {
  debug(`DocumentActions() props is ${JSON.stringify(props)}`);

  const openDocumentsContext = useOpenDocumentsContext();

  const sessionContext = useSessionContext();

  const allApplicationSettingsQuery = useAllApplicationSettings();

  const selectedApplicationSettingsQuery = useSelectedApplicationSettings(props.selectedApplicationSettingsVersion.value!);

  const [allApplicationSettings, setAllApplicationSettings] = useState<ApplicationSettings[] | undefined>(allApplicationSettingsQuery.data || []);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [documentRulesEvaluating, setDocumentRulesEvaluating] = useState<boolean>(false);
  const [documentRulesEvaluatingPercentComplete, setDocumentRulesEvaluatingPercentComplete] = useState<number>(0);
  const [documentRulesResponses, setDocumentRulesResponses] = useState<RulesResponse[]>([]);
  const [documentRulesEvaluations, setDocumentRulesEvaluations] = useState<RulesEvaluation[]>([]);
  const [hardwareDevices, setHardwareDevices] = useState<(HardwareDevice | null)[]>([]);
  const [hardwareTypes, setHardwareTypes] = useState<(HardwareTypeDetails | null)[]>([]);
  const [readyForSave, setReadyForSave] = useState<(boolean)>(false);
  const [saving, setSaving] = useState<(boolean)>(false);
  const [sending, setSending] = useState<boolean>(false);
  const [selectedApplicationSettings, setSelectedApplicationSettings] = useState<ApplicationSettings | null>(selectedApplicationSettingsQuery.data || null);
  const [selectedApplicationSettingsVersion, setSelectedApplicationSettingsVersion] = useState<SelectProps.Option | null>(props.selectedApplicationSettingsVersion);
  const [selectedHardwareDevice, setSelectedHardwareDevice] = useState<(SelectProps.Option | null)>(null);
  const [selectedHardwareTemplate, setSelectedHardwareTemplate] = useState<SelectProps.Option | null>(null);
  const [selectedHardwareType, setSelectedHardwareType] = useState<SelectProps.Option | null>(null);
  const [statusOptions] = useState<(SelectProps.Options)>(() => {
    return Object.values(Status).filter(s => documentStatusOptions.includes(s)).map(value => ({ label: value, value }));
  });

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentActions');

  const selectHardwareDevice = (selectedOption: SelectProps.Option) => {
    if (!selectedApplicationSettings || !selectedHardwareType?.value) return;
    if (selectedOption.value === '') {
      setSelectedHardwareDevice(null);
      return;
    }
    try {
      setSelectedHardwareDevice(selectedOption);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const add = () => {
    if (!selectedApplicationSettings
      || !selectedHardwareType?.value
      || (!selectedHardwareTemplate && !selectedHardwareDevice)) return;
    if (selectedHardwareTemplate) {
      props.applyTemplate(selectedHardwareTemplate.value, openDocumentsContext.currentDeviceId);
      return;
    }
    if (selectedHardwareDevice) {
      const hardwareDevice = hardwareDevices.find(hd => hd?.name === selectedHardwareDevice.value);
      if (!hardwareDevice) return;
      const deviceSettings: DeviceSettings[] = hardwareDevice?.fields
        ?.map(f => (
          {
            key: f.name,
            segments: f.segments,
            segmentDelimiter: f.segmentDelimiter,
            uiAttributes: f.uiAttributes,
            value: (f.uiAttributes?.find(f => f?.name === 'componentType')?.value ?? '') === 'Toggle' ? 'false' : '',
          })) || [];
      const slots: Slot[] = hardwareDevice?.slots
        ?.map(s => (
          {
            description: s.description,
            hardwareType: s.hardwareType,
            id: uuid.v4(),
            name: s.name,
            settings: s.settings,
          })) || [];
      const newDevice: Device = {
        hardwareDeviceName: hardwareDevice.name,
        hardwareType: selectedHardwareType.value as HardwareType,
        id: uuid.v4(),
        name: hardwareDevice.name,
        parentDeviceId: openDocumentsContext.currentDeviceId,
        settings: deviceSettings,
        slots,
      };
      props.addDevice(newDevice);
      return;
    }
  };

  const count = useRef<number>(0);

  const handleEvaluateRuleResponse = (ruleResponse: RulesResponse) => {
    debug(`handleRuleResponse() handleEvaluateRuleResponse() ruleResponse is ${JSON.stringify(ruleResponse)} count is ${count}`);
    if (!ruleResponse) return;
    const deviceCount = openDocumentsContext.currentDocument.temporaryDocument.devices
      .filter(d => d.hardwareTemplateId && d.hardwareTemplateDeviceId).length;
    debug(`handleRuleResponse() handleEvaluateRuleResponse() deviceCount is ${deviceCount}`);
    count.current++;
    debug(`handleRuleResponse() handleEvaluateRuleResponse() count.current.valueOf() is ${count.current.valueOf()}`);
    setDocumentRulesEvaluatingPercentComplete(Math.round((count.current.valueOf() / deviceCount) * 100));
  }

  const verify = async (): Promise<boolean> => {
    if (!openDocumentsContext.currentDocument?.temporaryDocument?.devices) return;
    count.current = 0;
    setDocumentRulesEvaluatingPercentComplete(0);
    setDocumentRulesEvaluating(true);
    setDocumentRulesResponses([]);
    const rulesEvaluations: RulesEvaluation[] = [];
    const ruleEvaluationPromises: Promise<RulesResponse | null>[] = [];
    let failedRules = false;
    let ruleEvaluationResults = [];
    for (const device of openDocumentsContext.currentDocument.temporaryDocument.devices) {
      if (device.hardwareTemplateId && device.hardwareTemplateDeviceId) {
        ruleEvaluationPromises.push(evaluateRules(device, handleEvaluateRuleResponse));
      }
    }
    ruleEvaluationResults = await Promise.all(ruleEvaluationPromises);
    setDocumentRulesResponses(ruleEvaluationResults);
    debug(`DocumentActions() verify() ruleEvaluationResults is ${JSON.stringify(ruleEvaluationResults)}`);
    if (ruleEvaluationResults.length === 0) alert(bundle.getMessage('template-not-applied'));
    for (const ruleEvaluationResult of ruleEvaluationResults) {
      if (!ruleEvaluationResult) continue;
      if (ruleEvaluationResult.data.failedRules.length > 0) failedRules = true;
      const newDocumentDevice: Device = {
        ...ruleEvaluationResult.data.device,
        failedRules: ruleEvaluationResult.data.failedRules,
        passedRules: ruleEvaluationResult.data.passedRules,
      };
      await openDocumentsContext.dispatch({ type: OpenDocumentActionType.updateDeviceForRulesEvaluation, payload: { device: newDocumentDevice } });
      rulesEvaluations.push({
        device: newDocumentDevice,
        failedRules: ruleEvaluationResult.data.failedRules,
        passedRules: ruleEvaluationResult.data.passedRules,
      });
    }
    setDocumentRulesEvaluations(rulesEvaluations);
    setDocumentRulesEvaluating(false);
    return(failedRules);
  };

  const save = async () => {
    setSaving(true);
    const failedRules = await verify();
    if (!failedRules) setReadyForSave(true);
    setSaving(false);
  };

  const send = async () => {
    setSending(true);
    alert('TODO: send device configuration to PACS');
    await sendSNSTopic(openDocumentsContext.currentDocument?.savedDocument.id);
    setSending(false);
  }

  useEffect(() => {
    if (readyForSave) {
      setReadyForSave(false);
      props.saveDocument(openDocumentsContext.currentDocument?.temporaryDocument);
      setDocumentRulesEvaluations([]); 
    }
  }, [readyForSave])
  
  useEffect(() => {
    setSelectedApplicationSettingsVersion(props.selectedApplicationSettingsVersion);
  }, [props.selectedApplicationSettingsVersion]);

  useEffect(() => {
    setAllApplicationSettings(allApplicationSettingsQuery.data);
  }, [allApplicationSettingsQuery.data]);

  useEffect(() => {
    setSelectedApplicationSettings(selectedApplicationSettingsQuery.data || null);
  }, [selectedApplicationSettingsQuery.data]);

  useEffect(() => {
    setHardwareTypes(selectedApplicationSettings?.hardwareTypes || []);
    setHardwareDevices([]);
    setSelectedHardwareType(null);
    setSelectedHardwareTemplate(null);
  }, [selectedApplicationSettings]);

  useEffect(() => {
    setSelectedHardwareTemplate(null);
    setSelectedHardwareDevice(null);
    if (!selectedHardwareType) return;
    const hardwareDevices = hardwareTypes.find(ht => ht?.name === selectedHardwareType?.label)?.hardwareDevices || [];
    setHardwareDevices(hardwareDevices);
  }, [selectedHardwareType]);

  useEffect(() => {
    if (!openDocumentsContext.currentDeviceId) return;
    const currentDevice = openDocumentsContext?.currentDocument?.temporaryDocument?.devices?.find(d => d.id === openDocumentsContext.currentDeviceId);
    if (!currentDevice) return;
  }, [openDocumentsContext.currentDeviceId]);

  useEffect(() => {
    try {
      const darkMode = JSON.parse(sessionContext.userPreferences.preferences)?.darkMode ?? false;
      setDarkMode(darkMode);
    } catch (error) {
      console.error(error); 
    }
  }, [sessionContext.userPreferences.preferences]);
  
  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      {(documentRulesEvaluating || documentRulesEvaluations?.length > 0)
      &&
      <Modal
        onDismiss={() => {
          setDocumentRulesEvaluations([]);
        }}
        header={
          <h2>
            {bundle.getMessage('rules-evaluation')}
          </h2>
        }
        size='large'
        visible={documentRulesEvaluations !== null}
      >
        <DocumentRulesEvaluationTablePanel
          rulesEvaluating={documentRulesEvaluating}
          rulesEvaluatingPercentComplete={documentRulesEvaluatingPercentComplete}
          rulesEvaluations={documentRulesEvaluations}
          rulesResponses={documentRulesResponses}
        />
      </Modal>}
      <form onSubmit={event => event.preventDefault()}>
        <SpaceBetween direction='vertical' size='s'>
          <Container>
            <Grid gridDefinition={[
              {colspan: 3},
              {colspan: 4},
              {colspan: 2},
              {colspan: 1},
              {colspan: 2},
            ]}>
              <FormField label={bundle.getMessage('name')} >
                <Input
                  disabled
                  value={openDocumentsContext.currentDocument?.temporaryDocument.name || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('description')} stretch >
                <Input
                  onChange={( { detail }) => {
                    props.updateDocument(
                      {
                        ...openDocumentsContext.currentDocument?.temporaryDocument,
                        description: detail.value,
                      });
                  }}
                  value={openDocumentsContext.currentDocument?.temporaryDocument.description || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('status')} stretch >
                <Select
                  onChange={event => {
                    props.updateDocument(
                      {
                        ...openDocumentsContext.currentDocument?.temporaryDocument,
                        status: event.detail.selectedOption.value,
                      });
                  }}
                  selectedOption={{
                    label: openDocumentsContext.currentDocument!.temporaryDocument.status,
                    value: openDocumentsContext.currentDocument!.temporaryDocument.status,
                  }}
                  options={statusOptions}
                />
              </FormField>
              <FormField label={bundle.getMessage('version')} >
                <Input
                  disabled
                  value={openDocumentsContext.currentDocument?.temporaryDocument.versionId || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('updated')} >
                <Input
                  disabled
                  value={openDocumentsContext.currentDocument?.temporaryDocument.updatedAt || ''}
                />
              </FormField>
            </Grid>
          </Container>
          <Container>
            <SpaceBetween direction='horizontal' size='s' alignItems='end'>
              <SpaceBetween direction='horizontal' size='xs' alignItems='end'>
                <FormField label={bundle.getMessage('application-settings-version')}>
                  <Select
                    ariaLabel={''}
                    ariaDescribedby={''}
                    ariaRequired
                    disabled={allApplicationSettings === undefined || allApplicationSettings.length === 0}
                    onChange={({ detail }) => props.setSelectedApplicationSettingsVersion(detail.selectedOption)}
                    options={allApplicationSettings?.map((applicationSettings: ApplicationSettings) => {
                      return {
                        label: applicationSettings.versionId,
                        value: applicationSettings.versionId,
                      };
                    })}
                    renderHighlightedAriaLive={undefined}
                    selectedAriaLabel=''
                    selectedOption={selectedApplicationSettingsVersion}
                  />
                </FormField>
                <FormField>
                  <Button
                    formAction='none'
                    iconName='refresh'
                    loading={allApplicationSettingsQuery.isFetching}
                    onClick={async ()=> {
                      await allApplicationSettingsQuery.refetch();
                      props.setSelectedApplicationSettingsVersion({...selectedApplicationSettingsVersion});
                    }}
                  />
                </FormField>
                <FormField label={bundle.getMessage('hardware-type')}>
                  <Select
                    ariaDescribedby={''}
                    ariaLabel={''}
                    ariaRequired
                    disabled={selectedApplicationSettingsVersion === null}
                    onChange={({ detail }) => setSelectedHardwareType(detail.selectedOption)}
                    options={hardwareTypes.map(ht => { return { value: ht?.type, label: ht?.name } } ) || []}
                    placeholder={bundle.getMessage('choose-a-hardware-type')}
                    renderHighlightedAriaLive={undefined}
                    selectedAriaLabel=''
                    selectedOption={selectedHardwareType}
                  />
                </FormField>
                <FormField label={bundle.getMessage('hardware-device')}>
                  <Select
                    ariaLabel={''}
                    ariaDescribedby={''}
                    ariaRequired
                    disabled={selectedHardwareType === null || selectedHardwareTemplate !== null}
                    filteringType='auto'
                    onChange={({ detail }) => selectHardwareDevice(detail.selectedOption)}
                    options={
                      [
                        ...hardwareDevices?.map(hd => { return { value: hd?.name, label: hd?.name } } ) || [],
                        {value: '', label: bundle.getMessage('none')}
                      ]
                    }
                    placeholder={bundle.getMessage('choose-a-hardware-device')}
                    renderHighlightedAriaLive={undefined}
                    selectedAriaLabel=''
                    selectedOption={selectedHardwareDevice}
                  />
                </FormField>
                <FormField>
                  <Button
                    disabled={(selectedHardwareTemplate === null && selectedHardwareDevice === null)
                      || openDocumentsContext.currentDocument?.saving || saving}
                    formAction='none'
                    iconName='add-plus'
                    onClick={() => add()}
                    variant='primary'
                  >
                    {bundle.getMessage('add')}
                  </Button>
                </FormField>
              </SpaceBetween>
              <SpaceBetween direction='horizontal' size='xs' alignItems='end'>
               <FormField>
                  <Button
                    disabled={!openDocumentsContext.currentDocument?.changed || openDocumentsContext.currentDocument?.saving
                      || saving}
                    formAction='none'
                    iconName='undo'
                    onClick={() => props.undoDocument()}
                    variant='normal'
                  >
                    {bundle.getMessage('undo')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={openDocumentsContext.currentDocument?.saving || saving}
                    formAction='none'
                    iconName='status-info'
                    loading={documentRulesEvaluating}
                    onClick={() => verify()}
                    variant='normal'
                  >
                    {bundle.getMessage('verify')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={!openDocumentsContext.currentDocument?.changed || openDocumentsContext.currentDocument?.saving}
                    iconName='file'
                    loading={openDocumentsContext.currentDocument?.saving || saving}
                    onClick={() => save()}
                    variant='primary'
                  >
                    {bundle.getMessage('save')}
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={openDocumentsContext.currentDocument?.changed || openDocumentsContext.currentDocument?.saving}
                    formAction='none'
                    iconName='send'
                    loading={sending}
                    onClick={() => send()}
                    variant='normal'
                  >
                    {bundle.getMessage('send')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={openDocumentsContext.currentDocument?.saving || saving}
                    formAction='none'
                    iconName='close'
                    onClick={() => props.close()}
                    variant='normal'
                  >
                    {bundle.getMessage('close')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={
                      !openDocumentsContext.currentDocument?.temporaryDocument?.devices
                      || openDocumentsContext.currentDocument.temporaryDocument.devices.length === 0}
                    formAction='none'
                    iconName='zoom-to-fit'
                    iconSvg={
                      <svg>
                        {darkMode
                        &&
                        <image href={Images.showGraphDarkMode} />}
                        {!darkMode
                        &&
                        <image href={Images.showGraphLightMode} />}
                      </svg>
                    }
                    onClick={() => props.showPlumageModel()}
                    variant='normal'
                  >
                    {bundle.getMessage('show-graph')} 
                  </Button>
                </FormField>
              </SpaceBetween>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </form>
    </Container>);
}
