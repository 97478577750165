import * as uuid from 'uuid';
import {
  Box,
  Button,
  Container,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './AddDownstreamTableConfig';
import {
  Device,
  Slot,
} from 'src/API';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useState } from 'react';

interface IAddDownstreamProps {
  addDownstream: Function;
  closeAddDownstream: Function;
  deviceForAddDownstream: Device;
}

export function AddDownstreamTablePanel(props: IAddDownstreamProps) {
  debug(`AddDownstreamTablePanel() props is ${JSON.stringify(props)}`);

  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Templates.AddDownstreamTablePanel');

  const { actions, collectionProps, filterProps, filteredItemsCount, items } = useCollection(
    props.deviceForAddDownstream?.slots as Slot[] || [],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-downstream-found') : 'No downstream found'} />,
        filteringFunction: (item, filteringText) => {
          if (!item) return false;
          return item.name.toLowerCase().includes(filteringText.toLowerCase())
          || item.hardwareType.toLowerCase().replace(/_/g, ' ').includes(filteringText.toLowerCase())
        },
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
  });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const addDownstream = () => {
    if (!selectedSlot) return;
    const newDevice: Device = {
      settings: [],
      hardwareType: selectedSlot.hardwareType,
      hardwareDeviceName: selectedSlot.name,
      id: uuid.v4(),
      name: selectedSlot.name,
      parentDeviceId: props.deviceForAddDownstream.id,
    };
    props.addDownstream(newDevice);
    close();
  };

  const close = () => {
    setSelectedSlot(null);
    actions.setFiltering(''); 
    props.closeAddDownstream();
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <Container
      footer={
        <Box
          float='right'
        >
          <SpaceBetween direction='horizontal' size='s'>
            <Button
              onClick={() => close()}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              disabled={selectedSlot === undefined}
              onClick={() => addDownstream()}
              variant='primary'
            >
              {bundle.getMessage('apply')}
          </Button>
          </SpaceBetween>
        </Box>
      }
      variant='stacked'
    >
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-downstream')}
            filteringPlaceholder={bundle.getMessage('find-downstream')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        items={items}
        onSelectionChange={({ detail }) => setSelectedSlot(detail.selectedItems[0]) }
        selectedItems={selectedSlot ? [selectedSlot] : []}
        selectionType='single'
      />
    </Container>);
}
