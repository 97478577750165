import * as uuid from 'uuid';
import {
  ApplicationSettings,
  Device,
  DeviceSettings,
  HardwareDevice,
  HardwareType,
  HardwareTypeDetails,
  Slot,
  Status,
} from 'src/API';
import {
  Button,
  Container,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  useEffect,
  useState,
} from 'react';
import { Images } from 'src/constants';
import { debug } from '../../utils';
import { useAllApplicationSettings } from '../common/hooks/useAllApplicationSettings';
import { useBundle } from '@amzn/react-arb-tools';
import { useOpenTemplatesContext } from './OpenTemplatesContext';
import { useSelectedApplicationSettings } from '../common/hooks/useSelectedApplicationSettings';
import { useSessionContext } from '../common/SessionContext';

const templateStatusOptions = [
  Status.Active,
  Status.Development,
  Status.Inactive,
];

export interface ITemplateActions {
  addDevice: Function;
  close: Function;
  saveTemplate: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
  showPlumageModel: Function;
  updateTemplate: Function;
  undoTemplate: Function;
}

export function TemplateActions(props: ITemplateActions) {
  debug(`TemplateActions() props is ${JSON.stringify(props)}`);

  const openTemplatesContext = useOpenTemplatesContext();

  const sessionContext = useSessionContext();

  const allApplicationSettingsQuery = useAllApplicationSettings();

  const selectedApplicationSettingsQuery = useSelectedApplicationSettings(props.selectedApplicationSettingsVersion.value!);

  const [allApplicationSettings, setAllApplicationSettings] = useState<ApplicationSettings[] | undefined>(allApplicationSettingsQuery.data || []);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [hardwareTypes, setHardwareTypes] = useState<(HardwareTypeDetails | null)[]>([]);
  const [hardwareDevices, setHardwareDevices] = useState<(HardwareDevice | null)[]>([]);
  const [selectedApplicationSettings, setSelectedApplicationSettings] = useState<ApplicationSettings | null>(selectedApplicationSettingsQuery.data || null);
  const [selectedApplicationSettingsVersion, setSelectedApplicationSettingsVersion] = useState<SelectProps.Option | null>(props.selectedApplicationSettingsVersion);
  const [selectedHardwareDevice, setSelectedHardwareDevice] = useState<(SelectProps.Option | null)>(null);
  const [selectedHardwareType, setSelectedHardwareType] = useState<SelectProps.Option | null>(null);
  const [statusOptions] = useState<(SelectProps.Options)>(() => {
    return Object.values(Status).filter(s => templateStatusOptions.includes(s)).map(value => ({ label: value, value }));
  });

  const [bundle, isBundleLoading] = useBundle('components.Templates.TemplateActions');

  const selectHardwareDevice = (selectedOption: SelectProps.Option) => {
    if (!selectedApplicationSettings || !selectedHardwareType?.value || !selectedOption?.value) return;
    try {
      setSelectedHardwareDevice(selectedOption);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addDevice = () => {
    if (!selectedApplicationSettings
      || !selectedHardwareType?.value
      || !selectedHardwareDevice) return;
    const hardwareDevice = hardwareDevices.find(hd => hd?.name === selectedHardwareDevice.value);
    if (!hardwareDevice) return;
    const deviceSettings: DeviceSettings[] = hardwareDevice?.fields
      ?.map(f => (
        {
          key: f.name,
          uiAttributes: f.uiAttributes,
          value: '',
        })) || [];
    const slots: Slot[] = hardwareDevice?.slots
      ?.map(s => (
        {
          description: s.description,
          hardwareType: s.hardwareType,
          id: uuid.v4(),
          name: s.name,
          settings: s.settings,
        })) || [];
    const newDevice: Device = {
      settings: deviceSettings,
      hardwareDeviceName: hardwareDevice.name,
      hardwareType: selectedHardwareType.value as HardwareType,
      id: uuid.v4(),
      name: hardwareDevice.name,
      parentDeviceId: openTemplatesContext.currentDeviceId,
      slots,
    };
    props.addDevice(newDevice);
  };

  useEffect(() => {
    setSelectedApplicationSettingsVersion(props.selectedApplicationSettingsVersion);
  }, [props.selectedApplicationSettingsVersion]);

  useEffect(() => {
    setAllApplicationSettings(allApplicationSettingsQuery.data);
  }, [allApplicationSettingsQuery.data]);

  useEffect(() => {
    setSelectedApplicationSettings(selectedApplicationSettingsQuery.data || null);
  }, [selectedApplicationSettingsQuery.data]);

  useEffect(() => {
    setHardwareTypes(selectedApplicationSettings?.hardwareTypes || []);
    setHardwareDevices([]);
    setSelectedHardwareType(null);
  }, [selectedApplicationSettings]);

  useEffect(() => {
    const hardwareDevices = hardwareTypes.find(ht => ht?.name === selectedHardwareType?.label)?.hardwareDevices || [];
    setHardwareDevices(hardwareDevices);
    setSelectedHardwareDevice(null);
  }, [selectedHardwareType]);

  useEffect(() => {
    try {
      const darkMode = JSON.parse(sessionContext.userPreferences.preferences)?.darkMode ?? false;
      setDarkMode(darkMode);
    } catch (error) {
      console.error(error); 
    }
  }, [sessionContext.userPreferences.preferences]);

  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      <form onSubmit={event => event.preventDefault()}>
        <SpaceBetween direction='vertical' size='s'>
          <Container >
            <Grid gridDefinition={[
              {colspan: 3},
              {colspan: 4},
              {colspan: 2},
              {colspan: 1},
              {colspan: 2},
            ]}>
              <FormField label={bundle.getMessage('name')} >
                <Input
                  disabled
                  value={openTemplatesContext.currentTemplate?.temporaryTemplate.name || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('description')} stretch >
                <Input
                  onChange={( { detail }) => {
                    debug(`TemplateActions() onChange() detail is ${JSON.stringify(detail)}`);
                    props.updateTemplate(
                      {
                        ...openTemplatesContext.currentTemplate?.temporaryTemplate,
                        description: detail.value,
                      });
                  }}
                  value={openTemplatesContext.currentTemplate?.temporaryTemplate.description || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('status')} stretch >
                <Select
                  onChange={event => {
                    props.updateTemplate(
                      {
                        ...openTemplatesContext.currentTemplate?.temporaryTemplate,
                        status: event.detail.selectedOption.value,
                      });
                  }}
                  selectedOption={{
                    label: openTemplatesContext.currentTemplate!.temporaryTemplate.status,
                    value: openTemplatesContext.currentTemplate!.temporaryTemplate.status,
                  }}
                  options={statusOptions}
                />
              </FormField>
              <FormField label={bundle.getMessage('version')} >
                <Input
                  disabled
                  value={openTemplatesContext.currentTemplate?.temporaryTemplate.versionId || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('updated')} >
                <Input
                  disabled
                  value={openTemplatesContext.currentTemplate?.temporaryTemplate.updatedAt || ''}
                />
              </FormField>
            </Grid>
          </Container>
          <Container>
            <SpaceBetween direction='horizontal' size='s' alignItems='end'>
              <SpaceBetween direction='horizontal' size='xs' alignItems='end'>
                <FormField label={bundle.getMessage('application-settings-version')}>
                  <Select
                    ariaLabel={''}
                    ariaDescribedby={''}
                    ariaRequired
                    disabled={allApplicationSettings === undefined || allApplicationSettings.length === 0}
                    onChange={({ detail }) => props.setSelectedApplicationSettingsVersion(detail.selectedOption)}
                    options={allApplicationSettings?.map((applicationSettings: ApplicationSettings) => {
                      return {
                        label: applicationSettings.versionId,
                        value: applicationSettings.versionId,
                      };
                    })}
                    renderHighlightedAriaLive={undefined}
                    selectedAriaLabel=''
                    selectedOption={selectedApplicationSettingsVersion}
                  />
                </FormField>
                <FormField>
                  <Button
                    formAction='none'
                    iconName='refresh'
                    loading={allApplicationSettingsQuery.isFetching}
                    onClick={async ()=> {
                      await allApplicationSettingsQuery.refetch();
                      props.setSelectedApplicationSettingsVersion({...selectedApplicationSettingsVersion});
                    }}
                  />
                </FormField>
                <FormField label={bundle.getMessage('hardware-type')}>
                  <Select
                    ariaDescribedby={''}
                    ariaLabel={''}
                    ariaRequired
                    disabled={selectedApplicationSettingsVersion === null}
                    onChange={({ detail }) => setSelectedHardwareType(detail.selectedOption)}
                    options={hardwareTypes.map(ht => { return { value: ht?.type, label: ht?.name } } ) || []}
                    placeholder={bundle.getMessage('choose-a-hardware-type')}
                    renderHighlightedAriaLive={undefined}
                    selectedAriaLabel=''
                    selectedOption={selectedHardwareType}
                  />
                </FormField>
                <FormField label={bundle.getMessage('hardware-device')}>
                  <Select
                    ariaLabel={''}
                    ariaDescribedby={''}
                    ariaRequired
                    disabled={selectedHardwareType === null}
                    filteringType='auto'
                    onChange={({ detail }) => selectHardwareDevice(detail.selectedOption)}
                    options={hardwareDevices?.map(hd => { return { value: hd?.name, label: hd?.name } } ) || []}
                    placeholder={bundle.getMessage('choose-a-hardware-device')}
                    renderHighlightedAriaLive={undefined}
                    selectedAriaLabel=''
                    selectedOption={selectedHardwareDevice}
                  />
                </FormField>
                <FormField>
                  <Button
                    disabled={selectedHardwareDevice == null || openTemplatesContext.currentTemplate?.saving}
                    formAction='none'
                    iconName='add-plus'
                    onClick={() => addDevice()}
                    variant='primary'
                  >
                    {bundle.getMessage('add')}
                  </Button>
                </FormField>
              </SpaceBetween>
              <SpaceBetween direction='horizontal' size='xs' alignItems='end'>
                <FormField>
                  <Button
                    disabled={openTemplatesContext.currentTemplate?.saving}
                    formAction='none'
                    iconName='close'
                    onClick={() => props.close()}
                    variant='normal'
                  >
                    {bundle.getMessage('close')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={!openTemplatesContext.currentTemplate?.changed || openTemplatesContext.currentTemplate?.saving}
                    formAction='none'
                    iconName='redo'
                    onClick={() => props.undoTemplate()}
                    variant='normal'
                  >
                    {bundle.getMessage('undo')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={openTemplatesContext.currentTemplate?.saving}
                    formAction='none'
                    iconName='status-info'
                    onClick={() => alert('TODO: get device status from PACS if it exists')}
                    variant='normal'
                  >
                    {bundle.getMessage('verify')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={!openTemplatesContext.currentTemplate?.changed || openTemplatesContext.currentTemplate?.saving}
                    iconName='file'
                    loading={openTemplatesContext.currentTemplate?.saving}
                    onClick={() => props.saveTemplate(openTemplatesContext.currentTemplate?.temporaryTemplate)}
                    variant='primary'
                  >
                    {bundle.getMessage('save')}
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={openTemplatesContext.currentTemplate?.changed || openTemplatesContext.currentTemplate?.saving}
                    formAction='none'
                    iconName='send'
                    onClick={() => alert('TODO: send device configuration to PACS')}
                    variant='normal'
                  >
                    {bundle.getMessage('send')} 
                  </Button>
                </FormField>
                <FormField>
                  <Button
                    disabled={
                      !openTemplatesContext.currentTemplate?.temporaryTemplate?.devices
                      || openTemplatesContext.currentTemplate.temporaryTemplate.devices.length === 0}
                    formAction='none'
                    iconName='zoom-to-fit'
                    iconSvg={
                      <svg>
                        {darkMode
                        &&
                        <image href={Images.showGraphDarkMode} />}
                        {!darkMode
                        &&
                        <image href={Images.showGraphLightMode} />}
                      </svg>
                    }
                    onClick={() => props.showPlumageModel()}
                    variant='normal'
                  >
                    {bundle.getMessage('show-graph')} 
                  </Button>
                </FormField>
              </SpaceBetween>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </form>
    </Container>);
}
