import {
  Box,
  Button,
  Container,
  FormField,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner } from '@amzn/awsui-components-react';
import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from '../Documents/OpenDocumentsContext';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useSessionContext } from './SessionContext';

interface ISelectSiteProps {
  selectedSiteCode: string | undefined;
  setSelectedSite: Function;
  setSiteSelectVisible: Function;
  personId: string;
}

export default function SelectSite(props: ISelectSiteProps) {
  debug(`SelectSite(): props is ${JSON.stringify(props)}`);

  const openDocumentsContext = useOpenDocumentsContext();
  const sessionContext = useSessionContext();

  const [selected, setSelected] = useState<boolean>(false);
  const [selectedSiteCode, setSelectedSiteCode] = useState<string | undefined>(props.selectedSiteCode);

  const [bundle, isBundleLoading] = useBundle('components.common.SelectSite');

  const siteFieldOnChangeHandler = (detail: SelectProps.ChangeDetail) => {
    setSelectedSiteCode(detail.selectedOption.label);
  };

  const selectSite = () => {
    props.setSelectedSite(selectedSiteCode);
    setSelected(true);
    openDocumentsContext.dispatch({ type: OpenDocumentActionType.closeAll, payload: {} });
  };

  const siteSelectRef = useRef<SelectProps.Ref>(null);

  useEffect(() => {
    siteSelectRef.current?.focus();
  }, [siteSelectRef.current]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Modal
      onDismiss={() => props.setSiteSelectVisible(false)}
      size='small'
      visible={true}
      header={bundle.getMessage('select-a-site')}
    >
      <Container
        footer={
          <Box float='right'>
            <Button
              ariaLabel='Select User Site'
              disabled={!selectedSiteCode || selectedSiteCode === props.selectedSiteCode}
              loading={selected}
              onClick={() => selectSite()}
              variant='primary'
            >
              {bundle.getMessage('select')}
            </Button>
          </Box>
        }
      >
        <SpaceBetween size='m' direction='vertical'>
          <FormField label={bundle.getMessage('site')}>
            <Select
              ariaDescribedby={selectedSiteCode}
              ariaLabel='User Sites'
              ariaRequired
              empty={bundle.getMessage('no-sites-found')}
              filteringPlaceholder={bundle.getMessage('sites-placeholder')}
              filteringType='auto'
              loadingText={bundle.getMessage('loading-sites')}
              onChange={({ detail }) => siteFieldOnChangeHandler(detail)}
              options={sessionContext.userSites?.map(s => { return { label: s, value: s }; } ).sort((c,p) => c.label! < p.label! ? -1 : 1)}
              placeholder={bundle.getMessage('select-site')}
              ref={siteSelectRef}
              renderHighlightedAriaLive={option => `Select Site ${option.label}`}
              selectedAriaLabel='Selected User Site'
              selectedOption={{ label: selectedSiteCode, value: selectedSiteCode }}
            />
          </FormField>
        </SpaceBetween>
      </Container>
    </Modal>
  );
}