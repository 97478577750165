import {
  Alert,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  OpenReviewActionType,
  useOpenReviewsContext,
} from './OpenReviewsContext';
import {
  useEffect,
  useState,
} from 'react';
import { Review } from './Review';
import { ReviewsTablePanel } from './ReviewsTablePanel';
import { SiteHardwareConfiguration } from 'src/API';
import { UseQueryResult } from '@tanstack/react-query';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useSessionContext } from '../common/SessionContext';

interface IReviewsProps {
  reviewsQuery: UseQueryResult<SiteHardwareConfiguration[]>;
}

export default function Reviews(props: IReviewsProps) {
  debug(`Reviews() props is ${JSON.stringify(props)}`);

  const openReviewsContext = useOpenReviewsContext();
  const sessionContext = useSessionContext();

  const [documentSaveError, setReviewSaveError] = useState<string | null>(null);
  const [selectedReview, setSelectedReview] = useState<SiteHardwareConfiguration | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Reviews.index');

  const editReview = () => {
    openReviewsContext?.dispatch({ type: OpenReviewActionType.open, payload: { review: selectedReview } });
  };

  const closeReview = (review: SiteHardwareConfiguration) => {
    openReviewsContext.dispatch({ type: OpenReviewActionType.close, payload: { review } });
  };

  useEffect(() => {
    if (openReviewsContext.currentReview?.id) {
      setSelectedReview({...openReviewsContext.currentReview.savedReview});
    }
  }, [openReviewsContext.currentReview]);

  useEffect(() => {
    if (openReviewsContext.currentReview
      && openReviewsContext.currentReview?.id === selectedReview?.id)
    {
      setSelectedReview({...openReviewsContext.currentReview.savedReview});
    }
  }, [openReviewsContext]);

  if (isBundleLoading) return <Spinner/>;

  if (sessionContext.siteCode === '') {
    return (
      <Alert
        type='info'
      >
        {bundle.getMessage('select-a-site')}
      </Alert>
    );
  }

  if (openReviewsContext.currentReview) {
    return(
      <Review
        close={closeReview}
      />);
  }

  return(
    <>
      {documentSaveError
      &&
      <Alert
        onDismiss={() => setReviewSaveError(null)}
        type='error'
      >
        {documentSaveError}
      </Alert>}
      <ReviewsTablePanel
        closeReview={closeReview}
        editReview={editReview}
        reviewsQuery={props.reviewsQuery}
        selectedReview={selectedReview}
        setSelectedReview={setSelectedReview}
      />
    </>);
}
