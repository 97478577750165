import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from '../Documents/OpenDocumentsContext';
import {
  OpenReviewActionType,
  useOpenReviewsContext,
} from '../Reviews/OpenReviewsContext';
import {
  OpenTemplateActionType,
  useOpenTemplatesContext,
} from '../Templates/OpenTemplatesContext';
import {
  Page,
  Pages,
  SPOTSesameUserPermissions,
} from './constants';
import {
  SessionActionType,
  useSessionContext,
} from './SessionContext';
import
  SideNavigation,
  {
    SideNavigationProps,
  } from '@amzn/awsui-components-react/polaris/side-navigation';
import {
  useEffect,
  useState,
} from 'react';
import { Spinner } from '@amzn/awsui-components-react';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';

interface ISideNav {
  setCurrentPage: Function;
  setShowTools: Function;
  userDefaultPage: Page | null;
}

export default function SideNav(props: ISideNav) {
  debug(`SideNav() props is ${JSON.stringify(props)}`);

  const openDocumentsContext = useOpenDocumentsContext();
  const openTemplatesContext = useOpenTemplatesContext();
  const openReviewsContext = useOpenReviewsContext();
  const sessionContext = useSessionContext();

  const [bundle, isBundleLoading] = useBundle('components.common.SideNav');

  const [activeHref, setActiveHref] = useState<string>(
    Pages.find(p => p.code === (props.userDefaultPage))?.route || '#');
  const [menuItems, setMenuItems] = useState<SideNavigationProps.Item[]>([]);

  function createMenuItems(): SideNavigationProps.Item[] {
    const
      items: SideNavigationProps.Item[] = [],
      templatesPriv = sessionContext.userPermissions?.applicationPermissions?.find(ap => ap.permissionName === SPOTSesameUserPermissions.template) !== undefined,
      documentsPriv = sessionContext.userPermissions?.applicationPermissions?.find(ap => ap.permissionName === SPOTSesameUserPermissions.configuration) !== undefined,
      reviewsPriv = sessionContext.userPermissions?.applicationPermissions?.find(ap => ap.permissionName === SPOTSesameUserPermissions.review) !== undefined;
    if (templatesPriv && openTemplatesContext.templates.length > 0 || openTemplatesContext.currentTemplate)
    {
      const allOpenTemplates = [...openTemplatesContext.templates];
      if (openTemplatesContext.currentTemplate) {
        allOpenTemplates.push(openTemplatesContext.currentTemplate); 
      }
      items.push({
        type: 'link-group',
        text: bundle.getMessage(Page.Templates),
        href: `${Pages.find(p => p.code === Page.Templates)?.route}` || '#',
        items: 
          allOpenTemplates
            .sort((a,b) => `${a.savedTemplate.name.replace(/\s/g, '')}${a.savedTemplate.versionId}` < `${b.savedTemplate.name.replace(/\s/g, '')}${b.savedTemplate.versionId}` ? -1 : 1)
            .map(od => {
              let href = `#/${Page.Templates}/${od.id}`;
              const activeHrefParams = activeHref.split('/');
              if (activeHrefParams[1] === Page.Templates
                && activeHrefParams[4] === 'devices'
                && activeHrefParams[5] !== null
                && activeHrefParams[3] === od.id)
              {
                href = `#/${Page.Templates}/${od.id}/devices/${activeHrefParams[5]}`;
              }
              return({
                type: 'link',
                text: `${od.savedTemplate.name} ${od.savedTemplate.versionId}`,
                href,
              });
            })
      });
    } else {
      items.push({
        type: 'link',
        text: bundle.getMessage(Pages.find(p => p.code === Page.Templates)?.name || ''),
        href: `${Pages.find(p => p.code === Page.Templates)?.route}` || '#',
      });
    }
    if (documentsPriv && openDocumentsContext.documents.length > 0 || openDocumentsContext.currentDocument)
    {
      const allOpenDocuments = [...openDocumentsContext.documents];
      if (openDocumentsContext.currentDocument) {
        allOpenDocuments.push(openDocumentsContext.currentDocument); 
      }
      items.push({
        type: 'link-group',
        text: bundle.getMessage(Page.Documents),
        href: `${Pages.find(p => p.code === Page.Documents)?.route}${sessionContext.siteCode ? sessionContext.siteCode : ''}` || '#',
        items: 
          allOpenDocuments
            .sort((a,b) => `${a.savedDocument.name.replace(/\s/g, '')}${a.savedDocument.versionId}` < `${b.savedDocument.name.replace(/\s/g, '')}${b.savedDocument.versionId}` ? -1 : 1)
            .map(od => {
              let href = `#/${Page.Documents}/${sessionContext.siteCode}/${od.id}`;
              const activeHrefParams = activeHref.split('/');
              if (activeHrefParams[1] === Page.Documents
                && activeHrefParams[4] === 'devices'
                && activeHrefParams[5] !== null
                && activeHrefParams[3] === od.id)
              {
                href = `#/${Page.Documents}/${sessionContext.siteCode}/${od.id}/devices/${activeHrefParams[5]}`;
              }
              return({
                type: 'link',
                text: `${od.savedDocument.name} ${od.savedDocument.versionId}`,
                href,
              });
            })
      });
    } else {
      items.push({
        type: 'link',
        text: bundle.getMessage(Pages.find(p => p.code === Page.Documents)?.name || ''),
        href: `${Pages.find(p => p.code === Page.Documents)?.route}${sessionContext.siteCode ? sessionContext.siteCode : ''}` || '#',
      });
    }
    if (reviewsPriv && openReviewsContext.reviews.length > 0 || openReviewsContext.currentReview)
    {
      const allOpenReviews = [...openReviewsContext.reviews];
      if (openReviewsContext.currentReview) {
        allOpenReviews.push(openReviewsContext.currentReview); 
      }
      items.push({
        type: 'link-group',
        text: bundle.getMessage(Page.Reviews),
        href: `${Pages.find(p => p.code === Page.Reviews)?.route}${sessionContext.siteCode ? sessionContext.siteCode : ''}` || '#',
        items: 
          allOpenReviews
            .sort((a,b) => `${a.savedReview.name.replace(/\s/g, '')}${a.savedReview.versionId}` < `${b.savedReview.name.replace(/\s/g, '')}${b.savedReview.versionId}` ? -1 : 1)
            .map(od => {
              let href = `#/${Page.Reviews}/${sessionContext.siteCode}/${od.id}`;
              const activeHrefParams = activeHref.split('/');
              if (activeHrefParams[1] === Page.Reviews
                && activeHrefParams[4] === 'devices'
                && activeHrefParams[5] !== null
                && activeHrefParams[3] === od.id)
              {
                href = `#/${Page.Reviews}/${sessionContext.siteCode}/${od.id}/devices/${activeHrefParams[5]}`;
              }
              return({
                type: 'link',
                text: `${od.savedReview.name} ${od.savedReview.versionId}`,
                href,
              });
            })
      });
    } else {
      items.push({
        type: 'link',
        text: bundle.getMessage(Pages.find(p => p.code === Page.Reviews)?.name || ''),
        href: `${Pages.find(p => p.code === Page.Reviews)?.route}${sessionContext.siteCode ? sessionContext.siteCode : ''}` || '#',
      });
    }
    return items;
  }

  useEffect(() => {
    const activeHrefParams = activeHref.split('/');
    debug(`SideNav() useEffect()[activeHref] activeHrefParams is ${JSON.stringify(activeHrefParams)}`);
    switch (activeHrefParams[1]) {
      case Page.Documents:
        try {
          openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentReview, payload: { review: null }});
          openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentTemplate, payload: { template: null }});
          sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: null } });
        } catch(error) {
          console.error(error);
        }
        if (activeHrefParams[3] === undefined) {
          try {
            openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDeviceId, payload: { deviceId: null }});
            openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDocument, payload: { document: null }});
          } catch(error) {
            console.error(error);
          }
        }
        if (activeHrefParams[3] !== null) {
          const temporaryDocument = openDocumentsContext.documents.find(d => d.id === activeHrefParams[3])?.temporaryDocument;
          if (temporaryDocument) {
            const document = {...temporaryDocument};
            if (document) {
              try {
                openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDeviceId, payload: { deviceId: null }});
                openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDocument, payload: { document }});
              } catch (error) {
                console.error(error); 
              }
            }
          }
        }
        props.setCurrentPage(Page.Documents);
        break;

      case Page.Reviews:
        try {
          openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentTemplate, payload: { template: null }});
          openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDocument, payload: { document: null }});
          sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: null } });
        } catch(error) {
          console.error(error);
        }
        if (activeHrefParams[3] === undefined) {
          try {
            openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentDeviceId, payload: { deviceId: null }});
            openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentReview, payload: { review: null }});
          } catch(error) {
            console.error(error);
          }
        }
        if (activeHrefParams[3] !== null) {
          const temporaryReview = openReviewsContext.reviews.find(d => d.id === activeHrefParams[3])?.temporaryReview;
          if (temporaryReview) {
            const review = {...temporaryReview};
            if (review) {
              try {
                openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentDeviceId, payload: { deviceId: null }});
                openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentReview, payload: { review }});
              } catch (error) {
                console.error(error); 
              }
            }
          }
        }
        props.setCurrentPage(Page.Reviews);
        break;

      case Page.Templates:
        try {
          openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDocument, payload: { document: null }});
          openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentReview, payload: { review: null }});
          sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: null } });
        } catch(error) {
          console.error(error);
        }
        if (activeHrefParams[2] === undefined || activeHrefParams[2] === '') {
          try {
            openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentDeviceId, payload: { deviceId: null }});
            openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentTemplate, payload: { template: null }});
          } catch(error) {
            console.error(error);
          }
        }
        if (activeHrefParams[2] !== null) {
          const temporaryTemplate = openTemplatesContext.templates.find(t => t.id === activeHrefParams[2])?.temporaryTemplate;
          if (temporaryTemplate) {
            const template = {...temporaryTemplate};
            if (template) {
              try {
                openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentDeviceId, payload: { deviceId: null }});
                openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentTemplate, payload: { template }});
              } catch (error) {
                console.error(error); 
              }
            }
          }
        }
        props.setCurrentPage(Page.Templates);
        break;

      default:
        try {
          openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openDocumentsContext.dispatch({ type: OpenDocumentActionType.setCurrentDocument, payload: { document: null }});
          openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openTemplatesContext.dispatch({ type: OpenTemplateActionType.setCurrentTemplate, payload: { template: null }});
          openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentDeviceId, payload: { deviceId: null }});
          openReviewsContext.dispatch({ type: OpenReviewActionType.setCurrentReview, payload: { review: null }});
          sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: null } });
        } catch(error) {
          console.error(error);
        }
        break;
    }
  }, [activeHref]);

  useEffect(() => {
    debug(`SideNav() useEffect()[openTemplatesContext] activeHref is ${activeHref} openTemplatesContext is ${JSON.stringify(openTemplatesContext)}`);
    debug(`SideNav() useEffect()[openTemplatesContext] activeHref is ${activeHref} openTemplatesContext.currentDeviceId is ${openTemplatesContext.currentDeviceId}`);
    if (activeHref.includes(Page.Templates)) {
      if (!openTemplatesContext?.currentTemplate?.id) {
      setActiveHref(`${Pages.find(p => p.code == Page.Templates)?.route}`);
      }
      if (openTemplatesContext.currentTemplate?.id) {
        setActiveHref(`${Pages.find(p => p.code == Page.Templates)?.route}${openTemplatesContext.currentTemplate.id}`);
      }
      if (openTemplatesContext.currentDeviceId) {
        props.setShowTools(true);
      }
      if (!openTemplatesContext.currentDeviceId) {
        props.setShowTools(false);
      }
    }
  }, [openTemplatesContext]);

  useEffect(() => {
    debug(`SideNav() useEffect()[openDocumentsContext] activeHref is ${activeHref} openDocumentsContext is ${JSON.stringify(openDocumentsContext)}`);
    debug(`SideNav() useEffect()[openDocumentsContext] activeHref is ${activeHref} openDocumentsContext.currentDeviceId is ${openDocumentsContext.currentDeviceId}`);
    if (activeHref.includes(Page.Documents)) {
      if (!openDocumentsContext?.currentDocument?.id) {
        setActiveHref(`${Pages.find(p => p.code == Page.Documents)?.route}${sessionContext.siteCode}`);
      }
      if (openDocumentsContext.currentDocument?.id) {
        setActiveHref(`${Pages.find(p => p.code == Page.Documents)?.route}${sessionContext.siteCode}/${openDocumentsContext.currentDocument.id}`);
      }
      if (openDocumentsContext.currentDeviceId) {
        props.setShowTools(true);
      }
      if (!openDocumentsContext.currentDeviceId) {
        props.setShowTools(false);
      }
    }
  }, [openDocumentsContext]);

  useEffect(() => {
    debug(`SideNav() useEffect()[openReviewsContext] activeHref is ${activeHref} openReviewsContext is ${JSON.stringify(openReviewsContext)}`);
    debug(`SideNav() useEffect()[openReviewsContext] activeHref is ${activeHref} openReviewsContext.currentDeviceId is ${openReviewsContext.currentDeviceId}`);
    if (activeHref.includes(Page.Reviews)) {
      if (!openReviewsContext?.currentReview?.id) {
        setActiveHref(`${Pages.find(p => p.code == Page.Reviews)?.route}${sessionContext.siteCode}`);
      }
      if (openReviewsContext.currentReview?.id) {
        setActiveHref(`${Pages.find(p => p.code == Page.Reviews)?.route}${sessionContext.siteCode}/${openReviewsContext.currentReview.id}`);
      }
      if (openReviewsContext.currentDeviceId) {
        props.setShowTools(true);
      }
      if (!openReviewsContext.currentDeviceId) {
        props.setShowTools(false);
      }
    }
  }, [openReviewsContext]);

  useEffect(() => {
    if (!isBundleLoading) setMenuItems(createMenuItems());
  }, [openDocumentsContext, openTemplatesContext, openReviewsContext, isBundleLoading]);

  useEffect(() => {
    setActiveHref(Pages.find(p => p.code === (props.userDefaultPage || Page.Documents))?.route || '#');
  }, [props.userDefaultPage]);

  if (isBundleLoading) return <Spinner/>;

  return (
    <SideNavigation
      header={{
        href: '#',
        text: '',
      }}
      activeHref={activeHref}
      items={menuItems}
      onFollow={event => {
        if (!event.detail.external) {
          setActiveHref(event.detail.href);
        }
      }}
    />
  );
}
