import {
  ColumnDefinitions,
  TableEmptyState,
  TableNoMatchState,
} from './DocumentRulesEvaluationTableConfig';
import {
  Flashbar,
  Header,
  ProgressBar,
  SpaceBetween,
  Spinner,
  Table,
} from '@amzn/awsui-components-react';
import {
  RulesEvaluation,
  RulesResponse,
} from 'src/API';
import {
  useEffect,
  useState,
} from 'react';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

export interface IRulesEvaluationProps {
  rulesEvaluating: boolean;
  rulesEvaluatingPercentComplete: number;
  rulesEvaluations: RulesEvaluation[];
  rulesResponses: RulesResponse[];
}

export const DocumentRulesEvaluationTablePanel = (props: IRulesEvaluationProps) => {
  debug(`DocumentRulesEvaluationTablePanel() props is ${JSON.stringify(props)}`);

  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentRulesEvaluationTablePanel');

  const [showRuleViolationFlashbar, setShowRuleViolationFlashbar] = useState<boolean>(false);

  const { items, collectionProps } = useCollection(
    [
      ...props.rulesEvaluations.flatMap(re => re.failedRules).filter(fr => fr?.id !== null && fr?.id !== undefined).map(fr => ({
        ...fr!,
        passed: false,
      })) || [],
      ...props.rulesEvaluations.flatMap(re => re.passedRules).filter(pr => pr?.id !== null && pr?.id !== undefined).map(pr => ({
        ...pr!,
        passed: true,
      })) || [],
    ],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-rules-found') : 'No rules found'} />,
        noMatch: <TableNoMatchState />
      },
      pagination: {
        defaultPage: 5,
      },
      selection: {
        trackBy: 'id',
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'passed',
          }
        },
      },
  });

  useEffect(() => {
    if (props.rulesEvaluations.some(re => re.failedRules.length > 0)) setShowRuleViolationFlashbar(true);
    if (!props.rulesEvaluations.some(re => re.failedRules.length > 0)) setShowRuleViolationFlashbar(false);
  }, [props.rulesEvaluations]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <SpaceBetween direction='vertical' size='s'>
      {props.rulesEvaluating
      &&
      <ProgressBar
        value={props.rulesEvaluatingPercentComplete}
        description={bundle.getMessage('evaluating-rules')}
        label={bundle.getMessage('progress')}
      />}
      {showRuleViolationFlashbar
      &&
      <Flashbar
        items={[
          {
            id: 'review-violations',
            content: (
              <>
                {bundle.getMessage('review-violations')}
              </>
            ),
            dismissible: true,
            onDismiss: () => setShowRuleViolationFlashbar(false),
            type: 'error',
          }
        ]}
      />}
      {props.rulesResponses.find(rr => rr.status !== 200)
      &&
      <Flashbar
        items={[
          {
            id: 'error',
            content: (
              <ul>
                {props.rulesResponses.filter(rr => rr.status !== 200)?.map((item, index) => {
                  return(
                    <li key={index.toString()}>
                      Error: {JSON.stringify(item.message)}
                    </li>);
                })}
              </ul>
            ),
            type: 'error',
          }
        ]}
      />}
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        header={<Header>{bundle.getMessage('rules')}</Header>}
        items={items}
        loading={props.rulesEvaluating}
        stickyHeader
      />
    </SpaceBetween>
  );
}