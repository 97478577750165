import * as uuid from 'uuid';
import {
  Alert,
  Box,
  Button,
  Container,
  Flashbar,
  Header,
  Modal,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  DARK_THEME,
  LIGHT_THEME,
  Plumage,
  PlumageEvents,
  PlumageModel,
} from '@amzn/plumage';
import {
  Device,
  HardwareTemplate,
  SiteHardwareConfiguration,
} from 'src/API'
import {
  OpenDocumentActionType,
  useOpenDocumentsContext,
} from './OpenDocumentsContext';
import {
  PlumageStyle,
  QueryKey,
} from '../common/constants';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import { DocumentActions } from './DocumentActions';
import { DocumentDevicesTablePanel } from './DocumentDevicesTablePanel';
import { debug } from 'src/utils';
import { transformDocumentDevicesToPlumageModel } from '../utils';
import { updateSiteHardwareConfiguration } from './utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useMutation } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useSessionContext } from '../common/SessionContext';

export interface IDocumentProps {
  close: Function;
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
}

export function Document(props: IDocumentProps) {
  debug(`Document() props is ${JSON.stringify(props)}`);

  const openDocumentsContext = useOpenDocumentsContext();

  const sessionContext = useSessionContext();

  const queryClient = useQueryClient();

  const [document, setDocument] = useState<SiteHardwareConfiguration | null>(openDocumentsContext.currentDocument?.temporaryDocument || null);
  const [documentSaveError, setDocumentSaveError] = useState<string | null>(null);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState<boolean>(false);
  const [showDocumentSaved, setShowDocumentSaved] = useState<boolean>(false);
  const [showPlumageModel, setShowPlumageModel] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Documents.Document');

  const saveDocumentMutation = useMutation({
    mutationFn: updateSiteHardwareConfiguration,
    onSettled: (data) => {
      openDocumentsContext.dispatch({ type: OpenDocumentActionType.save, payload: { document: data }});
      queryClient.invalidateQueries({ queryKey: [QueryKey.documents] });
      setShowDocumentSaved(true);
    },
    onError: (error) => {
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    },
  });

  const addDevice = async (device: Device) => {
    debug(`Document() addDevice() item is ${JSON.stringify(device)}`);
    if (!device) return;
    try {
      await openDocumentsContext.dispatch({ type: OpenDocumentActionType.addDevice, payload: { device } });
    } catch(error: any) {
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const findParentDevice = (deviceId: string | null, parentDeviceId: string): string => {
    const parentDevice = openDocumentsContext.currentDocument?.temporaryDocument?.devices?.find(td => td.id === parentDeviceId);
    if (parentDevice) return parentDevice.id;
    return deviceId || uuid.v4();
  };

  const applyTemplate = async (templateId: string, applyToDeviceId: string | null, applyToDeviceParentDeviceId?: string) => {
    debug(`Document() applyTemplate() templateId is ${templateId} applyToDeviceId is ${applyToDeviceId} applyToDeviceParentDeviceId is ${applyToDeviceParentDeviceId}`);
    if (!templateId) return;
    try {
      const hardwareTemplates = queryClient.getQueryData([QueryKey.templates]) as HardwareTemplate[];
      if (!hardwareTemplates) throw new Error('template not found');
      let newDevices: Device[] = [];
      const newDevicesParentIdMap: Map<string, { newParentDeviceId: string, childDeviceIds: string[] }> = new Map();
      const currentDevice: Device | undefined = applyToDeviceId
        ? openDocumentsContext.currentDocument?.temporaryDocument?.devices?.find(d => d.id === applyToDeviceId)
        : undefined;
      debug(`Document() applyTemplate() currentDevice is ${JSON.stringify(currentDevice)}`);
      hardwareTemplates.find(ht => ht.id === templateId)?.devices
        ?.forEach(d => newDevices.push({
          ...currentDevice,
          settings: d.settings,
          hardwareTemplateDeviceId: d.id,
          hardwareTemplateId: templateId,
          hardwareType: d.hardwareType,
          hardwareDeviceName: d.hardwareDeviceName,
          // if root device of template assign given applyToDeviceId to replace it with template device
          id: d.parentDeviceId === null && applyToDeviceId ? applyToDeviceId : d.id,
          name: currentDevice?.address ? `Address ${currentDevice.address} ${d.name}` : d.name,
          // if root device of template assign given applyToDeviceParentDeviceId
          parentDeviceId: d.parentDeviceId === null ? applyToDeviceParentDeviceId : d.parentDeviceId,
        }));
      debug(`Document() applyTemplate() newDevices is ${JSON.stringify(newDevices)}`);
      // create a map of the devices to update the parentDeviceId to the root device id for the direct children
      for (let i = 0; i < newDevices.length; i++) {
        const d = newDevices[i];
        if (d.parentDeviceId) {
          if (!newDevicesParentIdMap.has(d.parentDeviceId)) {
            newDevicesParentIdMap.set(
              d.parentDeviceId,
              {
                // if orphaned child device set parent to apply to device
                newParentDeviceId: findParentDevice(applyToDeviceId, d.parentDeviceId),
                childDeviceIds: [],
              });
          }
          newDevicesParentIdMap.get(d.parentDeviceId)?.childDeviceIds.push(d.id);
        }
      }
      newDevicesParentIdMap.forEach((value, key) => {
        debug(`Document() applyTemplate() newDevicesParentIdMap key is ${key} value is ${JSON.stringify(value)}`);
      });
      newDevices = newDevices.map(d => {
        const newDeviceParentIdMapping = d.parentDeviceId ? newDevicesParentIdMap.get(d.parentDeviceId) : null;
        debug(`Document() applyTemplate() newDeviceParentIdMapping is ${JSON.stringify(newDeviceParentIdMapping)}`);
        return({
          ...d,
          id: newDevicesParentIdMap.get(d.id)?.newParentDeviceId || d.id === applyToDeviceId ? d.id : uuid.v4(),
          parentDeviceId: d.parentDeviceId ? newDevicesParentIdMap.get(d.parentDeviceId)?.newParentDeviceId : null,
        });
      });

      debug(`Document() applyTemplate() newDevices is ${JSON.stringify(newDevices)}`);

      await openDocumentsContext.dispatch({
        type: OpenDocumentActionType.setDevices,
        payload: {
          devices: [
            ...openDocumentsContext.currentDocument?.temporaryDocument?.devices?.filter(d => d.id !== applyToDeviceId) || [],
            ...newDevices,
          ],
        }
      });

      openDocumentsContext.dispatch({
        type: OpenDocumentActionType.setParentReadersCount,
        payload: {
          deviceId: applyToDeviceId,
        }
      });
    } catch(error: any) {
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const saveDocument = async (document: SiteHardwareConfiguration) => {
    debug(`Document() saveDocument() document is ${JSON.stringify(document)}`);
    if (!document) return;
    openDocumentsContext.dispatch({ type: OpenDocumentActionType.saving, payload: { } });
    try {
      const saveInput = {
        id: document.id,
        description: document.description,
        devices: document.devices,
        name: document.name,
        status: document.status,
        versionId: document.versionId,
      };
      await saveDocumentMutation.mutateAsync(saveInput);
    } catch(error: any) {
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const updateDocument = async (updatedDocument: SiteHardwareConfiguration) => {
    debug(`Document() updateDocument() updatedDocument is ${JSON.stringify(updatedDocument)}`);
    if (!updatedDocument) return;
    try {
      openDocumentsContext.dispatch({ type: OpenDocumentActionType.update, payload: { document: updatedDocument } });
    } catch(error: any) {
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const undoDocument = async () => {
    debug(`Document() undoDocument() document is ${JSON.stringify(document)}`);
    if (!document) return;
    try {
      openDocumentsContext.dispatch({ type: OpenDocumentActionType.undo, payload: { document } });
    } catch(error: any) {
      setDocumentSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      console.error(error);
    }
  };

  const closeDocument = () => {
    const documentChanged = openDocumentsContext.currentDocument?.changed;
    if (documentChanged) {
      setShowCloseConfirmation(true);
    }
    if (!documentChanged) props.close(document);
  };

  const plumageModel: PlumageModel = useMemo(
    () => transformDocumentDevicesToPlumageModel(openDocumentsContext.currentDocument.temporaryDocument.devices),
    [openDocumentsContext.currentDocument.temporaryDocument.devices]
  );

  const events: PlumageEvents = {
    onSelectionChanged: (event) => {
      debug(`Document() events.onSelectionChanged() event is ${JSON.stringify(event)}`)
    },
  };

  useEffect(() => {
    setDocument(openDocumentsContext.currentDocument?.temporaryDocument || null);
  }, [openDocumentsContext.currentDocument?.temporaryDocument]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      {showDocumentSaved
      &&
      <Flashbar
        items={
          [
            {
              content: bundle.getMessage('document-saved'),
              dismissible: true,
              onDismiss: () => setShowDocumentSaved(false),
              type: 'success',
            },
          ]}
      />}
      <Modal
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size={'s'}>
              <Button
                onClick={() => setShowCloseConfirmation(false)}
                iconName='status-stopped'
              >
                {bundle.getMessage('no')}
              </Button>
              <Button
                iconName='check'
                onClick={() => props.close()}
                variant='primary'
              >
                {bundle.getMessage('yes')}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header>
            {bundle.getMessage('confirm')}
          </Header>
        }
        onDismiss={() => setShowCloseConfirmation(false)}
        visible={showCloseConfirmation}
      >
        {`${bundle.getMessage('document-close-confirm')}`}
      </Modal>
      <Container
        header={
          documentSaveError
          &&
          <Alert
            dismissible
            onDismiss={() => setDocumentSaveError(null)}
            type='error'
          >
            {documentSaveError}
          </Alert>
        }
      >
        {document
        &&
        <SpaceBetween direction='vertical' size='s'>
          <DocumentActions
            addDevice={addDevice}
            applyTemplate={applyTemplate}
            close={closeDocument}
            saveDocument={saveDocument}
            selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
            setSelectedApplicationSettingsVersion={props.setSelectedApplicationSettingsVersion}
            showPlumageModel={() => setShowPlumageModel(true)}
            updateDocument={updateDocument}
            undoDocument={undoDocument}
          />
          <DocumentDevicesTablePanel
            applyTemplate={applyTemplate}
            selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
          />
          {showPlumageModel
          &&
          <Modal
            onDismiss={() => setShowPlumageModel(false)}
            size='max'
            visible={showPlumageModel}
          >
            <Plumage
              events={events}
              options={{
                focusOnSelect: true,
                isUsingCFNIcons: true,
                viewportControls: {
                  showFullscreen: false,
                  showZoom: false,
                  showZoomToFit: false,
                },
              }}
              plumageModel={plumageModel}
              style={PlumageStyle}
              theme={
                JSON.parse(sessionContext.userPreferences.preferences)?.darkMode
                  ? DARK_THEME
                  : LIGHT_THEME
              }
            />
          </Modal>}
        </SpaceBetween>}
      </Container>
    </>);
}
