import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Container,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './TemplateDevicesTableConfig';
import {
  OpenTemplateActionType,
  useOpenTemplatesContext,
} from './OpenTemplatesContext';
import {
  SessionActionType,
  useSessionContext,
} from '../common/SessionContext';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { AddDownstreamTablePanel } from './AddDownstreamTablePanel';
import { Device } from 'src/API';
import { DeviceIcon } from '../common/DeviceIcon';
import { debug } from 'src/utils';
import { saveDevice } from './utils';
import { sortDevices } from '../utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useQueryClient } from '@tanstack/react-query';

export interface ITemplateDevicesTableProps {
  addDownstream: Function;
}

export const TemplateDevicesTablePanel = (props: ITemplateDevicesTableProps) => {
  debug(`TemplateDevicesTree() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  const openTemplatesContext = useOpenTemplatesContext();

  const queryClient = useQueryClient();

  const [selectedItems, setSelectedItems] = useState<Device[]>([]);
  const [showAddDownstream, setShowAddDownstream] = useState<boolean>(false);
  const [templateDevices, setTemplateDevices] = useState<Device[]>(
    openTemplatesContext.currentTemplate?.temporaryTemplate
      .devices?.filter(d => d !== null)?.sort((a, b) => a.id < b.id ? -1 : 1) || []);

  const [bundle, isBundleLoading] = useBundle('components.Templates.TemplateDevicesTablePanel');

  const deviceForAddDownstream = useRef<Device>();

  const { items, actions, collectionProps } = useCollection(
    sortDevices(templateDevices),
    {
      expandableRows: {
        getId: item => item.id,
        getParentId: item => item.parentDeviceId || null,
      },
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-devices-found') : 'No devices found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
  });

  const selectDevice = (selectedDevices: Device[]) => {
    try {
      const payload: { deviceId: string | null } = { deviceId: null};
      switch (selectedDevices.length) {
        case 0:
          setSelectedItems([]);
          break;
        case 1:
          setSelectedItems([selectedDevices[0]]);
          payload.deviceId = selectedDevices[0].id;
          break;
        case 2:
          setSelectedItems([selectedDevices[1]]);
          payload.deviceId = selectedDevices[1].id;
          break;
        default:
          setSelectedItems([]);
          break;
      }
      openTemplatesContext.dispatch({
        type: OpenTemplateActionType.setCurrentDeviceId,
        payload,
      });
    } catch(error) {
      console.error(error);
    }
  };

  const expand = () => {
    const expandedItems = collectionProps.expandableRows?.expandedItems;
    if (expandedItems && expandedItems?.length > 0) {
      actions.setExpandedItems([]);
      return;
    }
    const parentDevices = templateDevices
      .filter(device => templateDevices.find(innerDevice => innerDevice.parentDeviceId === device.id) !== undefined);
    actions.setExpandedItems(parentDevices);
  };

  enum actionType {
    addSlot = 'addSlot',
    remove = 'remove',
    save = 'save',
    undo = 'undo',
  }

  const act = (action: { type: actionType, payload: { device?: Device } }) => {
    if (!action.payload.device) action.payload.device = selectedItems[0];
    switch (action.type) {
      case actionType.addSlot:
        deviceForAddDownstream.current = action.payload.device;
        setShowAddDownstream(true);
        break;

      case actionType.remove:
        openTemplatesContext.dispatch({ type: OpenTemplateActionType.removeDevice, payload: { deviceId: action.payload.device.id } });
        break;

      case actionType.save:
        saveDevice(openTemplatesContext, queryClient);
        break;

      case actionType.undo:
        openTemplatesContext.dispatch({ type: OpenTemplateActionType.undoDevice, payload: { deviceId: action.payload.device.id } });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const device = (openTemplatesContext.currentTemplate?.temporaryTemplate.devices
      ?.find(d => d.id === openTemplatesContext.currentDeviceId));
    if (device) {
      selectDevice([device]);
      sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: device.id } });
    }
    if (!device) {
      selectDevice([]);
      sessionContext.dispatch({ type: SessionActionType.setSessionSelectedDeviceId, payload: { selectedDeviceId: null } });
    }
  }, [openTemplatesContext.currentDeviceId]);

  useEffect(() => {
    const updatedDevices = openTemplatesContext.currentTemplate?.temporaryTemplate.devices
      ?.filter(d => d !== null)?.sort((a, b) => a.id < b.id ? -1 : 1) || [];
    if (updatedDevices.length > templateDevices.length) {
      actions.setExpandedItems(updatedDevices);
      const addedDevice = updatedDevices.find(d => !templateDevices.includes(d));
      if (addedDevice) {
        setSelectedItems([addedDevice]);
        selectDevice([addedDevice]);
      }
    }
    setTemplateDevices(updatedDevices);
  }, [openTemplatesContext.currentTemplate?.temporaryTemplate.devices]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<Device>[] = [
    {
      id: 'name',
      header:
        <>
          <Button
            iconAlign='right'
            iconName={collectionProps.expandableRows?.expandedItems && collectionProps.expandableRows.expandedItems.length === 0
              ? 'treeview-expand'
              : 'treeview-collapse'
            }
            onClick={() => expand()}
            variant='icon'
          />
          {bundle.getMessage('name')}
        </>,
      cell: item =>
        <DeviceIcon
          deviceId={item.id}
          display={`${item.name} (${item.hardwareType.replace(/_/g, ' ').trim()})`}
          documentId={''}
          siteCode={''}
          type={item.hardwareType}
        />,
    },
  ];

  const deviceActions: ButtonDropdownProps.ItemOrGroup[] = [
    {
      text: `${bundle.getMessage('add-downstream')}...`,
      iconName: 'insert-row',
      id: actionType.addSlot,
    },
    {
      text: bundle.getMessage('remove'),
      iconName: 'remove',
      id: actionType.remove,
    },
    {
      text: bundle.getMessage('save'),
      iconName: 'file',
      id: actionType.save,
    },
    {
      text: bundle.getMessage('undo'),
      iconName: 'undo',
      id: actionType.undo,
    },
  ];

  return(
    <Container
      header={
        <Header
          actions={
            <ButtonDropdown
              expandToViewport
              items={deviceActions}
              onItemClick={({ detail }) => act({ type: detail.id as actionType, payload: {} })}
              variant='primary'
              disabled={selectedItems.length !== 1}
            >
              {bundle.getMessage('actions')}
            </ButtonDropdown>
          }
        >
          {bundle.getMessage('devices')}
        </Header>}
    >
      <Modal
        header={
          <h2>
            {bundle.getMessage('add-downstream')}
          </h2>
        }
        size='large'
        visible={showAddDownstream && deviceForAddDownstream !== null}
      >
        <AddDownstreamTablePanel
          addDownstream={props.addDownstream}
          closeAddDownstream={() => setShowAddDownstream(false)}
          deviceForAddDownstream={deviceForAddDownstream.current!}
        />
      </Modal>
      <SpaceBetween direction='vertical' size='xs'>
        <Table
          {...collectionProps}
          columnDefinitions={[
            ...ColumnDefinitions,
            {
              id: 'action',
              header: bundle.getMessage('actions'),
              cell: item =>
                <SpaceBetween direction='horizontal' size='xxxs'>
                  {deviceActions.map(action => {
                    return(
                      <Button
                        data-testid='editApplication'
                        data-tooltip-id='applications-tooltip'
                        data-tooltip-content={'edit xyz'}
                        data-tooltip-place='left'
                        ariaLabel={action.text}
                        iconName={action.iconName}
                        key={action.id}
                        onClick={() => act({ type: action.id as actionType, payload: { device: item } })}
                        variant='icon'
                      />);
                    })}
                </SpaceBetween>,
              width: '25%',
              maxWidth: '25%',
              minWidth: '25%',
            },
          ]}
          items={items}
          onSelectionChange={({ detail }) => selectDevice(detail.selectedItems) }
          resizableColumns={true}
          selectedItems={selectedItems}
          selectionType='multi'
        />
      </SpaceBetween>
    </Container>
  );
}