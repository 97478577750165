import * as uuid from 'uuid';
import {
  Box,
  Button,
  Container,
  Header,
  Icon,
  Input,
  Pagination,
  Select,
  SpaceBetween,
  Spinner,
  Table,
  TableProps,
  TextFilter,
} from '@amzn/awsui-components-react';
import {
  DefaultPageSize,
  PaginationLabels,
  RuleConditionWithDeviceSettings,
  TableEmptyState,
  TableNoMatchState,
} from './TemplateDeviceRuleConditionsTableConfig';
import {
  Device,
  Rule,
  RuleConditionGroupOperator,
  RuleConditionOperator,
} from 'src/API';
import {
  findKeyLabel,
  formatOperator,
} from './utils';
import {
  useEffect,
  useState,
} from 'react';
import { debug } from 'src/utils';
import { keyTypeOperators } from '../common/constants';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';

interface ITemplateDeviceRuleConditionsProps {
  addRuleCondition: Function;
  device: Device;
  removeRuleCondition: Function;
  rule: Rule | undefined;
  setConditions: Function;
}

export function TemplateDeviceRuleConditionsTablePanel(props: ITemplateDeviceRuleConditionsProps) {
  debug(`TemplateDeviceRuleConditionsTablePanel() props is ${JSON.stringify(props)}`);

  const [selectedRuleCondition, setSelectedRuleCondition] = useState<RuleConditionWithDeviceSettings | undefined>();
  const [conditions, setConditions] = useState<RuleConditionWithDeviceSettings[]>(props.rule?.conditions?.map(c => ({...c, deviceSettings: props.device.settings || []})) || []);
  const [pageSize] = useState<number>(DefaultPageSize.pageSize);

  const [bundle, isBundleLoading] = useBundle('components.Templates.TemplateDeviceRuleConditionsTablePanel');

  const { actions, collectionProps, filterProps, filteredItemsCount, items, paginationProps } = useCollection(
    [...conditions.map(c => ({...c, deviceSettings: props.device.settings || []}))],
    {
      expandableRows: {
        getId: item => item.id,
        getParentId: item => item.groupParentId || null,
      },
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-conditions-found') : 'No conditions found'} />,
        filteringFunction: (item, filteringText) => {
          return(
            item.key.toLowerCase().includes(filteringText.toLowerCase())
            || item.operator?.toLowerCase().replace(/_/g, ' ').includes(filteringText.toLowerCase())
            || item.value.toLowerCase().includes(filteringText.toLowerCase()));
        },
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: 'group',
          }
        },
      },
  });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const add = () => {
    actions.setFiltering('');
    const newCondition: RuleConditionWithDeviceSettings = {
      deviceSettings: [],
      group: false,
      groupParentId: selectedRuleCondition?.group ? selectedRuleCondition?.id : selectedRuleCondition?.groupParentId,
      id: uuid.v4(),
      key: '',
      value: '',
      not: false,
      operator: RuleConditionOperator.equals,
    };
    setConditions(
      [
        ...conditions,
        newCondition,
      ]);
    if (selectedRuleCondition?.group) {
      const expandedItems = collectionProps.expandableRows?.expandedItems;
      actions.setExpandedItems([
        ...expandedItems || [],
        selectedRuleCondition,
      ]);
    }
    setSelectedRuleCondition(newCondition);
    props.addRuleCondition(newCondition);
  };

  const addGroup = () => {
    actions.setFiltering('');
    const newCondition: RuleConditionWithDeviceSettings = {
      deviceSettings: [],
      group: true,
      groupOperator: RuleConditionGroupOperator.AND,
      groupParentId: selectedRuleCondition?.group ? selectedRuleCondition?.id : selectedRuleCondition?.groupParentId || null,
      id: uuid.v4(),
      key: '',
      value: '',
      not: false,
    };
    setConditions(
      [
        ...conditions,
        newCondition,
      ]);
    setSelectedRuleCondition(newCondition);
    props.addRuleCondition(newCondition);
  };

  const expand = () => {
    const expandedItems = collectionProps.expandableRows?.expandedItems;
    if (expandedItems && expandedItems?.length > 0) {
      actions.setExpandedItems([]);
      return;
    }
    const parentConditions = conditions
      .filter(device => conditions.find(innerDevice => innerDevice.groupParentId === device.id) !== undefined);
    actions.setExpandedItems(parentConditions);
  };

  const remove = () => {
    debug(`TemplateDeviceRuleConditionsTablePanel() remove()`);
    if (!selectedRuleCondition) return;
    setSelectedRuleCondition(undefined);
    props.removeRuleCondition(selectedRuleCondition);
  };

  const submitEdit = async (
    currentItem: RuleConditionWithDeviceSettings,
    column: TableProps.ColumnDefinition<RuleConditionWithDeviceSettings>,
    value: any) =>
  {
    const newCondition = conditions.find(c => c.id === currentItem.id);
    if (!newCondition) return;
    if (newCondition) {
      const attribute = column.id;
      if (!attribute) return;
      // @ts-ignore
      newCondition[attribute as keyof RuleConditionWithDeviceSettings] = typeof value === 'object' ? value.value : value;
      setConditions([...conditions]);
      props.setConditions([...conditions.map(c => ({...c, deviceSettings: undefined}))]);
    }
  };

  const updateItemGroupOperatorNot = (item: RuleConditionWithDeviceSettings) => {
    debug(`TemplateDeviceRuleConditionsTablePanel() updateItemGroupOperatorNot() item is ${JSON.stringify(item)}`);
    const newCondition = conditions.find(c => c.id === item.id);
    if (!newCondition) return;
    newCondition.not = !item.not;
    setConditions([...conditions]);
    props.setConditions([...conditions.map(c => ({...c, deviceSettings: undefined}))]);
    setSelectedRuleCondition(newCondition);
    const expandedItems = collectionProps.expandableRows?.expandedItems;
    actions.setExpandedItems([
      ...expandedItems || [],
      newCondition,
    ]);
  };

  const updateItemGroupOperator = (item: RuleConditionWithDeviceSettings) => {
    debug(`TemplateDeviceRuleConditionsTablePanel() updateItemGroupOperatorAnd item is ${JSON.stringify(item)}`);
    const newCondition = conditions.find(c => c.id === item.id);
    if (!newCondition) return;
    newCondition.groupOperator = item.groupOperator === RuleConditionGroupOperator.AND ? RuleConditionGroupOperator.OR : RuleConditionGroupOperator.AND;
    setConditions([...conditions]);
    props.setConditions([...conditions.map(c => ({...c, deviceSettings: undefined}))]);
    setSelectedRuleCondition(newCondition);
    const expandedItems = collectionProps.expandableRows?.expandedItems;
    actions.setExpandedItems([
      ...expandedItems || [],
      newCondition,
    ]);
  };

  const keyOperators = (key: any) => {
    debug(`TemplateDeviceRuleConditionsTablePanel() keyOperators() key is ${JSON.stringify(key)}`);
    if (!key) return [];
    const keySettings = props.device.settings?.find(s => s.key === key);
    debug(`TemplateDeviceRuleConditionsTablePanel() keyOperators() keySettings is ${JSON.stringify(keySettings)}`);
    if (!keySettings) return [];
    const keyType = keySettings.uiAttributes.find(a => a.name === 'type').value ?? 'string';
    debug(`TemplateDeviceRuleConditionsTablePanel() keyOperators() keyType is ${keyType}`);
    if (!keyType) return [];
    const allOperators = Object.keys(RuleConditionOperator).map(ro => ({label: ro.replace(/_/g, ' '), value: ro}));
    debug(`TemplateDeviceRuleConditionsTablePanel() keyOperators() allOperators is ${JSON.stringify(allOperators)}`);
    const keyOperators = allOperators.filter(o => keyTypeOperators.get(keyType).includes(o.value));
    debug(`TemplateDeviceRuleConditionsTablePanel() keyOperators() keyOperators is ${JSON.stringify(keyOperators)}`);
    return(keyOperators.sort((a, b) => { return a.label.localeCompare(b.label) }));
  }

  useEffect(() => {
    setConditions(props.rule?.conditions?.map(c => ({...c, deviceSettings: props.device.settings || []})) || []);
  }, [props.rule?.conditions]);

  if (isBundleLoading) return <Spinner/>;

  const ColumnDefinitions: TableProps.ColumnDefinition<RuleConditionWithDeviceSettings>[] = [
    {
      id: 'group',
      header:
        <>
          <Button
            iconAlign='right'
            iconName={collectionProps.expandableRows?.expandedItems && collectionProps.expandableRows.expandedItems.length === 0
              ? 'treeview-expand'
              : 'treeview-collapse'
            }
            onClick={() => expand()}
            variant='icon'
          />
          {bundle.getMessage('group')}
        </>,
      cell: (item) => item.group || item.groupParentId ? <Icon name='check'></Icon> : <></>,
    },
    {
      id: 'groupOperator',
      header: bundle.getMessage('group-operator'),
      cell: item => {
        if (!item.group) return(<></>);
        return(
          <SpaceBetween direction='horizontal' size='xxxs'>
            <Button
              onClick={(event) => {
                event.preventDefault();
                updateItemGroupOperatorNot(item);
              }}
              variant={item.not ? 'primary' : 'normal' }
            >
              NOT
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                updateItemGroupOperator(item);
              }}
              variant={item.groupOperator !== RuleConditionGroupOperator.OR ? 'primary' : 'normal' }
            >
              {RuleConditionGroupOperator.AND}
            </Button>
            <Button
              onClick={(event) => {
                event.preventDefault();
                updateItemGroupOperator(item);
              }}
              variant={item.groupOperator === RuleConditionGroupOperator.OR ? 'primary' : 'normal' }
            >
              {RuleConditionGroupOperator.OR}
            </Button>
          </SpaceBetween>);
      },
      minWidth: 253,
      sortingField: 'groupOperator',
    },
    {
      id: 'key',
      header: bundle.getMessage('key'),
      cell: item => findKeyLabel(props.device, item.key),
      editConfig: {
        ariaLabel: 'Key',
        disabledReason: item => {
          if (item.group) return 'Not available';
          return undefined;
        },
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Key Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              onChange={({ detail }) => {
                setValue({ value: detail.selectedOption.value!, label: detail.selectedOption.label! });
              }}
              options={[
                ...item.deviceSettings
                  .filter(ds => !ds.uiAttributes?.find(a => a?.name === 'aggregate')?.value)
                  .sort((a, b) => {
                    return a.uiAttributes?.find(a => a?.name === 'label')?.value.localeCompare(b.uiAttributes?.find(a => a?.name === 'label')?.value);
                  })
                  .map(ds => ({label: ds.uiAttributes?.find(a => a?.name === 'label')?.value || ds.key, value: ds.key})),
                {
                  label: bundle.getMessage('aggregate'),
                  options: [
                    ...item.deviceSettings
                      .filter(ds => ds.uiAttributes?.find(a => a?.name === 'aggregate')?.value)
                      .map(ds => ({label: ds.uiAttributes?.find(a => a?.name === 'label')?.value || ds.key, value: ds.key})),
                  ],
                }
              ]}
              selectedOption={currentValue ?? item.key}
            />
          );
        },
      },
      sortingField: 'key',
    },
    {
      id: 'operator',
      header: bundle.getMessage('operator'),
      cell: item => formatOperator(item.operator),
      editConfig: {
        ariaLabel: 'Operator',
        disabledReason: item => {
          if (item.group) return 'Not available';
          return undefined;
        },
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Operator Error',
        editingCell: (item, { currentValue, setValue }) => {
          return(
            <Select
              autoFocus
              expandToViewport
              onChange={({ detail }) => setValue({ value: detail.selectedOption.value!, label: detail.selectedOption.label! })}
              options={keyOperators(item.key)}
              selectedOption={currentValue ?? item.key}
            />);
        },
      },
      sortingField: 'operator',
    },
    {
      id: 'value',
      header: bundle.getMessage('value'),
      cell: item => item.value,
      editConfig: {
        ariaLabel: 'Value',
        disabledReason: item => {
          if (item.group) return 'Not available';
          return undefined;
        },
        editIconAriaLabel: 'editable',
        errorIconAriaLabel: 'Value Error',
        editingCell: (item, { currentValue, setValue }) => {
          debug(`TemplateDeviceRuleConditionsTablePanel() currentValue is ${JSON.stringify(currentValue)} item is ${JSON.stringify(item)}`);
          const componentType = item.deviceSettings.find(ds => ds.key === item.key)?.uiAttributes?.find(a => a?.name === 'componentType')?.value;
          if (componentType === 'toggle') {
            return(
              <Select
                autoFocus
                expandToViewport
                onChange={({ detail }) => setValue({ value: detail.selectedOption.value!, label: detail.selectedOption.label! })}
                options={
                  [
                    {label: 'false', value: 'false' },
                    {label: 'true', value: 'true' }
                  ]}
                selectedOption={currentValue ?? item.key}
              />);
          }
          return(
            <Input
              autoFocus
              onChange={({ detail }) => setValue(detail.value)}
              value={currentValue ?? item.value}
            />);
        },
      },
      sortingField: 'value',
    },
  ];

  return(
    <Container
      footer={
        <Box
          float='right'
        >
          <SpaceBetween direction='horizontal' size='s'>
            <Button
              disabled={!selectedRuleCondition}
              onClick={() => remove()}
            >
              {bundle.getMessage('remove')}
            </Button>
            <Button
              disabled={!selectedRuleCondition}
              onClick={() => setSelectedRuleCondition(undefined)}
              variant='normal'
            >
              {bundle.getMessage('unselect')}
            </Button>
            <Button
              onClick={() => add()}
              variant='normal'
            >
              {bundle.getMessage('add')}
            </Button>
            <Button
              onClick={() => addGroup()}
              variant='normal'
            >
              {bundle.getMessage('add-group')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header>
          {bundle.getMessage('conditions')}
        </Header>}
      variant='default'
    >
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-conditions')}
            filteringPlaceholder={bundle.getMessage('find-conditions')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        items={items}
        onSelectionChange={({ detail }) => setSelectedRuleCondition(detail.selectedItems[0]) }
        pagination={
          conditions.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        selectedItems={selectedRuleCondition ? [selectedRuleCondition] : []}
        selectionType='single'
        submitEdit={submitEdit}
        trackBy='id'
      />
    </Container>);
}
