import {
  Button,
  Container,
  FormField,
  Grid,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  useEffect,
  useState,
} from 'react';
import { Images } from 'src/constants';
import { Status } from 'src/API';
import { debug } from '../../utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useOpenReviewsContext } from './OpenReviewsContext';
import { useSessionContext } from '../common/SessionContext';

const reviewStatusOptions = [
  Status.Active,
  Status.Development,
];

export interface IReviewActions {
  close: Function;
  saveReview: Function;
  showPlumageModel: Function;
  updateReview: Function;
  undoReview: Function;
}

export function ReviewActions(props: IReviewActions) {
  debug(`ReviewActions() props is ${JSON.stringify(props)}`);

  const openReviewsContext = useOpenReviewsContext();

  const sessionContext = useSessionContext();

  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [statusOptions] = useState<(SelectProps.Options)>(() => {
    return Object.values(Status).filter(s => reviewStatusOptions.includes(s)).map(value => ({ label: value, value }));
  });

  const [bundle, isBundleLoading] = useBundle('components.Reviews.ReviewActions');

  useEffect(() => {
    if (!openReviewsContext.currentDeviceId) return;
    const currentDevice = openReviewsContext?.currentReview?.temporaryReview?.devices?.find(d => d.id === openReviewsContext.currentDeviceId);
    if (!currentDevice) return;
  }, [openReviewsContext.currentDeviceId]);

  useEffect(() => {
    try {
      const darkMode = JSON.parse(sessionContext.userPreferences.preferences)?.darkMode ?? false;
      setDarkMode(darkMode);
    } catch (error) {
      console.error(error); 
    }
  }, [sessionContext.userPreferences.preferences]);
  
  if (isBundleLoading) return <Spinner/>;

  return (
    <Container>
      <form onSubmit={event => event.preventDefault()}>
        <SpaceBetween direction='vertical' size='s'>
          <Container >
            <Grid gridDefinition={[
              {colspan: 3},
              {colspan: 4},
              {colspan: 2},
              {colspan: 1},
              {colspan: 2},
            ]}>
              <FormField label={bundle.getMessage('name')} >
                <Input
                  disabled
                  value={openReviewsContext.currentReview?.temporaryReview.name || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('description')} stretch >
                <Input
                  onChange={( { detail }) => {
                    props.updateReview(
                      {
                        ...openReviewsContext.currentReview?.temporaryReview,
                        description: detail.value,
                      });
                  }}
                  value={openReviewsContext.currentReview?.temporaryReview.description || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('status')} stretch >
                <Select
                  onChange={event => {
                    props.updateReview(
                      {
                        ...openReviewsContext.currentReview?.temporaryReview,
                        status: event.detail.selectedOption.value,
                      });
                  }}
                  selectedOption={{
                    label: openReviewsContext.currentReview!.temporaryReview.status,
                    value: openReviewsContext.currentReview!.temporaryReview.status,
                  }}
                  options={statusOptions}
                />
              </FormField>
              <FormField label={bundle.getMessage('version')} >
                <Input
                  disabled
                  value={openReviewsContext.currentReview?.temporaryReview.versionId || ''}
                />
              </FormField>
              <FormField label={bundle.getMessage('updated')} >
                <Input
                  disabled
                  value={openReviewsContext.currentReview?.temporaryReview.updatedAt || ''}
                />
              </FormField>
            </Grid>
          </Container>
          <Container>
            <SpaceBetween direction='horizontal' size='xs' alignItems='end'>
              <FormField>
                <Button
                  disabled={!openReviewsContext.currentReview?.changed || openReviewsContext.currentReview?.saving}
                  formAction='none'
                  iconName='undo'
                  onClick={() => props.undoReview()}
                  variant='normal'
                >
                  {bundle.getMessage('undo')} 
                </Button>
              </FormField>
              <FormField>
                <Button
                  disabled={openReviewsContext.currentReview?.saving}
                  formAction='none'
                  iconName='status-info'
                  onClick={() => alert('TODO: get device status from PACS if it exists')}
                  variant='normal'
                >
                  {bundle.getMessage('verify')} 
                </Button>
              </FormField>
              <FormField>
                <Button
                  disabled={!openReviewsContext.currentReview?.changed || openReviewsContext.currentReview?.saving}
                  iconName='file'
                  loading={openReviewsContext.currentReview?.saving}
                  onClick={() => props.saveReview(openReviewsContext.currentReview?.temporaryReview)}
                  variant='primary'
                >
                  {bundle.getMessage('save')}
                </Button>
              </FormField>
              <FormField>
                <Button
                  disabled={openReviewsContext.currentReview?.changed || openReviewsContext.currentReview?.saving}
                  formAction='none'
                  iconName='send'
                  onClick={() => alert('TODO: send device configuration to PACS')}
                  variant='normal'
                >
                  {bundle.getMessage('send')} 
                </Button>
              </FormField>
              <FormField>
                <Button
                  disabled={openReviewsContext.currentReview?.saving}
                  formAction='none'
                  iconName='close'
                  onClick={() => props.close()}
                  variant='normal'
                >
                  {bundle.getMessage('close')} 
                </Button>
              </FormField>
              <FormField>
                <Button
                  disabled={
                    !openReviewsContext.currentReview?.temporaryReview?.devices
                    || openReviewsContext.currentReview.temporaryReview.devices.length === 0}
                  formAction='none'
                  iconName='zoom-to-fit'
                  iconSvg={
                    <svg>
                      {darkMode
                      &&
                      <image href={Images.showGraphDarkMode} />}
                      {!darkMode
                      &&
                      <image href={Images.showGraphLightMode} />}
                    </svg>
                  }
                  onClick={() => props.showPlumageModel()}
                  variant='normal'
                >
                  {bundle.getMessage('show-graph')} 
                </Button>
              </FormField>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </form>
    </Container>);
}
