import { API, graphqlOperation } from 'aws-amplify';
import {
  ApplicationSettings,
  CreateHardwareTemplateInput,
  CreateHardwareTemplateMutation,
  DeleteHardwareTemplateInput,
  DeleteHardwareTemplateMutation,
  Device,
  HardwareDevice,
  HardwareTemplate,
  ListHardwareTemplatesQuery,
  Rule,
  RuleCondition,
  UpdateHardwareTemplateInput,
  UpdateHardwareTemplateMutation,
} from 'src/API';
import {
  IOpenTemplatesContext,
  OpenTemplateActionType,
} from './OpenTemplatesContext';
import {
  createHardwareTemplate as createHardwareTemplateMutation,
  deleteHardwareTemplate as deleteHardwareTemplateMutation,
  updateHardwareTemplate as updateHardwareTemplateMutation,
} from 'src/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api';
import { QueryClient } from '@tanstack/react-query';
import { QueryKey } from '../common/constants';
import { auditDecorator } from '../utils';
import { debug } from 'src/utils';
import { listHardwareTemplates } from 'src/graphql/queries';

export let queryHardwareTemplates = async (hardwareType?: string): Promise<HardwareTemplate[] | []> => {
  debug(`queryHardwareTemplates() hardwareType is ${hardwareType}`);
 
  let hardwareTemplates: HardwareTemplate[] = [];
  let moreRecords = true, nextToken = null;

  while (moreRecords) {
    try {
      const response = await API.graphql(graphqlOperation(listHardwareTemplates,
        {
          filter:
            {
              hardwareType: {
                'eq': hardwareType
              }
            },
          nextToken,
        }
      )) as GraphQLResult<ListHardwareTemplatesQuery>;
      if (response.data && response.data.listHardwareTemplates) {
        nextToken = response.data.listHardwareTemplates.nextToken;
        if (!nextToken) moreRecords = false;
        hardwareTemplates = response.data.listHardwareTemplates.items as HardwareTemplate[];
      }
      debug(`queryHardwareTemplates() response is ${JSON.stringify(response)}`);
    } catch(error) {
      console.error(`queryHardwareTemplates() error is ${JSON.stringify(error)}`);
      throw error;
    }
  }
 
  return(hardwareTemplates);
};    
queryHardwareTemplates = auditDecorator('queryHardwareTemplates', queryHardwareTemplates);

export let createHardwareTemplate = async (input: CreateHardwareTemplateInput): Promise<HardwareTemplate | null> => {
  debug(`createHardwareTemplate() input is ${JSON.stringify(input)}`);
 
  let createdHardwareTemplate: HardwareTemplate | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(createHardwareTemplateMutation,
      {
        input
      })) as GraphQLResult<CreateHardwareTemplateMutation>;
    if (response.data && response.data.createHardwareTemplate) {
      createdHardwareTemplate = response.data.createHardwareTemplate as HardwareTemplate;
    }
    debug(`createHardwareTemplate() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`createHardwareTemplate() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(createdHardwareTemplate);
};    
createHardwareTemplate = auditDecorator('createHardwareTemplate', createHardwareTemplate);

export let updateHardwareTemplate = async (input: UpdateHardwareTemplateInput): Promise<HardwareTemplate | null> => {
  debug(`updateHardwareTemplate() input is ${JSON.stringify(input)}`);
 
  let updatedHardwareTemplate: HardwareTemplate | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(updateHardwareTemplateMutation,
      {
        input
      })) as GraphQLResult<UpdateHardwareTemplateMutation>;
    if (response.data && response.data.updateHardwareTemplate) {
      updatedHardwareTemplate = response.data.updateHardwareTemplate as HardwareTemplate;
    }
    debug(`updateHardwareTemplate() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`updateHardwareTemplate() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(updatedHardwareTemplate);
};    
updateHardwareTemplate = auditDecorator('updateHardwareTemplate', updateHardwareTemplate);

export let deleteHardwareTemplate = async (input: DeleteHardwareTemplateInput): Promise<HardwareTemplate | null> => {
  debug(`deleteHardwareTemplate() input is ${JSON.stringify(input)}`);
 
  let deletedHardwareTemplate: HardwareTemplate | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(deleteHardwareTemplateMutation,
      {
        input
      })) as GraphQLResult<DeleteHardwareTemplateMutation>;
    if (response.data && response.data.deleteHardwareTemplate) {
      deletedHardwareTemplate = response.data.deleteHardwareTemplate as HardwareTemplate;
    }
    debug(`deleteHardwareTemplate() response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`deleteHardwareTemplate() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(deletedHardwareTemplate);
};    
deleteHardwareTemplate = auditDecorator('deleteHardwareTemplate', deleteHardwareTemplate);

export function getApplicationSettingsForHardwareTypeAndDeviceName(
  applicationSettings: ApplicationSettings,
  hardwareTypeName: string,
  hardwareDeviceName: string): HardwareDevice | null
{
  debug(`getApplicationSettingsForHardwareTypeAndDeviceName() applicationSettings is ${JSON.stringify(applicationSettings)} \
  hardwareTypeName is ${hardwareTypeName} hardwareDeviceName is ${hardwareDeviceName}`);

  let hardwareDevice: HardwareDevice | null = null;

  try {
    hardwareDevice = applicationSettings?.hardwareTypes
      ?.find((ht) => ht?.name === hardwareTypeName)?.hardwareDevices
      ?.find((hd) => hd?.name === hardwareDeviceName) || null;
  } catch (error) {
    console.error(error); 
    throw error;
  }

  return hardwareDevice;
}

export const saveDevice = async (openTemplatesContext: IOpenTemplatesContext, queryClient: QueryClient) => {
  if (!openTemplatesContext.currentTemplate) throw new Error('missing current template');
  try {
    const updateHardwareTemplateInput: UpdateHardwareTemplateInput = {
      id: openTemplatesContext.currentTemplate.temporaryTemplate.id,
      description: openTemplatesContext.currentTemplate.temporaryTemplate.description,
      devices: openTemplatesContext.currentTemplate.temporaryTemplate.devices,
      name: openTemplatesContext.currentTemplate.temporaryTemplate.name,
      status: openTemplatesContext.currentTemplate.temporaryTemplate.status,
      versionId: openTemplatesContext.currentTemplate.temporaryTemplate.versionId,
    };
    await updateHardwareTemplate(updateHardwareTemplateInput);
    openTemplatesContext.dispatch({ type: OpenTemplateActionType.saveDevice, payload: { } });
    queryClient!.invalidateQueries({ queryKey: [QueryKey.templates] });
  } catch (error) {
    console.error(error); 
  }
};

export const formatOperator = (operator: string | null | undefined): string => {
  if (!operator) return '';
  return(operator?.replace(/_/g, ' '));
}

export const findKeyLabel = (device: Device, key: string) => {
  debug(`findKeyLabel() device is ${JSON.stringify(device)} key is ${key}`);
  return device.settings?.find(s => s.key === key)?.uiAttributes?.find(a => a?.name === 'label')?.value || key;
}

export function constructRuleConditionsStatement(device: Device, rule: Rule, format: boolean = false, level: number = 0, conditions?: RuleCondition[]): string {
  debug(`constructRuleConditionsStatement() device is ${JSON.stringify(device)} rule is ${JSON.stringify(rule)} format is ${format} level is ${level} conditions is ${JSON.stringify(conditions)}`);

  const ruleGroups = (conditions ?? rule.conditions)?.filter(c => c.group) || [];
  const ruleGroupConditions = (conditions ?? rule.conditions)?.filter(c => !c.group && (c.groupParentId !== null || c.groupParentId === undefined)) || [];
  const ruleNonGroupConditions = (conditions ?? rule.conditions)?.filter(c => !c.group && (c.groupParentId === null || c.groupParentId === undefined)) || [];
  const indent = format ? ' '.repeat(4) : '';

  debug(`constructRuleConditionsStatement() ruleGroupConditions.length is ${ruleGroupConditions.length}`);
  debug(`constructRuleConditionsStatement() ruleNonGroupConditions.length is ${ruleNonGroupConditions.length}`);
  if ((!conditions || conditions.length === 0) && ruleGroupConditions.length === 0 && ruleNonGroupConditions.length === 0) return '';

  const ruleConditionStatements: string[] = [];

  for (const ruleNonGroupCondition of ruleNonGroupConditions) {
    ruleConditionStatements.push(`(${findKeyLabel(device, ruleNonGroupCondition.key)} ${formatOperator(ruleNonGroupCondition.operator)} ${ruleNonGroupCondition.value})`);
  }

  for (const ruleGroup of ruleGroups) {
    debug(`constructRuleConditionsStatement() ruleGroup is ${JSON.stringify(ruleGroup)}`);
    let childConditions;
    if (conditions) {
      childConditions = rule.conditions?.filter(c => c.groupParentId === ruleGroup.id && !c.group) || [];
    } else {
      childConditions = rule.conditions?.filter(c => ruleGroup.groupParentId === null && c.groupParentId === ruleGroup.id && !c.group) || [];
    }
    debug(`constructRuleConditionsStatement() childConditions is ${JSON.stringify(childConditions)}`);
    if (childConditions.length === 0) continue;
    let childConditionStatements = childConditions.map(c =>
      `(${findKeyLabel(device, c.key)} ${formatOperator(c.operator)} ${c.value})`).join(`\n${indent.repeat(level)}${ruleGroup.groupOperator}\n${indent.repeat(level)}`);
    if (ruleGroup.not) childConditionStatements = `NOT (${childConditionStatements})`;
    const childGroupConditions = rule.conditions?.filter(c => c.groupParentId === ruleGroup.id && c.group) || [];
    let innerChildConditionsStatement = '';
    if (childGroupConditions.length > 0) {
      innerChildConditionsStatement = `\n${indent.repeat(level)}${ruleGroup.groupOperator}\n${indent.repeat(level)} ${constructRuleConditionsStatement(device, rule, format, level+1, childGroupConditions)}`;
    }
    childConditionStatements += innerChildConditionsStatement;
    ruleConditionStatements.push(`(${childConditionStatements})`);
  }

  let joinedRuleConditions = ruleConditionStatements.join(`\n${indent.repeat(level)}${rule.operator}\n`);
  if (rule.not) joinedRuleConditions = `NOT (${joinedRuleConditions})`;
  debug(`constructRuleConditionsStatement() joinedRuleConditions is ${JSON.stringify(joinedRuleConditions)}`);
  return joinedRuleConditions;
}
