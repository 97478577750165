import {
  Alert,
  Button,
  CollectionPreferences,
  Link,
  Pagination,
  Spinner,
  Table,
  TextFilter, 
 } from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  PaginationLabels,
  TableEmptyState,
  TableNoMatchState,
} from './ReviewsTableConfig';
import {
  SiteHardwareConfiguration,
  Status,
} from 'src/API';
import {
  useEffect,
  useState,
} from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import { UseQueryResult } from '@tanstack/react-query';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useSessionContext } from '../common/SessionContext';

export interface IReviewsTablePanelProps {
  closeReview: Function;
  editReview: Function;
  reviewsQuery: UseQueryResult<SiteHardwareConfiguration[]>;
  selectedReview: SiteHardwareConfiguration | null;
  setSelectedReview: Function;
}

export function ReviewsTablePanel(props: IReviewsTablePanelProps ) {
  debug(`ReviewsTablePanel() props is ${JSON.stringify(props)}`);

  const sessionContext = useSessionContext();

  const [allItems, setAllItems] = useState<SiteHardwareConfiguration[]>(props.reviewsQuery?.data || []);
  const [clickedReview, setClickedReview] = useState<SiteHardwareConfiguration | null>(null);
  const [error, setError] = useState<string>();
  const [hideTable] = useState<boolean>(false);
  const [pageSize] = useState<number>(5);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [selectedReview, setSelectedReview] = useState<SiteHardwareConfiguration | null>(props.selectedReview);

  const [bundle, isBundleLoading] = useBundle('components.Reviews.ReviewsTablePanel');

  const refresh = async () => {
    props.reviewsQuery.refetch();
  };

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems.filter(d => d.status === Status.Review),
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-documents-found') : 'No documents found'} />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
    }
  );

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    setSelectedReview(null);
    refresh();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  useEffect(() => {
    if (!props.reviewsQuery?.data) {
      setAllItems([]);
      return;
    }
    setAllItems(props.reviewsQuery.data);
    if (props.reviewsQuery.data.length === 1) {
      setSelectedReview(props.reviewsQuery.data[0]);
    }
  }, [props.reviewsQuery.data]);

  useEffect(() => {
    props.setSelectedReview(selectedReview);
  }, [selectedReview]);

  useEffect(() => {
    if (props.selectedReview !== selectedReview) setSelectedReview(props.selectedReview);
  }, [props.selectedReview]);

  useEffect(() => {
    if (clickedReview) props.editReview();
  }, [clickedReview]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
      {
        error
        &&
        <Alert
          header={bundle.getMessage('error')}
          type='error'
          dismissible
          onDismiss={() => setError('')}
        >
          {error}
        </Alert>
      }
      <div id='ReviewsTablePanel' hidden={hideTable}>
        <Table
          {...collectionProps}
          columnDefinitions={[
            {
              id: 'name',
              header: bundle.getMessage('name'),
              cell: item =>
                <Link
                  onClick={async () => {
                    setClickedReview(item);
                    setSelectedReview(item);
                    props.setSelectedReview(item);
                  }}
                >
                  {item.name}
                </Link>,
              sortingField: 'name',
            },
            ...ColumnDefinitions
          ]}
          filter={
            <TextFilter
              {...filterProps}
              filteringAriaLabel={bundle.getMessage('filter-documents')}
              filteringPlaceholder={bundle.getMessage('find-documents')}
              countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
            />
          }
          footer={
            <Header
              actions={
                <Button
                  disabled={selectedReview === null}
                  iconName='edit'
                  onClick={() => props.editReview()}
                  variant='primary'
                >
                  {bundle.getMessage('review')}
                </Button>
              }
            >
            </Header>
          }
          header={
            <Header
              counter={`(${itemsCount().toString()})`}
              actions={
                <Button
                  loading={refreshing}
                  key='refreshBtn'
                  onClick={refreshBtnClickHandler}
                  iconName='refresh'
                />
              }
            >
              {`${sessionContext.siteCode} ${bundle.getMessage('documents')}`}
            </Header>
          }
          items={items}
          loading={props.reviewsQuery?.fetchStatus == 'fetching'}
          empty={props.reviewsQuery?.status == 'error'
            ?
              <Alert type='error'>
                `${bundle.getMessage('query-failure')} (${typeof props.reviewsQuery?.error === 'object'
                   ? JSON.stringify(props.reviewsQuery?.error)
                   : 'TODO'
                   })`
              </Alert>
            : bundle.getMessage('no-documents-found')
          }
          loadingText={bundle.getMessage('loading-documents')}
          onSelectionChange={({ detail }) => setSelectedReview(detail.selectedItems[0]) }
          pagination={
            allItems.length > pageSize
            &&
            <Pagination
              {...paginationProps}
              ariaLabels={PaginationLabels}
            />
          }
          preferences={
            <CollectionPreferences
              onConfirm={() => alert('TODO: save preferences')}
              title={bundle.getMessage('preferences')}
              confirmLabel={bundle.getMessage('confirm')}
              cancelLabel={bundle.getMessage('cancel')}
              preferences={{
                pageSize: pageSize,
              }}
              pageSizePreference={{
                title: bundle.getMessage('select-page-size'),
                options: [
                  { value: 5, label: '5' },
                  { value: 10, label: '10' },
                  { value: 15, label: '15' },
                  { value: 25, label: '25' },
                  { value: 50, label: '50' },
                  { value: 100, label: '100' }
                ],
              }}
            />
          }
          resizableColumns={true}
          selectedItems={selectedReview ? [selectedReview] : []}
          selectionType='single'
          trackBy='id'
        />
      </div>
    </>);
}
