export enum ComponentType {
  button = 'button',
  input = 'input',
  link = 'link',
  select = 'select',
  toggle = 'toggle',
}

export enum Page {
  Documents = 'documents',
  HardwareTemplates = 'hardwareTemplates',
  Reviews = 'reviews',
  Templates = 'templates',
}

interface IPage {
  admin?: boolean;
  code: Page;
  name: string;
  order: number;
  params?: string[],
  route: string;
}

export const Pages: IPage[] = [
  {
    code: Page.Documents,
    name: 'documents',
    order: 2,
    params: [
      'siteCode',
    ],
    route: '#/documents/',
  },
  {
    code: Page.Reviews,
    name: 'reviews',
    order: 3,
    route: '#/reviews/',
  },
  {
    code: Page.Templates,
    name: 'templates',
    order: 1,
    route: '#/templates/',
  },
];

export enum QueryKey {
  allApplicationSettings = 'allApplicationSettings',
  documents = 'documents',
  reviews = 'reviews',
  selectedApplicationSettings = 'selectedApplicationSettings',
  templates = 'templates',
  userPreferences = 'userPreferences',
}

export enum UIAttribute {
  componentType = 'componentType',
  displayOnly = 'displayOnly',
  label = 'label',
  options = 'options',
  sequence = 'sequence',
}

export const DEFAULT_USER_PREFERENCES = {
  darkMode: false,
  visualRefresh: false,
  language: {
    label: 'English',
    value: 'en-US',
  },
}

export enum SPOTSesameAPIMethods {
  getUserSPOTPermissions = 'getUserSPOTPermissions',
  getPersonSPOTPermissions = 'getPersonSPOTPermissions',
}

export enum SPOTSesameUserPermissions {
  administrate = 'admin:Administrate',
  configuration = 'readWrite:Configuration',
  review = 'readWrite:Review',
  support = 'readonly:Support',
  template = 'readWrite:Template',
}

export enum SPOTUnicornPACSAPIMethods {
  getRequestStatus = 'getRequestStatus',
}

export const keyTypeOperators = new Map<string, string[]>([
  [
    'boolean',
    [
      'equals',
      'not_equal',
    ],
  ],
  [
    'number',
    [
      'equals_to',
      'less_than',
      'less_than_or_equal_to',
      'not_equal',
      'greater_than',
      'greater_than_or_equal_to',
    ]
  ],
  [
    'string',
    [
      'equals',
      'format_is',
      'not_equal',
      'contains',
      'does_not_contain',
      'starts_with',
      'ends_with',
    ]
  ],
]);

export const PlumageStyle = {
  height: '800px',
  width: '100%',
};