import {
  ComponentType,
  UIAttribute,
} from '../common/constants';
import {
  Container,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import {
  Device,
  DeviceSettings,
} from 'src/API';
import {
  OpenTemplateActionType,
  useOpenTemplatesContext,
} from '../Templates/OpenTemplatesContext';
import {
  useEffect,
  useState,
} from 'react';
import { debug } from 'src/utils';

export interface ITemplateDeviceSettingsProps {}

export default function TemplateDeviceSettings(props: ITemplateDeviceSettingsProps) {
  debug(`TemplateDeviceSettings() props is ${JSON.stringify(props)}`);

  const openTemplatesContext = useOpenTemplatesContext();

  const [templateDevice, setTemplateDevice] = useState<Device>();

  useEffect(() => {
    const currentDeviceId = openTemplatesContext.currentDeviceId;
    if (!currentDeviceId) {
      setTemplateDevice(undefined);
      return;
    }
    const device = openTemplatesContext.currentTemplate?.temporaryTemplate.devices?.find(d => d.id === currentDeviceId);
    if (!device) return;
    setTemplateDevice({...device});
  }, [openTemplatesContext.currentDeviceId, openTemplatesContext.currentTemplate?.temporaryTemplate?.devices]);

  if (openTemplatesContext.currentDeviceId === null) return(<></>);

  const createHardwareDeviceForm = () => {
    debug(`TemplateDevice() createHardwareDeviceForm() templateDevice is ${JSON.stringify(templateDevice)}`);
    return(
      <Container
        header={
          <Header
          >
            {templateDevice?.name}
          </Header>}
      >
      <form onSubmit={event => event.preventDefault()}>
        <Form>
          <Container>
            <SpaceBetween direction='vertical' size='xxxs'>
              {Array.from(templateDevice?.settings || [])
                ?.sort((dsA: any, dsB: any): number => {
                  if (!dsA || !dsB) return 0;
                  const sequenceA = parseInt(dsA?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  const sequenceB = parseInt(dsB?.uiAttributes?.find((a: any) => a?.name === UIAttribute.sequence)?.value || '0');
                  return sequenceA > sequenceB ? 1 : -1;
              })
              ?.map((ds: (DeviceSettings | null)) => {
                if (ds === null) return(<></>);
                const componentType = ds.uiAttributes?.find((a: any) => a?.name === UIAttribute.componentType)?.value || ComponentType.input;
                return(
                  <FormField
                    key={ds?.key}
                    label={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.label)?.value || ds?.key}
                  >
                    {componentType === ComponentType.input && templateDevice?.settings?.length && templateDevice.settings.length > 0
                    ? 
                      <Input
                        disabled={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly) ?.value === 'true' || false}
                        onChange={({ detail }) => {
                          const deviceSettings = templateDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds?.key,
                            uiAttributes: ds?.uiAttributes,
                            value: detail.value,
                          };
                          const newTemplateDevice: Device = {
                            ...templateDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setTemplateDevice(newTemplateDevice);
                          openTemplatesContext.dispatch({ type: OpenTemplateActionType.updateDevice, payload: { device: newTemplateDevice } });
                        }}
                        value={ds.value || ''}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.select && templateDevice?.settings?.length && templateDevice.settings.length > 0
                    ?
                      <Select
                        disabled={ds.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly)?.value === 'true' || false}
                        expandToViewport
                        onChange={({ detail }) => {
                          const deviceSettings = templateDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds.key,
                            uiAttributes: ds.uiAttributes,
                            value: detail.selectedOption.value,
                          };
                          const newTemplateDevice: Device = {
                            ...templateDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setTemplateDevice(newTemplateDevice);
                          openTemplatesContext.dispatch({ type: OpenTemplateActionType.updateDevice, payload: { device: newTemplateDevice } });
                        }}
                        options={JSON.parse(ds.uiAttributes.find(a => a.name === 'options').value).sort((a, b) => a.label < b.label ? -1 : 1)}
                        selectedOption={({ label: ds.value, value: ds.value})}
                      />
                    :
                      <></>}
                    {componentType === ComponentType.toggle && templateDevice?.settings?.length && templateDevice.settings.length > 0
                    ? 
                      <Toggle
                        disabled={ds?.uiAttributes?.find((a: any) => a?.name === UIAttribute.displayOnly)
                          ?.value === 'true' || false}
                        onChange={({ detail }) => {
                          const deviceSettings = templateDevice?.settings!.filter(s => s?.key !== ds.key);
                          const newDeviceSettings: DeviceSettings = {
                            key: ds?.key,
                            uiAttributes: ds?.uiAttributes,
                            value: detail.checked.toString(),
                          };
                          const newTemplateDevice: Device = {
                            ...templateDevice,
                            settings: [
                              ...deviceSettings,
                              newDeviceSettings,
                            ],
                          };
                          setTemplateDevice(newTemplateDevice);
                          openTemplatesContext.dispatch({ type: OpenTemplateActionType.updateDevice, payload: { device: newTemplateDevice } });
                        }}
                        checked={ds.value === 'true' || false}
                      />
                    :
                      <></>}
                  </FormField>);
                })}
            </SpaceBetween>
          </Container>
        </Form>
      </form>
      </Container>
    );
  };

  return(
    <>
      {createHardwareDeviceForm()}
    </>);
}