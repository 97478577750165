import {
  Box,
  Button,
  Container,
  SpaceBetween,
  Spinner,
  Table,
  TextFilter,
} from '@amzn/awsui-components-react';
import {
  ColumnDefinitions,
  DefaultPageSize,
  TableEmptyState,
  TableNoMatchState,
} from './ApplyTemplateTableConfig';
import {
  Device,
  HardwareTemplate,
} from 'src/API';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useState } from 'react';
import { useTemplates } from '../common/hooks/useTemplates';

interface IApplyTemplateProps {
  applyTemplate: Function;
  closeApplyTemplate: Function;
  deviceForApplyTemplate: Device;
}

export function ApplyTemplateTablePanel(props: IApplyTemplateProps) {
  debug(`ApplyTemplateTablePanel() props is ${JSON.stringify(props)}`);

  const hardwareTemplatesQuery = useTemplates();

  const [selectedTemplate, setSelectedTemplate] = useState<HardwareTemplate | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Documents.ApplyTemplateTablePanel');

  const { actions, collectionProps, filterProps, filteredItemsCount, items } = useCollection(
    hardwareTemplatesQuery?.data || [],
    {
      filtering: {
        empty: <TableEmptyState title={!isBundleLoading ? bundle.getMessage('no-templates-found') : 'No templates found'} />,
        filteringFunction: (item, filteringText) =>
          item.name.toLowerCase().includes(filteringText.toLowerCase())
          || item.hardwareType.toLowerCase().replace(/_/g, ' ').includes(filteringText.toLowerCase()),
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />,
      },
      pagination: DefaultPageSize,
      selection: {
        trackBy: 'id',
      },
      sorting: {},
  });

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  const applyTemplate = () => {
    props.applyTemplate(selectedTemplate?.id, props.deviceForApplyTemplate.id, props.deviceForApplyTemplate.parentDeviceId);
    close();
  };

  const close = () => {
    setSelectedTemplate(null);
    actions.setFiltering(''); 
    props.closeApplyTemplate();
  };

  if (isBundleLoading) return <Spinner/>;

  return(
    <Container
      footer={
        <Box
          float='right'
        >
          <SpaceBetween direction='horizontal' size='s'>
            <Button
              onClick={() => close()}
            >
              {bundle.getMessage('cancel')}
            </Button>
            <Button
              disabled={selectedTemplate === null}
              onClick={() => applyTemplate()}
              variant='primary'
            >
              {bundle.getMessage('apply')}
          </Button>
          </SpaceBetween>
        </Box>
      }
      variant='stacked'
    >
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={bundle.getMessage('filter-templates')}
            filteringPlaceholder={bundle.getMessage('find-templates')}
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        items={items}
        onSelectionChange={({ detail }) => setSelectedTemplate(detail.selectedItems[0]) }
        selectedItems={selectedTemplate ? [selectedTemplate] : []}
        selectionType='single'
      />
    </Container>);
}
