import * as APITypes from "../API";
import {
  API,
  graphqlOperation,
} from 'aws-amplify';
import {
  ApplicationSettings,
  CreateUserActionInput,
  CreateUserActionMutation,
  CreateUserPreferencesInput,
  CreateUserPreferencesMutation,
  GetApplicationSettingsQuery,
  GetUserPreferencesQuery,
  ListApplicationSettingsQuery,
  ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery,
  SesameQuery,
  SesameUserPermissions,
  SiteHardwareConfiguration,
  UpdateUserPreferencesInput,
  UpdateUserPreferencesMutation,
  UserAction,
  UserPreferences,
} from 'src/API';
import {
  Entity,
  PlumageModel,
  Relation,
  RelationType,
} from "@amzn/plumage";
import {
  createUserAction as createUserActionMutation,
  createUserPreferences as createUserPreferencesMutation,
  updateUserPreferences as updateUserPreferencesMutation,
 } from 'src/graphql/mutations';
import {
  getApplicationSettings,
  getUserPreferences,
  listApplicationSettings,
  listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName,
} from 'src/graphql/queries';
import { Device } from 'src/API';
import { GraphQLResult } from '@aws-amplify/api';
import { SPOTSesameAPIMethods } from "./common/constants";
import { authenticatedUsername } from './Auth/AuthenticateUser';
import { debug } from 'src/utils';
import { produce } from "immer";
import schema from 'src/graphql/schema.json';

type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const ttl = (years: number): number => {
  const ttlDate = new Date();
  ttlDate.setFullYear(ttlDate.getFullYear()+years);
  const ttlEpochInt: number = Math.round(ttlDate.getTime()/1000);
  return ttlEpochInt;
}

export const auditDecorator = function(actionName: string, fn: Function) {
  debug(`auditorDecorator() actionName is ${actionName}`);
  return function(...args: any[]) {
    debug(`auditorDecorator() actionName is ${actionName}`);
    debug(`auditorDecorator() args is ${args}`);
    const userActionInput: CreateUserActionInput = {
      actionTime: Date.now(),
      actionType: actionName,
      ttl: Math.round((Date.now()+10000)/1000),
      userId: authenticatedUsername,
    };
    createUserAction(userActionInput);
    return fn(...args);
  }
};

export async function createUserAction(userActionInput: CreateUserActionInput): Promise<UserAction | null> {
  debug(`createUserAction() userActionInput is ${JSON.stringify(userActionInput)}`);

  let newUserAction: UserAction | null = null;

  try {
    const response = await API.graphql(graphqlOperation(createUserActionMutation,
      {
        input: userActionInput,
      })) as GraphQLResult<CreateUserActionMutation>;
    if (response.data && response.data.createUserAction) {
      newUserAction = response.data.createUserAction as UserAction;
    }
  } catch(error) {
    console.error(`createUserAction() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(newUserAction);
}

export let createUserPreferences = async (userPreferences: CreateUserPreferencesInput): Promise<UserPreferences | null> => {
  debug(`createUserPreferences() userPreferences is ${JSON.stringify(userPreferences)}`);
 
  let newUserPrefs: UserPreferences | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(createUserPreferencesMutation,
      {
        input: userPreferences,
      })) as GraphQLResult<CreateUserPreferencesMutation>;
    if (response.data && response.data.createUserPreferences) {
      newUserPrefs = response.data.createUserPreferences as UserPreferences;
    }
  } catch(error) {
    console.error(`createUserPreferences() error is ${JSON.stringify(error)}`); 
    throw error;
  }
  
  return(newUserPrefs);
};
createUserPreferences = auditDecorator('createUserPreferences', createUserPreferences);

export let queryPersonPermissions = async (personId: string): Promise<SesameUserPermissions | null> => {
  debug(`queryPersonPermissions() personId is ${personId}`);

  let personPermissions: SesameUserPermissions | null = null;

  const personPermissionsQuery = `query Sesame($method: String!, $parameters: AWSJSON!) {
    sesame(method: $method, parameters: $parameters) {
      status
      message
      data {
        applicationPermissions {
          applicationPermissionID
          permissionName
          applicationID
          metadata {
            key
            value
          }
          displayName
          description
        }
        expirationTimestamp
        userRegion
        userTimezone
      }
    }
  }
  ` as GeneratedQuery<APITypes.SesameQueryVariables, APITypes.SesameQuery>;
  
  try {
    const response = await API.graphql(graphqlOperation(personPermissionsQuery,
      {
        method: SPOTSesameAPIMethods.getPersonSPOTPermissions,
        parameters: JSON.stringify([ { personId } ]),
      })) as GraphQLResult<SesameQuery>;
    debug(`queryPersonPermissions() response is ${JSON.stringify(response)}`);
    if (response.data?.sesame?.status !== 200) throw new Error(`Failed to get person permissions. Message: ${response.data?.sesame?.message}`);
    if (response.data && response.data.sesame) {
      personPermissions = response.data.sesame.data as SesameUserPermissions;
    }
  } catch(error) {
    console.error(`queryPersonPermissions() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryPersonPermissions() personPermissions is ${JSON.stringify(personPermissions)}`);
  return(personPermissions);
};    
queryPersonPermissions = auditDecorator('queryPersonPermissions', queryPersonPermissions);

export let queryUserPermissions = async (username: string): Promise<SesameUserPermissions | null> => {
  debug(`queryUserPermissions() username is ${username}`);
 
  let userPermissions: SesameUserPermissions | null = null;

  const userPermissionsQuery = `query Sesame($method: String!, $parameters: AWSJSON!) {
    sesame(method: $method, parameters: $parameters) {
      status
      message
      data {
        applicationPermissions {
          applicationPermissionID
          permissionName
          applicationID
          metadata {
            key
            value
          }
          displayName
          description
        }
        expirationTimestamp
        userRegion
        userTimezone
      }
    }
  }
  ` as GeneratedQuery<APITypes.SesameQueryVariables, APITypes.SesameQuery>;
  
  try {
    const response = await API.graphql(graphqlOperation(userPermissionsQuery,
      {
        method: SPOTSesameAPIMethods.getUserSPOTPermissions,
        parameters: JSON.stringify([ { userLogin: username } ]),
      })) as GraphQLResult<SesameQuery>;
    debug(`queryUserPermissions() response is ${JSON.stringify(response)}`);
    if (response.data?.sesame?.status !== 200) throw new Error(`Failed to get user permissions. Message: ${response.data?.sesame?.message}`);
    if (response.data && response.data.sesame) {
      userPermissions = response.data.sesame.data as SesameUserPermissions;
    }
  } catch(error) {
    console.error(`queryUserPermissions() error is ${JSON.stringify(error)}`);
    throw error;
  }

  debug(`queryUserPermissions() userPermissions is ${JSON.stringify(userPermissions)}`);
  return(userPermissions);
};    
queryUserPermissions = auditDecorator('queryUserPermissions', queryUserPermissions);

export let queryUserPreferences = async (userId: string): Promise<UserPreferences | null> => {
  debug(`queryUserPreferences() userId is ${userId}`);
 
  let userPrefs: UserPreferences | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(getUserPreferences,
      {
        userId,
      })) as GraphQLResult<GetUserPreferencesQuery>;
    if (response.data && response.data.getUserPreferences) {
      userPrefs = response.data.getUserPreferences as UserPreferences;
    }
    debug(`response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`queryUserPreferences() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(userPrefs);
};    
queryUserPreferences = auditDecorator('queryUserPreferences', queryUserPreferences);

export let queryAllApplicationSettings = async (): Promise<ApplicationSettings[]> => {
  debug(`queryAllApplicationSettings()`);
 
  let allApplicationSettings: ApplicationSettings[] = [];
  
  try {
    const response = await API.graphql(graphqlOperation(listApplicationSettings, {})) as GraphQLResult<ListApplicationSettingsQuery>;
    debug(`queryAllApplicationSettings() response is ${JSON.stringify(response)}`);
    if (response.data.listApplicationSettings.items.length === 0) {
      const errorMessage = 'Application Settings Not Found!';
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
    if (response.data && response.data.listApplicationSettings) {
      allApplicationSettings = response.data.listApplicationSettings.items as ApplicationSettings[];
    }
  } catch(error) {
    console.error(`queryAllApplicationSettings() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(allApplicationSettings);
};    
queryAllApplicationSettings = auditDecorator('queryAllApplicationSettings', queryAllApplicationSettings);

export let queryApplicationSettings = async (versionId: string): Promise<ApplicationSettings | null> => {
  debug(`queryApplicationSettings() userId is ${versionId}`);
 
  let applicationSettings: ApplicationSettings | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(getApplicationSettings,
      {
        versionId,
      })) as GraphQLResult<GetApplicationSettingsQuery>;
    if (response.data && response.data.getApplicationSettings) {
      applicationSettings = response.data.getApplicationSettings as ApplicationSettings;
    }
    debug(`response is ${JSON.stringify(response)}`);
  } catch(error) {
    console.error(`queryApplicationSettings() error is ${JSON.stringify(error)}`);
    throw error;
  }

  return(applicationSettings);
};    
queryApplicationSettings = auditDecorator('queryApplicationSettings', queryApplicationSettings);

export let queryPersonSites = async (personId: string): Promise<string[]> => {
  debug(`queryPersonSites() personId is ${personId}`);

  let personSites: string[] = [];

  const personSitesQuery = `query Sesame($method: String!, $parameters: AWSJSON!) {
    sesame(method: $method, parameters: $parameters) {
      status
      message
      data {
        resourcePermissions {
          permissionIDs
          resource {
            resourceID
            resourceName
            resourceType
          }
        }
      } 
    }
  }
  ` as GeneratedQuery<APITypes.SesameQueryVariables, APITypes.SesameQuery>;

  try {
    const response = await API.graphql(graphqlOperation(personSitesQuery,
      {
        method: SPOTSesameAPIMethods.getPersonSPOTPermissions,
        parameters: JSON.stringify([ { personId } ]),
      })) as GraphQLResult<SesameQuery>;
    debug(`queryPersonSites() response is ${JSON.stringify(response)}`);
    if (response.data?.sesame?.status !== 200) throw new Error(`Failed to get person sites. Message: ${response.data?.sesame?.message}`);
    if (response.data && response.data.sesame && response.data.sesame.data) {
      debug(`queryPersonSites() getting sites from response`);
      // @ts-ignore
      personSites = response.data.sesame.data.resourcePermissions?.map(rp => {
        debug(`queryPersonSites() map() rp is ${JSON.stringify(rp)}`);
        if (rp?.resource.resourceType === 'SITE') return rp.resource.resourceName;
    });
    }
  } catch(error) {
    console.error(`queryPersonSites() error is ${JSON.stringify(error)}`, error);
    throw error;
  }

  debug(`queryPersonSites() personSites is ${JSON.stringify(personSites)}`);
  return personSites;

};
queryPersonSites = auditDecorator('queryPersonSites', queryPersonSites);

export let queryUserSites = async (username: string): Promise<string[]> => {
  debug(`queryUserSites() username is ${username}`);

  let userSites: string[] = [];

  const userSitesQuery = `query Sesame($method: String!, $parameters: AWSJSON!) {
    sesame(method: $method, parameters: $parameters) {
      status
      message
      data {
        resourcePermissions {
          permissionIDs
          resource {
            resourceID
            resourceName
            resourceType
          }
        }
      } 
    }
  }
  ` as GeneratedQuery<APITypes.SesameQueryVariables, APITypes.SesameQuery>;

  try {
    const response = await API.graphql(graphqlOperation(userSitesQuery,
      {
        method: SPOTSesameAPIMethods.getUserSPOTPermissions,
        parameters: JSON.stringify([ { userLogin: username } ]),
      })) as GraphQLResult<SesameQuery>;
    debug(`queryUserSites() response is ${JSON.stringify(response)}`);
    if (response.data?.sesame?.status !== 200) throw new Error(`Failed to get user sites. Message: ${response.data?.sesame?.message}`);
    if (response.data && response.data.sesame && response.data.sesame.data) {
      debug(`queryUserSites() getting sites from response`);
      // @ts-ignore
      userSites = response.data.sesame.data.resourcePermissions?.map(rp => {
        debug(`queryUserSites() map() rp is ${JSON.stringify(rp)}`);
        if (rp?.resource.resourceType === 'SITE') return rp.resource.resourceName;
    });
    }
  } catch(error) {
    console.error(`queryUserSites() error is ${JSON.stringify(error)}`, error);
    throw error;
  }

  debug(`queryUserSites() userSites is ${JSON.stringify(userSites)}`);
  return userSites;

};
queryUserSites = auditDecorator('queryUserSites', queryUserSites);

export let querySiteHardwareConfigurations = async (siteCode: string, status: string = '', versionId: string = ''): Promise<SiteHardwareConfiguration[] | []> => {
  debug(`querySiteHardwareConfigurations() siteCode is ${siteCode} status is ${status} versionId is ${versionId}`);
 
  let siteHardwareConfigurations: SiteHardwareConfiguration[] = [];
  let moreRecords = true, nextToken = undefined;

  let statusVersionIdName = undefined;

  if (status !== '') {
    statusVersionIdName = {
      beginsWith: {
        status: `${status}`,
        versionId: `${versionId}`,
      },
    };
  }
  
  while (moreRecords) {
    try {
      const response = await API.graphql(graphqlOperation(listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName,
        {
          siteCode: siteCode,
          statusVersionIdName,
          nextToken,
        })) as GraphQLResult<ListSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndNameQuery>;
      if (response.data && response.data.listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName) {
        nextToken = response.data.listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName.nextToken;
        if (!nextToken) moreRecords = false;
        siteHardwareConfigurations = response.data.listSiteHardwareConfigurationBySiteCodeAndStatusAndVersionIdAndName.items as SiteHardwareConfiguration[];
      }
      debug(`querySiteHardwareConfigurations() response is ${JSON.stringify(response)}`);
    } catch(error) {
      console.error(`querySiteHardwareConfigurations() error is ${JSON.stringify(error)}`);
      throw error;
    }
  }

  return(siteHardwareConfigurations);
};    
querySiteHardwareConfigurations = auditDecorator('querySiteHardwareConfigurations', querySiteHardwareConfigurations);

export let updateUserPreferences = async (userPreferences: UpdateUserPreferencesInput): Promise<UserPreferences | null> => {
  debug(`updateUserPreferences() userPreferences is ${JSON.stringify(userPreferences)}`);
  
  let updatedUserPreferences: UserPreferences | null = null;
  
  try {
    const response = await API.graphql(graphqlOperation(updateUserPreferencesMutation,
      {
        input: userPreferences,
      })) as GraphQLResult<UpdateUserPreferencesMutation>;
    if (response.data && response.data.updateUserPreferences) {
      updatedUserPreferences = response.data.updateUserPreferences as UserPreferences;
    }
  } catch(error) {
    console.error(`updateUserPreferences() error is ${JSON.stringify(error)}`); 
    throw error;
  }
  
  return(updatedUserPreferences);
};
updateUserPreferences = auditDecorator('updateUserPreferences', updateUserPreferences);

export let transformDocumentDevicesToPlumageModel = (devices: APITypes.Device[]): PlumageModel => {
  debug(`transformDocumentDevicesToPlumageModel() devices.length is ${devices.length}`);
  debug(`transformDocumentDevicesToPlumageModel() devices is ${JSON.stringify(devices)}`);

  const model: PlumageModel = {
    entities: [],
    relations: [],
  };

  const sortedDevices = produce(devices, (draft) => sortDevices(draft));

  for (const device of sortedDevices) {
    const entity: Entity = {
      id: device.id,
      slots: {
        title: device.name,
        subtitle: device.hardwareDeviceName,
      },
    };
    model.entities.push(entity);

    if (device.parentDeviceId) {
      const parentDevice = devices.find(d => d.id === device.parentDeviceId);
      const relation: Relation = {
        id: `${parentDevice.id}-${device.id}`,
        sourceEntityId: parentDevice.id,
        targetEntityId: device.id,
        type: RelationType.Integration,
      };
      model.relations.push(relation);
    }
  }

  return model;
};
transformDocumentDevicesToPlumageModel = auditDecorator('transformDocumentDevicesToPlumageModel', transformDocumentDevicesToPlumageModel);

export const sortDevices = (devices: Device[]): Device[] => {
  debug(`sortDevices() devices.length is ${devices.length}`);

  return devices.sort((a, b) => {
    if (a.name.startsWith('Address') && b.name.startsWith('Address')) {
      return parseInt(a.name.split(' ')[1]) < parseInt(b.name.split(' ')[1]) ? -1 : 1;
    }
    if (a.hardwareType === APITypes.HardwareType.Controller && b.hardwareType === APITypes.HardwareType.Controller) {
      debug(`sortDevices() a.name is ${a.name} b.name is ${b.name}`);
      debug(`sortDevices() a is ${JSON.stringify(a)} b is ${JSON.stringify(b)}`);
    }
    if (a.settings.find(s => s.key.toLowerCase() === 'name') && b.settings.find(s => s.key.toLocaleLowerCase() === 'name')) {
      return a.settings.find(s => s.key.toLowerCase() === 'name').value < b.settings.find(s => s.key.toLocaleLowerCase() === 'name').value ? -1 : 1;
    }
    return a.name < b.name ? -1 : 1;
  });
};

export const findParentDevices = (device: Device, allDevices: Device[]): Device[] => {
  debug(`findParentDevices() device is ${JSON.stringify(device)} allDevices.length is ${allDevices.length}`);
  debug(`findParentDevices() allDevices is ${JSON.stringify(allDevices)}`);

  const parentDevices: Device[] = [];

  if (!device || !allDevices || allDevices.length === 0) return parentDevices;

  function findParent(deviceId: string | null) {
    if (!deviceId) return;

    const parent = allDevices.find(device => device.id === deviceId);
    if (parent) {
      parentDevices.unshift(parent);
      if (parent.parentDeviceId) findParent(parent.parentDeviceId);
    }
  }

  if (device.parentDeviceId) findParent(device.parentDeviceId);

  debug(`findParentDevices() parentDevices is ${JSON.stringify(parentDevices)}`);
  return parentDevices;
};

export function createJsonSchemaFromGraphQLType(typeName: string) {
  // Find the type in the schema
  const type = schema.data.__schema.types.find(t => t.name === typeName);
  if (!type) {
    throw new Error(`Type ${typeName} not found in schema`);
  }

  // Convert GraphQL type to JSON Schema type
  function convertType(gqlType: any): any {
    if (!gqlType) return { type: 'string' };

    switch (gqlType.kind) {
      case 'NON_NULL':
        return convertType(gqlType.ofType);
      case 'LIST':
        return {
          type: 'array',
          items: convertType(gqlType.ofType)
        };
      case 'SCALAR':
        switch (gqlType.name) {
          case 'Int':
            return { type: 'integer' };
          case 'Float':
            return { type: 'number' };
          case 'Boolean':
            return { type: 'boolean' };
          case 'ID':
          case 'String':
          case 'AWSDateTime':
          default:
            return { type: 'string' };
        }
      case 'OBJECT':
      case 'INPUT_OBJECT':
        return {
          type: 'object',
          properties: {},
          required: []
        };
      default:
        return { type: 'string' };
    }
  }

  // Create the JSON Schema
  const jsonSchema = {
    $schema: 'http://json-schema.org/draft-07/schema#',
    type: 'object',
    properties: {},
    required: []
  };

  // Convert fields
  if (type.fields) {
    type.fields.forEach(field => {
      jsonSchema.properties[field.name] = convertType(field.type);
      if (field.type.kind === 'NON_NULL') {
        jsonSchema.required.push(field.name);
      }
    });
  }

  return jsonSchema;
}
