import {
  Alert,
  Modal,
  SelectProps,
  Spinner,
} from '@amzn/awsui-components-react';
import {
  CreateHardwareTemplateInput,
  HardwareTemplate,
  HardwareType,
  Status,
} from 'src/API';
import {
  OpenTemplateActionType,
  useOpenTemplatesContext,
} from './OpenTemplatesContext';
import {
  UseQueryResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {
  useEffect,
  useState,
} from 'react';
import { CreateTemplate } from './CreateTemplate';
import { CreateTemplateRevision } from './CreateTemplateRevision';
import { QueryKey } from '../common/constants';
import { Template } from './Template';
import { TemplatesTablePanel } from './TemplatesTablePanel';
import { createHardwareTemplate } from './utils';
import { debug } from 'src/utils';
import { useBundle } from '@amzn/react-arb-tools';

interface ITemplatesProps {
  selectedApplicationSettingsVersion: SelectProps.Option;
  setSelectedApplicationSettingsVersion: Function;
  templatesQuery: UseQueryResult<HardwareTemplate[]>;
}

export default function Templates(props: ITemplatesProps) {
  debug(`Templates() props is ${JSON.stringify(props)}`);

  const openTemplatesContext = useOpenTemplatesContext();

  const [creatingTemplate, setCreatingTemplate] = useState<boolean>(false);
  const [creatingTemplateRevision, setCreatingTemplateRevision] = useState<boolean>(false);
  const [templatesaveError, setTemplateSaveError] = useState<string | null>(null);
  const [showCreateTemplate, setShowCreateTemplate] = useState<boolean>(false);
  const [showCreateTemplateRevision, setShowCreateTemplateRevision] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<HardwareTemplate | null>(null);

  const [bundle, isBundleLoading] = useBundle('components.Templates.index');

  const queryClient = useQueryClient();

  const createHardwareTemplateMutation = useMutation(
    {
      mutationFn: createHardwareTemplate,
      onSuccess: (data) => {
        queryClient.fetchQuery({queryKey: [QueryKey.templates]});
        setSelectedTemplate(data);
        setShowCreateTemplate(false);
      },
      onError: (error: any) => {
        setTemplateSaveError((typeof error === 'object') ? JSON.stringify(error) : error);
      },
    }
  );

  const createTemplate = async (newTemplateProps: {name: string, description: string, versionId: string}) => {
    setCreatingTemplate(true);
    setSelectedTemplate(null);
    try {
      const createTemplateInput: CreateHardwareTemplateInput = {
        description: newTemplateProps.description,
        devices: [],
        hardwareType: HardwareType.Undefined,
        name: newTemplateProps.name,
        status: Status.Development,
        versionId: newTemplateProps.versionId,
      };
      await createHardwareTemplateMutation.mutateAsync(createTemplateInput);
      setShowCreateTemplate(false);
    } catch(error) {
      console.error(error);
      setShowCreateTemplate(false);
      setTemplateSaveError(JSON.stringify(error));
    }
    setCreatingTemplate(false);
  };

  const createTemplateRevision = async (versionId: string) => {
    setCreatingTemplateRevision(true);
    if (!selectedTemplate) return;
    try {
      const newTemplate: CreateHardwareTemplateInput = {
        description: selectedTemplate.description,
        devices: selectedTemplate.devices,
        hardwareType: HardwareType.Undefined,
        name: selectedTemplate.name,
        status: Status.Development,
        versionId: versionId,
      };
      await createHardwareTemplateMutation.mutateAsync(newTemplate);
      setShowCreateTemplateRevision(false);
    } catch(error) {
      console.error(error);
      setShowCreateTemplateRevision(false);
      setTemplateSaveError(JSON.stringify(error));
    }
    setCreatingTemplateRevision(false);
  };

  const editTemplate = () => {
    openTemplatesContext?.dispatch({ type: OpenTemplateActionType.open, payload: { template: selectedTemplate } });
  };

  const closeTemplate = (template: HardwareTemplate) => {
    openTemplatesContext.dispatch({ type: OpenTemplateActionType.close, payload: { template } });
  };

  useEffect(() => {
    if (openTemplatesContext.currentTemplate?.id) {
      setSelectedTemplate({...openTemplatesContext.currentTemplate.savedTemplate});
    }
  }, [openTemplatesContext.currentTemplate]);

  useEffect(() => {
    if (openTemplatesContext.currentTemplate
      && openTemplatesContext.currentTemplate?.id === selectedTemplate?.id)
    {
      setSelectedTemplate({...openTemplatesContext.currentTemplate.savedTemplate});
    }
  }, [openTemplatesContext]);

  if (isBundleLoading) return <Spinner/>;

  if (openTemplatesContext.currentTemplate) {
    return(
      <Template
        close={closeTemplate}
        selectedApplicationSettingsVersion={props.selectedApplicationSettingsVersion}
        setSelectedApplicationSettingsVersion={props.setSelectedApplicationSettingsVersion}
      />);
  }

  return(
    <>
      {templatesaveError
      &&
      <Alert
        onDismiss={() => setTemplateSaveError(null)}
        type='error'
      >
        {templatesaveError}
      </Alert>}
      <Modal
        header={<h3>{bundle.getMessage('create')}</h3>}
        onDismiss={() => setShowCreateTemplate(false)}  
        size='small'
        visible={showCreateTemplate}
      >
        <CreateTemplate
          cancel={() => setShowCreateTemplate(false)}
          createTemplate={createTemplate}
          creatingTemplate={creatingTemplate}
        />
      </Modal>
      <Modal
        header={<h3>{bundle.getMessage('create')}</h3>}
        onDismiss={() => setShowCreateTemplateRevision(false)}  
        size='small'
        visible={showCreateTemplateRevision}
      >
        <CreateTemplateRevision
          cancel={() => setShowCreateTemplateRevision(false)}
          createTemplateRevision={createTemplateRevision}
          creatingTemplateRevision={creatingTemplateRevision}
        />
      </Modal>
      <TemplatesTablePanel
        closeTemplate={closeTemplate}
        createTemplate={() => setShowCreateTemplate(true)}
        createTemplateRevision={() => setShowCreateTemplateRevision(true)}
        editTemplate={editTemplate}
        selectedTemplate={selectedTemplate}
        setSelectedTemplate={setSelectedTemplate}
        templatesQuery={props.templatesQuery}
      />
    </>);
}
