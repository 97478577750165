import {
  Icon,
  TableProps,
} from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import { Rule } from 'src/API';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import { useBundle } from '@amzn/react-arb-tools';

interface IExtendedRule extends Rule {
  passed: boolean;
}

export const ColumnDefinitions: TableProps.ColumnDefinition<IExtendedRule>[] = [
  {
    id: 'description',
    header: <TranslateHeader value='description'/>,
    cell: item =>
      (
        <span style={{color: item.passed ? undefined : 'red'}}>
          {item.description}
        </span>
      ),
    sortingField: 'description',
  },
  {
    id: 'passed',
    header: <TranslateHeader value='passed'/>,
    cell: item =>
      (
        <Icon
          name={item.passed ? 'thumbs-up' : 'thumbs-down'}
          variant={item.passed ? 'success' : 'error'}
        />
      ),
    sortingField: 'passed',
  },
];

function TranslateHeader({ value }: { value: string }) {
  const [bundle, isBundleLoading] = useBundle('components.Documents.DocumentRulesEvaluationTableConfig');
  if (isBundleLoading) return <Spinner/>
  return <>{bundle.getMessage(value)}</>;
}

interface EmptyStateProps {
  title: string
}

export function TableEmptyState({title}: EmptyStateProps) {
  return (
    <Box textAlign='center' color='inherit'>
      <Box variant='strong' textAlign='center' color='inherit'>
        {title}
      </Box>
    </Box>
  );
}

export const TableNoMatchState = () => (
  <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
    <SpaceBetween size='xxs'>
      <div>
        <b><TranslateHeader value='no-devices-found'/></b>
      </div>
    </SpaceBetween>
  </Box>
);

export const DefaultPageSize = {
  pageSize: 15
}

export const PaginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page'
};
