import { QueryKey } from '../constants';
import { Status } from 'src/API';
import { querySiteHardwareConfigurations } from 'src/components/utils';
import { useQuery } from '@tanstack/react-query';

export function useDocuments(siteCode: string, status: Status | string ) {
  return useQuery({
    enabled: (siteCode !== ''),
    queryKey: [QueryKey.documents],
    queryFn: () => querySiteHardwareConfigurations(siteCode, status),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
    staleTime: Infinity,
    throwOnError: true,
  });
}
