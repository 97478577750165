import { debugLogging } from 'src/components/App';

export function debug(message: string) {
  try {
    debugLogging && console.debug(message);
  } catch(error) {
    if (error instanceof TypeError) console.debug(message);
    else console.error(error);
  }
}

export function getTimeString(timestamp: number) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
}
